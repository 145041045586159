<template>
  <v-container>

    <v-card class="mt-4 mb-3 text-center">
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          {{ pytItem.prdtNm }}
        </v-col>
      </v-row>
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          결제일자
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.PYT_DT }}
        </v-col>
        <v-col class="py-1 cell-title">
          상태
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.usSttusNm }}
        </v-col>
      </v-row>
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          남은시간
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.RMN_TIM }}
        </v-col>
        <v-col class="py-1 cell-title">
          유효기간
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.VALD_PD }}
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-container fluid>
        <v-table
          fixed-header
          height="150px"
          density="compact"
          class="px-0 mx-0"
        >
          <thead>
            <tr>
              <th class="text-center px-1">
                사물함번호
              </th>
              <th class="text-center px-1">
                시작일
              </th>
              <th class="text-center px-1">
                완료일
              </th>
              <th class="text-center px-1">
                구분
              </th>
              <th class="text-center px-1">
                처리일
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item, idx in lkrHist"
              :key="idx"
            >
              <td class="text-center px-1">{{ item.lkrNo }}</td>
              <td class="text-center px-1">{{ item.LKR_BGN_DE }}</td>
              <td class="text-center px-1">{{ item.LKR_END_DE }}</td>
              <td class="text-center px-1">{{ item.isuTyNm }}</td>
              <td class="text-center px-1">{{ item.HST_DT }}</td>
            </tr>
          </tbody>
        </v-table>   
      </v-container>
    </v-card>
 
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';

export default {
  props: {
    pytItem: {    /* 화면 표시용 결제 내역 - 2023.03.18 */
      type: Object,
      default: null
    },
  },

  data: () => ({
    lkrHist: [
/*      
      {
        hstSrno: 5,                // 이력일련번호
        lkrNo: 23,                 // 사물함번호
        lkrBgnDe: '20210626',      // 시작일 yyyymmdd
        lkrEndDe: '20210627',      // 완료일 yyyymmdd
        isuTyCd: '01',             // 발생유형코드 01-신규, 02-이동, 03-연장, 04-해제, 05-변경
        isuTyNm: '신규',
        hstDt: '202106271816',
      },
*/      
    ],

  }),

  mounted() {
    this.getLkrUseHist();
  },

  methods: {

    /**
     * 사용자의 사물함 이용내역 조회 - 2023.10.28
     */
    async getLkrUseHist() {

      const param = { pytId: this.pytItem.pytId };

      // 사물함 이용내역 조회
      const res = await Com.callServer(URL.MBER.GET_LKR_USE_HIST, param);

      if (res !== null && res.rStatus === 0) {
        this.lkrHist = res.retList;
      }

      this.setDispFormat();
    },


    /**
     * 결제 리스트 화면 표시 텍스트 설정 - 2023.03.21
     */
     setDispFormat() {
      this.lkrHist.forEach(hist => {
        hist.LKR_BGN_DE = this.formatter(hist, 'LKR-BGN-DE');
        hist.LKR_END_DE = this.formatter(hist, 'LKR-END-DE');
        hist.HST_DT = this.formatter(hist, 'HST-DT');
      });
    },

    /**
     * 화면에 표시되는 형식으로 문자열 편집 - 2023.03.17
     * @param {화면 표시 데이터 행} item 
     * @param {화면 표시 데이터 필드명} field 
     */
     formatter(item, field) {

      let retVal = '';

      switch (field) {
        case 'LKR-BGN-DE': /* 시작일  */
          retVal = Com.stringFormatter(item.lkrBgnDe, 'DAY1');
          break;

        case 'LKR-END-DE': /* 종료일  */
          retVal = Com.stringFormatter(item.lkrEndDe, 'DAY1');
          break;

        case 'HST-DT': /* 처리일  */
          retVal = Com.stringFormatter(item.hstDt, 'DAY1');
          break;

        default:
          retVal = '잘못된 유형';
          break;
      }

      return retVal;
      },

  }

}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  font-size: 13px !important;
}
.cell-text {
  background-color: #ebecea;
  font-size: 13px;
}
</style>
