<template>
  <v-container>

    <confirm-view ref="cf"/>

    <v-card
      class="ma-2 text-center text-size"
      title="무통장 입금"
      max-width="300px"
    >
      <v-row class="mx-1 my-0">
        <v-col cols="12 text-left">
          아래의 계좌로 입금하실 입금자명을 입력 후 예약 버튼을 눌러 주세요.
          {{ branch.depositLimitMinute }}분 이내에 입금되지 않을 경우 자동으로
          예약이 취소 되며 확정 여부는 결제 내역에서 확인해 주세요.
        </v-col>
      </v-row>
      <v-row class="border my-0 align-center">
        <v-col cols="7" class="py-1">
          {{ branch.bankAcct }}
        </v-col>
        <v-col cols="5" class="py-1 pr-5">
          <v-btn
            variant="outlined"
            color="warning"
            class="ma-0"
            @click="copyText(bankInfo)"
          >
            복사하기
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        class="d-flex justify-space-between align-center pa-0 ma-0 border"
      >
        <v-col cols="7" height="100%" class="ma-0 pa-0">
          <div>
            입금액
          </div>
        </v-col>
        <v-col cols="5" class="ma-0 pa-2">
          {{ totalAmtText }} 원
        </v-col>
      </v-row>

      <v-row
        class="d-flex justify-space-between align-center pa-0 ma-0 border"
      >
        <v-col cols="7" height="100%" class="ma-0 pa-0">
          <div>
            입금자명
          </div>
        </v-col>
        <v-col cols="5" class="ma-0 pa-2">
          <v-text-field
            v-model="nameToSave"
            density="compact"
            variant="outlined"
            hide-details="true"
            class="centered-input text--darken-3"
            signgle-line
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="my-0">
        <v-col>
          <v-btn
            size="normal"
            color="success"
            class="pa-3"
            @click="reserveSTR()"
          >
            예약하기
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  props: {
    items: {    /* 예약 내역 - 2023.03.23 */
      type: Object,
      default: null
    },
    totalAmt: { /* 전체 금액 - 2023.04.18 */
      type: Number,
      default: 0
    },
    buyerInfo: { /* 비회원 구매시 구매자 정보 - 2024.03.25 */
      type: Object,
      default: null
    },
  },

  components: { ConfirmView },

  setup() {
    const store = useStore();
    // 로그인 정보
    const login = computed(() => store.getters.login);
    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { login, branch };
  },

  data: () => ({
    nameToSave: undefined,
    totalAmtText: undefined,    /* 화면표시 전체 상품 금액 */
  }),

  mounted() {
    // 이름 설정
    this.nameToSave = this.login.mberNam;

    // 가격 텍스트 설정
    this.totalAmtText = Com.stringFormatter(this.totalAmt, 'CURRENCY');
  },

  methods: {
    /**
     * 클립보드 복사 - 2023.03.23
     */
    copyText(text) {
      navigator.clipboard.writeText(text);
      //alert('클립보드에 저장되었습니다.');
      this.$refs.cf.toast("클립보드에 저장되었습니다.");
    },

    /** 스터디룸 예약 - 2023.04.20
     */
    async reserveSTR() {

      // 입금자명 확인
      if (this.nameToSave) {
        this.nameToSave = this.nameToSave.trim();

        if (this.nameToSave.length < 2) {
          //alert("입금자 명을 2자이상 입력해주세요.");
          this.$refs.cf.msgOk("입금자 명을 2자이상 입력해주세요.", "warning");
          return;
        }
      }
      else {
        //alert("입금자명을 입력해 주세요.");
        this.$refs.cf.msgOk("입금자명을 입력해 주세요.", "warning");
        return;
      }

      // 서버 전달 파라미터 구성
      const param = {
        //shpId: this.branch.shpId,
        //mpnum: this.login.mpnum,
        prdtId: this.items[0].prdtId,   /* 대표상품 ID 설정 - 2023.04.18  */
        prdtAmt: this.totalAmt,         /* 전체 상품금액 설정 - 2023.04.18 */
        acctNm: this.nameToSave,
        reserveList: [],
      };

      // 예약 리스트 구성
      this.items.forEach((item) => 
        param.reserveList.push({
          stNo: item.stNo,
          usDe: item.usDe,
          mngTmCd: item.mngTmCd,
          prdtId: item.prdtId,
          usStCnt: item.usStCnt,
        })
      );

      const isLogined = await Com.isLogined();
      let res = undefined;

      if (isLogined) {
        // 로그인 사용자 서버 예약 요청
        res = await Com.callServer(URL.MBER.RESERVE_STR_BY_BANK, param);
      }
      else if (this.buyerInfo.buyMpnum) {
        // 비로그인 예약자 구매 설정
        param.shpId = this.branch.shpId;
        param.mpnum = this.buyerInfo.buyMpnum;
        param.buyPwd = this.buyerInfo.buyPwd;

        // 비로그인 사용자 서버 예약 요청
        res = await Com.callServer(URL.PBLC.RESERVE_STR_BY_BANK, param);
      }
      else {
        this.$refs.cf.msgOk("잘못된 예약 호출입니다. 관리자에게 문의주세요.", "error");
        return;
      }

      // 서버 예약 결과가 있는 경우
      if (res !== null) {

        // 예약이 성공한 경우
        if (res.rStatus === 0) {
          await this.$refs.cf.msgOk("예약이 완료되었습니다. 지정된 시간안에 입금이 되지 않을 경우 자동으로 취소됩니다.", "success");
          this.$emit("completed");
        }
        else if (res.rStatus === 31) {
          Com.log("ReserveByBank.reserveSTR 중복예약", res.retList, "error");
          this.$refs.cf.msgOk("이미 존재하는 예약이 있습니다.", "error");
        }
        else {
          Com.log("ReserveByBank.reserveSTR", res.errorMsg, "error");
          this.$refs.cf.msgOk("예약중 오류가 발생하였습니다.", "error");
        }
      }
      else {
        this.$refs.cf.msgOk("예약중 오류가 발생하였습니다.", "error");
      }

    },
  }

}
</script>

<style scoped>
.text-size {
  font-size: 12px;
}
.centered-input >>> input {
  text-align: center
}
</style>
