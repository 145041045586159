<template>
  <v-app id="app">
    <!-- If using vue-router -->
    <router-view></router-view>
  </v-app>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';
//import { useRouter } from 'vue-router';
//import { useRoute } from 'vue-router';
//import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
//    const route = useRoute();
//    const router = useRouter();
    const store = useStore();
/*
    // root로 들어온 경우에만 default 페이지로 이동 함 - 2023.03.28
    onBeforeMount(async() => {
      Com.log("app.onBeforeMount before route ready", route);

      await router.isReady()

      Com.log("app.onBeforeMount route", route);
      //console.log("app.onBeforeMount path", route.path);

      // 지점정보가 없고 경로를 지정하지 않은 경우에만 이동
      if (Com.readBranchFromCookie() != null && route.path == '/') {

        // 좌석현황 정보조회 페이지 이동
        //router.push('home');

        // 지점정보 조회
        const branch = Com.readBranchFromCookie();
        Com.log("app.onBeforeMount go to seatStatus", '/' + branch.mblSiteId + '/seatStatus');
        //router.push({path: '/' + branch.mblSiteId + '/seatStatus'});
      }
      
    });
*/
    return { store };
  },

  mounted() {
    //console.log("app.mounted");
    // 지점 목록 조회 - 2023.04.06
    // selectBranch에서도 처리함 - 2023.10.27
    this.initBranchList();
  },

  methods: {
    /**
     * 지점 목록 초기화 - 2023.04.06
     * selectBranch로 옮김 - 2023.10.27
     */
    async initBranchList() {
      Com.log("App.initBranchList");

      // 서버 지점 목록 조회
      const res = await Com.callServer(URL.LOGIN.USER_GET_BRANCHLIST);

      if (res !== null) {
        // 목록 조회 성공의 경우
        if (res.rStatus === 0) {
          this.store.commit("setBranchList", res.retList);
        }
        // 오류가 발생한 경우
        else {
          Com.log("App.initBranchList", res.errorMsg, "error");
        }
      }

      // 지점정보 조회
      const branch = Com.readBranchFromCookie();
      if (branch != null) {
        // 지점정보 기간 갱신
        Com.saveBranchToCookie(branch);

        // 스터디룸 목록 조회 및 기준일 설정 - 2023.08.27
        this.store.dispatch("initSTRInfo");
      }
      else {
        Com.log("App.initBranchList router", this.$router);

        // 지점 선택 페이지 이동
        //this.$router.push('selectBranch');
      }

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
