<template>
  <v-overlay
    :model-value="is_show"
    class="wrapper"
    z-index="100005"
    @click:outside="close"
  >
    <v-alert
      :type="type"
      v-model="is_show"
      border="start"
      class="content mx-3"
      :text="text"
    >
      <div v-if="btnOk" class="mt-3 text-center">
        <v-btn @click="ok" class="mx-3">{{ btnOk }}</v-btn>
        <v-btn v-if="btnCancel" @click="cancel" class="mx-3">{{ btnCancel }}</v-btn>
      </div>
    </v-alert>
  </v-overlay>
</template>

<script>
import Com from '@/lib/common'

export default {
  name: 'ConfirmView',

  data: () => ({
    is_show: false,
    type: 'info', //success, info, warning, error 이렇게 4가지 사용 가능
    title: '',
    text: '',
    btnOk: '예',
    btnCancel: '아니요',
    result : undefined,

  }),

  mounted() {
    document.addEventListener('keydown', this.onKeyEvent);
  },

  watch: {
    is_show: function(val) {
      Com.log("ConfirmView.watch.is_show", val);
    },
  },

  methods: {

    /**
     * 키이벤트 핸들러 - 2023.10.08
     * @param { 키 이벤트컴포넌트 } e 
     */
    onKeyEvent(e) {
      if (this.is_show) {
        let code = e.keyCode;
        Com.log("keyCode: ", code);

        if (code === 27) { // esc
          this.cancel();
        }
        else if (code === 13) { // enter
          this.ok();
        }
        else {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    },

    /**
     * 토스트 메시지 표시 - 2023.10.08
     * @param { 메시지 } message 
     * @param { success, info(default), warning, error } type
     * @param { 메시지표시 시간 (miliseconds) } displayTime
     */
    toast(message, type, displayTime) {
      // 화면표시 시간 기본 3초
      let timeout = (displayTime && displayTime > 1000) ? displayTime : 3000;

      this.type = ( !type ) ? 'info' : type;
      this.text = message;

      // 버튼 표시 안함
      this.btnOk = undefined;
      this.btnCancel = undefined;

      // 메시지 화면 표시
      this.is_show = true;

      // 타이머 생성
      setTimeout(() => {
        // 화면 감추기
        this.is_show = false;
      }, timeout);
    },

    /**
     * 사용자 확인 메시지 박스 - 2023.10.08
     * @param { 메시지 } message 
     * @param { success, info(default), warning, error } type 
     * @param { ok 버튼명 } btnOk 
     * @param { cancel 버튼명 } btnCancel 
     */
    msgConfirm(message, type, btnOk, btnCancel) {
      this.type = ( !type ) ? 'info' : type;
      this.text = message;

      // ok 버튼 명칭 지정
      if (btnOk) {
        this.btnOk = btnOk;
      }

      // cancel 버튼 명칭 지정
      if (btnCancel) {
        this.btnCancel = btnCancel;
      }

      // 메시지 화면 표시
      this.is_show = true;

      // 결과 return
      return new Promise((resolve) => {
        this.result = resolve;
      })
    },


    /**
     * 확인 메시지 표시 - 2023.10.08
     * @param { 메시지 } message 
     * @param { success, info(default), warning, error } type 
     */
    msgOk(message, type) {
      this.type = !type ? 'info' : type;
      this.text = message;

      // ok 버튼 명칭 설정
      this.btnOk = '확인';

      // cancel 버튼 감추기
      this.btnCancel = undefined;

      // 메시지 화면 표시
      this.is_show = true;

      // 결과 return
      return new Promise((resolve) => {
        this.result = resolve;
      })
    },

    /**
     * OK 버튼 이벤트 처리 - 2023.10.08
     */
    ok() {
      this.is_show = false;
      this.result(true);
    },

    /**
     * Cancel 버튼 이벤트 핸들러 - 2023.10.08
     */
    cancel() {
      this.is_show = false;
      if (this.result) {
        this.result(false);
      }
    },

    /**
     * close of alert 이벤트 핸들러 - 2024.07.04
     */
    close() {
      this.is_show = false;
      if (this.result) {
        this.result(null);
      }
    }
  }
}
</script>

<style scoped>
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    margin-top: -20rem;
    border-radius: 0.5rem;
  }
</style>