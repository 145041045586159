<template>
  <v-container>

    <v-card class="mt-4 mb-3 text-center">
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          {{ pytItem.prdtNm }}
        </v-col>
      </v-row>
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          결제일자
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.PYT_DT }}
        </v-col>
        <v-col class="py-1 cell-title">
          상태
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.usSttusNm }}
        </v-col>
      </v-row>
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          남은시간
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.RMN_TIM }}
        </v-col>
        <v-col class="py-1 cell-title">
          유효기간
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.VALD_PD }}
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-container fluid>
        <v-table
          fixed-header
          height="200px"
          density="compact"
          class="px-0 mx-0"
        >
          <thead>
            <tr>
              <th class="text-center px-1">
                순번
              </th>
              <th class="text-center px-1">
                입실
              </th>
              <th class="text-center px-1">
                종료
              </th>
              <th class="text-center px-1">
                좌석
              </th>
              <th class="text-center px-1">
                퇴실처리
              </th>
            </tr>
          </thead>
          <tbody v-if="useHist && useHist.length > 0">
            <tr
              v-for="item in useHist"
              :key="item.hstSrno"
            >
              <td class="text-center px-1">{{ item.hstSrno }}</td>
              <td class="text-center px-1">{{ item.BGN_TIM }}</td>
              <td class="text-center px-1">{{ item.END_TIM }}</td>
              <td class="text-center px-1">{{ item.stNo }}</td>
              <td class="text-center px-1">{{ item.ATMC_AT }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">이용내역이 없습니다.</td>
            </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-card>
 
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url';

export default {
  props: {
    pytItem: {    /* 화면 표시용 결제 내역 - 2023.03.18 */
      type: Object,
      default: null
    },
  },

  data: () => ({
    useHist: [
/*      
    {
        hstSrno: 5,                // 사용일련번호
        stNo: 23,                  // 좌석번호
        bgnTim: '20210626220742',  // 입실시간 yyyymmddhhmiss
        endTim: '20210627000742',  // 종료시간 yyyymmddhhmiss
        atmcAt: 'Y',               // 자동종료 여부
      },
*/      
    ],

  }),

  mounted() {
    Com.log("MySeatUseList pytItem", this.pytItem);
    this.getSeatUseHist();
  },

  methods: {

    /**
     * 사용자의 좌석 이용내역 조회 - 2023.10.28
     */
    async getSeatUseHist() {

      const param = { pytId: this.pytItem.pytId };

      // 좌석 이용내역 조회
      const res = await Com.callServer(URL.MBER.GET_SEAT_USE_HIST, param);

      if (res !== null && res.rStatus === 0) {
        this.useHist = res.retList;
      }

      this.setDispFormat();
    },


    /**
     * 결제 리스트 화면 표시 텍스트 설정 - 2023.03.21
     */
     setDispFormat() {
      this.useHist.forEach(hist => {
        hist.BGN_TIM = this.formatter(hist, 'BGN-TIM');
        hist.END_TIM = this.formatter(hist, 'END-TIM');
        hist.ATMC_AT = this.formatter(hist, 'ATMC-AT');
      });
    },

    /**
     * 화면에 표시되는 형식으로 문자열 편집 - 2023.03.17
     * @param {화면 표시 데이터 행} item 
     * @param {화면 표시 데이터 필드명} field 
     */
     formatter(item, field) {

      let retVal = '';

      switch (field) {
        case 'BGN-TIM': /* 시작시간  */
          retVal = Com.stringFormatter(item.bgnTim, 'MMDDHHMI1');
          break;

        case 'END-TIM': /* 종료시간  */
          retVal = Com.stringFormatter(item.endTim, 'DATETIME-HHMI2');
          break;

        case 'ATMC-AT': /* 퇴실처리  */
          retVal = (item.atmcAt=='Y'?'미처리':'처리');
          break;

        default:
          retVal = '잘못된 유형';
          break;
      }

      return retVal;
      },

  }
}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  font-size: 13px !important;
}
.cell-text {
  background-color: #ebecea;
  font-size: 13px;
}
</style>
