<template>
  <v-container class="ma-0 pa-0">

    <v-card class="ma-4 pa-0 text-center"
      title="스터디룸 예약 확인"
      min-width="340"
    >

      <table
        width="320px"
      >
        <thead class="text-center">
          <tr height="30px">
            <th width="240px" class="cell-title">
              예약내역
            </th>
            <th width="80px" class="cell-title">
              가격
            </th>
          </tr>
        </thead>
      </table>

      <v-card
        max-height="150px"
        class="overflow-y-auto"
      >
          <table
            width="320px"
          >
            <tbody
              v-for="(item, idx) in items"
              :key="idx"
              class="cell-text"
            >
              <tr height="30px">
                <td width="160px" class="cell-text">
                  {{ item.roomNam }}
                </td>
                <td width="80px" class="cell-text">
                  {{ item.usStCnt }} 명
                </td>
                <td width="80px" rowspan="2" class="cell-text">
                  {{ item.prdtAmtNm }}
                </td>
              </tr>
              <tr height="30px">
                <td class="cell-text">
                  {{ item.usDeTitle }} {{ item.mngTmNm4Cfn }}
                </td>
                <td class="cell-text">
                  {{ item.prdtPdNm }}
                </td>
              </tr>
            </tbody>
          </table>
      </v-card>

      <table
        width="320px"
        class="mt-1"
      >
        <thead class="text-center">
          <tr height="30px">
            <th width="120px" class="cell-title">
              결제금액 ({{ items.length }} 건)
            </th>
            <th width="200px" class="cell-text">
              {{ totalAmtText }} 원
            </th>
          </tr>
        </thead>
      </table>

    </v-card>

    <div class="d-flex justify-space-around align-center flex-column flex-md-row fill-height">

<!--
      <v-btn
        size="normal"
        color="success"
        class="pa-3 ma-3"
      >
        예약하기(네이버페이)
      </v-btn>
-->

      <v-btn
        size="normal"
        color="primary"
        class="pa-3 ma-3"
        @click="$emit('clickBank')"
        :disabled="!branch.bankAcct"
      >
        <span v-if="isLogined">
          예약하기(무통장 입금)
        </span>
        <span v-else>
          예약자 정보 입력
        </span>
      </v-btn>
    </div>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';

import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  props: {
    items: {    /* 예약 내역 - 2023.03.23 */
      type: Object,
      default: null
    },
    totalAmt: { /* 전체 금액 - 2023.04.18 */
      type: Number,
      default: 0
    }
  },

  setup() {

    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { branch };
  },

  data: () => ({
    totalAmtText: undefined,    /* 전체 결제 금액 화면표시내용 - 2023.04.17 */
    isLogined: false,           /* 로그인 여부 - 2024.07.03 */
  }),

  async mounted() {
    // 가격 텍스트 설정
    this.totalAmtText = Com.stringFormatter(this.totalAmt, 'CURRENCY');
    // 로그인 여부 확인
    this.isLogined = await Com.isLogined();
  },

  methods: {
  }

}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  border: none;
  font-size: 14px !important;
}
.cell-text {
  background-color: #ebecea;
  border: none;
  font-size: 14px;
}
</style>
