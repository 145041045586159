<template>
  <v-container class="ma-0 pa-0 seat-container" style="position: absolute;">

    <confirm-view ref="cf"/>

    <div id="panzoom-element"
    :style="{
              position: 'relative',
              width: pageSize.width + 'px',
              height: pageSize.height + 'px',
              /* 2023.06.29 - backgroundImage: 'url(' + bgImg.path + ')', */
              backgroundImage: 'url(' + imgPath + ')', /* 이미지 통합 - 2023.06.29 */
              backgroundSize: pageSize.width + 'px ' + pageSize.height + 'px',
              backgroundRepeat: 'no-repeat',
            }"
  >

    <div v-for="(seat, idx) in seats" :key="idx">

      <!-- 좌석 번호 / 룸이름 표시 begin - 2023.04.11 -->
      <div pill variant="light"
        :style="{position: 'absolute',
          left: (seat.posx*1-(size.seatNumLM))+'px', top: (seat.posy*1)+'px',
          width: (seat.stTyCd==='01'?badgeSize.width:badgeSize.roomWidth) + 'px',
          height: badgeSize.height + 'px',
          padding: '0px 0px 0px 0px',
          margin: seat.stTyCd==='01'?'0px': margin(seat),
          font: size.seatNumFont + 'px verdana',
          zIndex: 1}"
      >
        <span v-if="seat.stTyCd === '01'">{{seat.stNo}}</span>
        <span v-else>{{seat.roomNam}}</span>
      </div>
      <!-- 좌석 번호 / 룸이름 표시 end -->

      <div
        class="m-0"
        :style="{
                  position: 'absolute',
                  left: seat.posx+'px',
                  top: seat.posy+'px',
                  width: seat.stWdh+'px',
                  height: seat.stHgt+'px'
                }"
        :class="{
                  normal: (seat.stTyCd==='01'),
                  studyroom: (seat.stTyCd==='02'),
                  myseat: seat.isMine,
                  useseat: seat.mpnum && !seat.isMine,
                  cantuse: !seat.mpnum
                }"
      >
        <div class="seatdesc" v-if="seat.mpnum">
            <br/><span>{{endPrrngTim(seat)}}</span>
        </div>
      </div>

      <!--고정좌석 여부 표시 - 2021.07.16 -->
      <div v-if="seat.fxdStAt==='Y'"
        :style="{
                  position: 'absolute',
                  left: seat.posx + 'px',
                  top: (seat.posy*1 + size.pinTM) + 'px',
                  color: 'red'
                }">
        <icon-base
          :viewBox="'0 0 ' + size.pinBox + ' ' + size.pinBox"
          :width="'' + size.pin"
          :height="'' + size.pin"
          icon-color="rgba(255, 0, 0, 0.80)"
          icon-name="pin">
          <icon-pin />
        </icon-base>
      </div>

    </div>

  </div>
  </v-container>
</template>

<script>
import Com from '@/lib/common';
import URL from '@/lib/url';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from 'vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
// import { useDisplay } from 'vuetify';

//import Panzoom from '@panzoom/panzoom';

const SIZE_WIDTH = 1050;
//const SIZE_HEIGHT = 1275;
const SIZE_HEIGHT = 1475;
//const ZOOM_LEVEL = 0.36;
const ZOOM_LEVEL = 0.8;

export default {
  setup() {
    const store = useStore();
    // const { mobile } = useDisplay();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    // 로그인 정보 - 2023.10.12
    const login = computed(() => store.getters.login);

    // 배경 이미지 정보 - 2023.06.29
    const imgPath = computed(() => URL.COMMON.ASSET_PATH + "?type=11&shpId=" + branch.value.shpId)

    onMounted(() => {
      //Com.log("SeatStatus mobile", mobile.value)
    })

    return { store, branch, login, imgPath };
  },

  components: { ConfirmView },

  data: () => ({
    pageSize: {
      width: 1035,
      height: 562
    },
    // 좌석번호 및 스터디룸 이름 크기
    badgeSize: {
        width: 25, 
        height: 25,
        roomWidth: 38
    },

    variantValue: {FM: "success", F: "danger", M: "primary"},
    
    bgImg: {},  /* 좌석 배치 배경 이미지 정보 - 2023.04.11 */
    seats: [],  /* 좌석 사용 현황 리스트 - 2023.04.10 */

    size: {     /* 화면에서 사용되는 크기, 화면 배율을 위해 변수로 사용 - 2023.04.11 */
      seatNumFont: 11,  // seat number text font size
      seatNumLM: 2,     // seat number text left margin arrange value
      pinTM: 13,        // fixed seat pin top margin
      pin: 36,          // fixed seat pin size
      pinBox: 36,       // fixed seat pin view box size
      chair: 20,        // study room chair size
    },
  }),

  mounted() {
/*    
    const elem = document.getElementById('panzoom-element');
    const panzoom = Panzoom(elem, {maxScale: 1});
    panzoom.zoom(0.36);
    setTimeout(() => panzoom.pan(100, 100));
*/

    // this.init();
    // 직접 호출된 경우 지점 정보 조회 보다 먼저 호출됨 - 2023.04.12
    // 지점 정보가 설정될 때까지 기다림
    Com.waitBranchEvtNExeFunc(this.init);

  },

  methods: {

    /** 좌석 현황 조회 - 2023.04.10
     */
    async init() {

      const param = { shpId: this.branch.shpId};

      // 서버 좌석 현황 조회
      const res = await Com.callServer(URL.PBLC.FETCH_SEAT_STATUS, param);

      // 좌석 현황을 성공적으로 조회한 경우
      if (res !== null) {
        if (res.rStatus === 0) {
          // return
          //  - retObject : { stImagePath, imgPath, imgWdh, imgHgt },
          //  - retList : { stNo, posX, posY, stWdh, stHgt, stTyCd, roomNam, stDirCd, stTopCnt, stRightCnt, stBottomCnt, stLeftCnt, fxdStAt, gndrUsCd, mpnum, ptyId, prdtTyCd, bgnTim, endPrrngTim }
          this.seats = res.retList;

          this.seats.forEach((seat) => {
            seat.posx = Math.floor(seat.posx * ZOOM_LEVEL);
            seat.posy = Math.floor(seat.posy * ZOOM_LEVEL);
            seat.stWdh = Math.floor(seat.stWdh * ZOOM_LEVEL);
            seat.stHgt = Math.floor(seat.stHgt * ZOOM_LEVEL);
            // 내 좌석 여부 설정 - 2023.10.12
            seat.isMine = (this.login.isLogined && this.login.mpnum === seat.mpnum);
          });

          // 배경 이미지 설정 - 2023.04.11
          this.bgImg = {
            path:  res.retObject.stImgPath + res.retObject.imgPath,
            height: res.retObject.imgHgt * ZOOM_LEVEL,
            width: res.retObject.imgWdh * ZOOM_LEVEL,
          };

          // 사이즈 변경 - 2023.04.11
          this.size.seatNumFont = Math.floor(this.size.seatNumFont * ZOOM_LEVEL);
          this.size.seatNumLM = Math.floor(this.size.seatNumLM / ZOOM_LEVEL);
          this.size.pinTM = Math.ceil(this.size.pinTM * ZOOM_LEVEL);
          this.size.pin = Math.floor(this.size.pin * ZOOM_LEVEL);
          //this.size.pinBox = Math.floor(this.size.pinBox * ZOOM_LEVEL);
          this.size.chair = Math.floor(this.size.chair * ZOOM_LEVEL);

          const WIDTH = SIZE_WIDTH * ZOOM_LEVEL;
          const HEIGHT = (SIZE_HEIGHT - 100) * ZOOM_LEVEL;
          const imgRatio = this.bgImg.height / this.bgImg.width;
          const bgRatio = HEIGHT / WIDTH;

          // 세로의 비율이 큰경우
          if (imgRatio >= bgRatio) {
            this.pageSize.height = HEIGHT;
            this.pageSize.width = Math.floor( this.bgImg.width * HEIGHT / this.bgImg.height);
          }
          // 가로의 비율이 큰경우
          else {
            this.pageSize.width = WIDTH;
            this.pageSize.height = Math.floor(this.bgImg.height * WIDTH / this.bgImg.width);
          }


          Com.log("SeatStatus.init pageSize", this.pageSize);
          Com.log("SeatStatus.init bgImag", this.bgImg);
          Com.log("SeatStatus.init size", this.size);
        }
        else {
          Com.log("SeatStatus.init", res.errorMsg, "error");
          //alert("좌석현황 조회중 오류가 발생했습니다.(" + res.errorMsg + ")");
          this.$refs.cf.msgOk("좌석현황 조회중 오류가 발생했습니다.(" + res.errorMsg + ")", "error");
        }
      }
    },

    margin (seat) {
      let margin = "0px 0px 0px 0px";
      const stWdh = seat.stWdh;
      const stHgt = seat.stHgt;
      const halfHeight = this.badgeSize.height/2;
      const halfWidth = this.badgeSize.width/2;
      const widthMargin = (stWdh/2 - halfWidth);

      // 1인용 좌석인 경우
      if (seat.stTyCd === "01") {
        const heightMargin = (stHgt/2- halfHeight);

        switch(seat.stDirCd) {
        case "01":
          margin = -this.badgeSize.height + "px 0px 0px " + widthMargin + "px";
          break;
        
        case "02":
          margin = heightMargin + "px 0px 0px " + stWdh + "px";
          break;

        case "03":
          margin = stHgt + "px 0px 0px " + widthMargin + "px";
          break;

        case "04":
          margin = heightMargin + "px 0px 0px " + (-this.badgeSize.width) + "px";
          break;
        }				
      }

      // 스터디 룸인 경우
      // 번호를 화면 중앙 배치
      else if (seat.stTyCd === "02") {
        margin = "0px 0px 0px " + (seat.stWdh/2 - this.badgeSize.roomWidth/2) + "px";
      }

      return margin;

    },

    mpnum (seat) {
      return Com.stringFormatter(seat.mpnum, "TEL");
    },

    endPrrngTim (seat) {
      if (seat.prdtTyCd === "04") {
        return Com.stringFormatter(seat.endPrrngTim, "DATETIME-DAY2");
      }
      else {
        return Com.stringFormatter(seat.endPrrngTim, "DATETIME-HHMI2");
      }
    },

  },
}
</script>

<style scoped>
  .seat-container {
    height: 600px;
    overflow: auto;
  }
  .normal {
		background-color: white;
		border: 1px solid black;
		opacity: 1.0;
	}
	.studyroom {
		background-color: green;
		border: 1px solid black;
		opacity: 0.3;
	}
  .cantuse{
		background-color: gray;
		border: 1px solid black;
		opacity: 0.5;
  }
  .useseat {
    background-color:coral;
    color: floralwhite;
  }
  .seattext {
    text-align: end;
    padding: 0px 0px 4px 0px;
  }
  .seattitle {
    font: 10px "verdana";
    padding: 0px 1px 1px 0px;
  }
.seatdesc {
    font: 11px "verdana";
  /*  border: 1px solid rgb(214, 214, 214);*/
    text-align: end;
    padding: 3px 5px;
  }
  .seatnum {
    font: 11px "verdana";
  }

</style>
