<template>
  <VueDatePicker auto-apply state
    v-model="initDate"
    :enable-time-picker="false"
    :minDate="new Date()"
    :maxDate="maxReserveDate"
    @update:model-value="handleDate"
  >
    <template #calendar-header="{ index, day }">
      <div :class="index === 5 || index === 6 ? 'red-color' : ''">
        {{ day }}
      </div>
    </template>              
    <template #trigger>
      <slot></slot>
      <div></div> <!--VueDatePicker에서 클릭 이벤트를 받기 위해 추가 함 - 2023.04.14-->
    </template>
  </VueDatePicker>
</template>

<script>
import Com from '@/lib/common';

import { computed } from "vue";
import { useStore } from 'vuex';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  props: {
    date: {
      type: Object,
      default: new Date()
    },
  },

  components: { VueDatePicker },

  setup() {
    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    /**
     * 예약 가능한 최대 일자 조회 - 2023.03.14
     */
    const maxReserveDate = computed(() => {
      const date = new Date();
      date.setDate(date.getDate() + branch.value.bookLimitDay);

      return date;
    })

    return { branch, maxReserveDate };
  },

  data: () => ({
    initDate: undefined,
  }),

  mounted() {
    this.initDate = this.date;
  },

  methods: {

    /** 데이트 변경 이벤트 핸들러 - 2023.04.14
     * 2023.04.16 - 최대 예약가능 일자까지 남은 주간수 추가
     */
    handleDate (modelDate) {
      Com.log("DatePicker.handleDate modelDate", modelDate);
      // 7일, 24시간, 60분, 60초, 1000 밀리세크
      const weekToLimit = Math.floor((this.maxReserveDate - modelDate) / (7 * 24 * 60 * 60 * 1000));
      this.$emit('dateChanged', {
        date: modelDate,
        weekToLimit: weekToLimit
      });
    },

  },
}
</script>
