<template>

  <div class="py-2">

    <confirm-view ref="cf"/>

    <!-- 예약 확인 팝업 begin - 2023.03.23 -->
    <v-dialog
      v-model="confirmDialog"
      width="auto"
      class="pa-0"
    >
      <v-card class="pa-0">
        <v-card-item class="pa-0">
          <reserve-room-confirm
            :items="reserveInfo"
            :totalAmt="totalAmt"
            @reserveResult="procAfterReserve"
            @clickBank="goToPay"
          ></reserve-room-confirm>           
        </v-card-item>
        <v-card-actions>
          <v-btn color="primary" block @click="confirmDialog = false">예약수정</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 예약 확인 팝업 end - 2023.03.23 -->

    <!-- 무통장 입금 팝업 begin - 2023.03.23 -->
    <v-dialog
      v-model="bankDialog"
      width="auto"
      class="pa-0"
    >
      <v-card class="pa-0">
        <v-card-item class="pa-0">
          <reserve-by-bank
            :items="reserveInfo"
            :totalAmt="totalAmt"
            :buyer-info="buyerInfo"
            @completed="bankDialog = false, $router.push({path: '/' + branch.mblSiteId + '/STRStatus'})"
          ></reserve-by-bank>           
        </v-card-item>
        <v-card-actions>
          <v-btn color="primary" block @click="bankDialog = false, confirmDialog = true">이전으로</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 무통장 입금 팝업 end - 2023.03.23 -->

    <!-- 구매 약관(비로그인 정보입력) 팝업 begin - 2024.03.25 -->
    <v-dialog
      v-model="buyAgreementDialog"
      width="auto"
      class="pa-0"
    >
      <v-card class="pa-0">
        <v-card-item class="pa-0">
          <buy-agreement
            :default-buy-mpnum="buyerInfo.buyMpnum"
            @agree="agreeForBuy"
          ></buy-agreement>           
        </v-card-item>
        <v-card-actions>
          <v-btn color="primary" block @click="buyAgreementDialog = false">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 구매 약관(비로그인 정보입력) 팝업 end - 2023.03.23 -->

    <v-sheet
      class="mx-auto px-8 pt-2 pb-6"
      elevation="8"
      width="400"
      rounded="lg"
    >
      <div class="mx-auto my-1 text-h6">스터디룸 예약 입력</div>

      <!--<div class="text-subtitle-1 text-medium-emphasis d-flex my-0">스터디룸</div>-->
      
      <v-row class="pa-0 ma-0">
        <v-col class="px-0 py-0">
          <v-select
            label="스터디룸"
            v-model="stNo"
            :items="studyRoomList"
            item-title="title"
            item-value="stNo"
            density="compact"
          ></v-select>
        </v-col>
<!--
        <v-col cols="4" class="px-0 py-0">
          <v-select
            label="사용인원"
            v-model="usStCnt"
            :items="usStCntList"
            item-title="title"
            item-value="usStCnt"
            density="compact"
          ></v-select>
        </v-col>
-->        
      </v-row>

      <!-- 시간추가 시작 - 2023.04.14 -->
      <!-- 일자, 시간 행 begin -->
      <v-row class="ma-0 pa-0">
        <v-col cols="6" class="ma-0 py-0">

          <!-- 사용일자 begin - 2023.04.15 -->
          <DatePicker
            :date="date"
            @dateChanged="dateChanged"
          >
            <v-text-field
              density="compact"
              prepend-inner-icon="mdi-calendar-outline"
              variant="outlined"
            >{{selectedDateText}}</v-text-field>
          </DatePicker>
          <!-- 사용일자 end - 2023.04.15 -->

        </v-col>  
        <v-col cols="6" class="ma-0 py-0">

          <!-- 시작시간 지정 begin - 2023.04.15 -->
          <v-dialog
            v-model="timeSelectDialog"
            width="auto"
            class="pa-0"
          >
            <template v-slot:activator="{ props }">
              <v-sheet class="mx-0 pa-2 text-center"
                v-bind="props"
                border
                rounded
              >{{ getTimeFormat() }}</v-sheet>
            </template>
      
            <v-card class="pa-0" max-width="380">
              <v-card-item class="pa-0">

                <!-- 시간선택 팝업의 타이틀 begin - 2023.04.14 -->
                <v-sheet class="mx-3 rounded-sm mt-4 py-2 border" width="340">
                  <v-row class="justify-center align-center text-center">
                    <v-col>
                      {{ selectedSTR.title }}
                    </v-col>
                    <v-col>
                      {{ selectedDateText }}
                    </v-col>
                  </v-row>
                </v-sheet>
                <!-- 시간선택 팝업의 타이틀 end - 2023.04.14 -->

                <room-time-status
                  :st-no="stNo"
                  :date="date"
                  :is-reserve-mode="true"
                  :width="340"
                  @timeSelect="onTimeSelect"
                >
                </room-time-status>
              </v-card-item>
              <v-card-actions>
                <v-btn color="primary" block @click="timeSelectDialog = false">닫기</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 시작시간 지정 end - 2023.04.15 -->
          
        </v-col>  
      </v-row>
      <!-- 일자, 시간 행 end -->

      <v-select v-if="strPrdtList"
        label="사용시간"
        :items="dispPrdtList"
        item-title="usPdNm"
        item-value="prdtId"
        v-model="selPrdtId"
        density="compact"
      ></v-select>
  
      <!-- 주단위 반복 예약 정보 - 2023.4.16
            - 선택된 날짜가 반복에약이 가능한 경우에만 표시됨
       -->
      <v-select
        v-if="repeatableWeek.length > 1"
        label="반복예약"
        v-model="repeatWeek"
        :items="repeatableWeek"
        item-title="title"
        item-value="week"
        item-disabled="disable"
        density="compact"
      >
      </v-select>

      <div class="d-flex justify-space-around">
        <v-btn
          color="blue"
          size="large"
          variant="tonal"
          width="150"
          @click="addReserveItem"
        >
          다중 예약 추가
        </v-btn>
        <v-btn
          color="blue"
          size="large"
          variant="tonal"
          width="150"
          :disabled="reserveItems.length > 0"
          @click="directReserveItem"
        >
          즉시 예약 하기
        </v-btn>
      </div>

      <div class="d-flex justify-space-between mt-5">
        <div class="text-subtitle-1 text-medium-emphasis">
          다중예약대상 <span v-if="reserveItems.length">({{ reserveItems.length }})건</span>
        </div>
        <v-btn
          density="compact"
          class="p1-2"
          @click="removeReserveItem()"
        >전체삭제</v-btn>
      </div>
      <v-card
        class="mx-auto pa-0 overflow-auto"
        elevation="4"
        max-width="400"
        max-height="100"
        min-height="100"
      >
        <v-card-item class="d-flex justify-left ma-0 pa-0">
          <v-list lines="one" density="compact" class="my-2 pa-0">
            <div
              v-for="(item, idx) in reserveItems"
              :key="idx"
              class="d-flex justify-left align-center my-1 pl-2">
              <v-btn
                size="small"
                prepend-icon="mdi-delete-forever"
                @click="removeReserveItem(item)"
              >삭제</v-btn>
              <v-list-item
                :subtitle="'[' + item.roomNam + '] ' + item.usDeTitle + ' ' + item.mngTmNm + ' {' + item.prdtPdNm + '}'"
              >
              </v-list-item>
            </div>
          </v-list>
        </v-card-item>
      </v-card>

      <v-btn
        block
        class="mt-3"
        color="blue"
        size="large"
        variant="tonal"
        @click="passReserveItemsToConfirm()"
        :disabled="reserveItems.length < 1"
      >
        다중 예약하기
        <span v-if="reserveItems.length > 0">
          ({{ reserveItems.length }}건)
        </span>
      </v-btn>
      
    </v-sheet>
  </div>

</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';
import RoomTimeStatus from '@/components/RoomTimeStatus.vue';
import ReserveRoomConfirm from '@/components/ReserveRoomConfirm.vue';
import ReserveByBank from '@/components/ReserveByBank.vue';
import DatePicker from '@/components/DatePicker.vue';
import ConfirmView from '@/components/ConfirmView.vue';
import BuyAgreement from '@/components/BuyAgreement.vue';

import { watch, ref, computed } from "vue";
import { useStore } from 'vuex';

// 한번에 예약 가능한 건수 - 2023.04.16
const MAX_RESERVE_CNT = 10;

export default {
  components: { DatePicker, RoomTimeStatus, ReserveRoomConfirm, ReserveByBank, ConfirmView, BuyAgreement },

  setup() {
    // 선택된 스터디룸 번호 - 2023.04.14
    const stNo = ref(0);

    // 사용인원 선택 리스트 - 2023.04.17
    const usStCnt = ref(0);

    // 사용인원 리스트 - 2023.04.17
    //const usStCntList = ref([]);

    // 선택된 스터디룸 정보 - 2023.04.14
    const selectedSTR = ref('');

    // 스터디룸 상품 목록 - 2023.04.14
    const strPrdtList = ref('');

    // 예약일자 - 2023.04.15
    const date = ref(new Date());

    // 시작시간 - 2023.04.15
    const startTime = ref('');

    // 화면 표시 날짜 - 2023.04.15
    const selectedDateText = ref('');

    // 반복 예약 주 - 2023.04.15
    const repeatWeek = ref(1);

    const store = useStore();

    // 스터디룸 목록
    const studyRoomList = computed(() => store.getters.studyRoomList);

    // 스터디룸 선택 설정 - 2023.04.14
    watch(stNo, (value) => {
      Com.log("ReserveRoom.watch stNo", stNo);
      Com.log("ReserveRoom.watch value", value);

      if (value) {
        // 예약일자 초기화
        date.value = new Date();

        Com.log("ReserveRoom.watch date", date);
        
        // 스터디룸 목록이 있는 경우 스터디룸 선택값 및 상품목록 초기화
        if (studyRoomList.value) {

          // 스터디룸 선택 초기화
          selectedSTR.value = studyRoomList.value.filter(item => item.stNo === stNo.value)[0];
          
          // 상품 목록 초기화
          strPrdtList.value = store.getters.strPrdtList(selectedSTR.value.usNmpr);

          // 사용인원 초기화 - 2023.04.17
          //usStCnt.value = 1;
          usStCnt.value = selectedSTR.value.stCnt;
/* 2023.09.09
          // 사용인원 선택리스트 설정 - 2023.04.17
          usStCntList.value = [];
          for(let i = 1; i <= selectedSTR.value.stCnt; ++i) {
            usStCntList.value.push({
              usStCnt: i,
              title: i + '명',
            })
          }
*/        
        }
      }
    })

    // 날짜변경 이벤트 - 2023.04.15
    watch(date, (value /*, oldValue */) => {
      //if (value && Com.getFormatDate(value, 'yyyymmdd') !== Com.getFormatDate(oldValue, 'yyyymmdd')) {
        //selectedDateText.value = Com.dateFormatter(this.date, '${mm}월${dd}일(${w})');

        // 화면표시날짜 변경
        selectedDateText.value = Com.getFormatDate(value, 'm/d(w)');

        // 시작 시간 초기화
        startTime.value = undefined;

        // 예약반복 주간 주 초기화
        repeatWeek.value = 1;
      //}
    })

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { stNo, usStCnt, selectedSTR, studyRoomList, strPrdtList, branch,
      date, startTime, selectedDateText, repeatWeek };
  },

  data: () => ({
    timeSelectDialog: false,  /* 시간 선택 다이얼 로그 오픈 여부 - 2023.03.14  */
    selPrdtId: undefined,     /* 선택상품 - 2023.03.16  */
    dispPrdtList: undefined,  /* 화면 표시 스터디룸 상품 - 2023.04.15 */
    reserveItems: [],         /* 예약대기 목록 - 2023.04.16 */
    repeatableWeek: [],       /* 반복예약 가능 주간 - 2023.03.16 */
    confirmDialog: false,     /* 예약 확인용 dialog - 2023.03.23  */
    reserveInfo: [],          /* 사용자 예약 정보  - 2023.04.17  */
    bankDialog: false,        /* 무통장 입금 예약 dialog - 2023.03.23  */
    totalAmt: 0,              /* 전체 상품 금액 - 2023.04.18 */
    buyAgreementDialog: false,/* 구매 동의 용 dialog - 2024.03.25  */
    buyerInfo:                /* 비회원 구매시 구매자 정보 - 2024.03.25 */
      { buyMpnum: '', buyPwd: '' },
  }),

  mounted() {
    // 비로그인 예약을 위한 기능 추가 - 2024.03.25
    // 로그인 또는 비로그인 예약에 대한 확인
    this.checkLogin();

    // 화면표시날짜 지정 - 2023.04.15
    this.selectedDateText = Com.getFormatDate(this.date, 'm/d(w)');

    // 반복문자열 생성 - 2023.04.415
    this.setRepeatWeekList(Math.floor(this.branch.bookLimitDay / 7));

    // 직접 호출된 경우 지점 정보 조회 보다 먼저 호출됨 - 2023.04.12
    // 지점 정보가 설정될 때까지 기다림
    Com.waitSTRListEvtNExeFunc(() => {
      this.stNo = this.studyRoomList[0].stNo;
    });

  },

  watch: {
    // 상품리스트가 바뀐 경우 화면 표시 상품 리스트 변경 - 2023.04.15
    strPrdtList() {
      this.setDispPrdtList();
    }
  },

  methods: {

    /**
     * 구매동의 완료 이벤트 핸들러 - 2024.03.25
     * @param {비로그인 구매자 정보} buyerInfo 
     */
    agreeForBuy(buyerInfo) {
      Com.log("ReserveRoom.agreeForBuy", buyerInfo);

      this.buyerInfo = buyerInfo;

      // 구매 동의 창닫고 결제 정보 창 열기
      this.buyAgreementDialog = false;
      this.bankDialog = true;
    },


    /**
     * 로그인 여부 확인 및 비로그인 예약 여부 선택 - 2024.03.25
     */
    async checkLogin() {

      const isLogined = await Com.isLogined();

      // 로그인이 안되어 있는 경우
      if (!isLogined) {

        // 로그인 예약 or 비로그인 예약에 대한 선택
        const res = await this.$refs.cf.msgConfirm("스터디룸 예약을 위해 로그인 하시겠습니까?",
           'info', '로그인', '비로그인 예약');
      
        if (res) {
          // 로그인
          this.$router.push({path: '/' + this.branch.mblSiteId + '/login', query: {fromPage: 'ReserveRoom', toPage: 'ReserveRoom'}});
        }
        return;
      }

    },


    /**
     * 예약처리후 메시지 표시 또는 화면 처리 - 2023.03.23
     * @param {예약결과상태} reserveStatus 
     */
    procAfterReserve(reserveStatus) {
      if (reserveStatus == 1) {
        //alert("스터디룸 예약이 완료되었습니다.");
        this.$refs.cf.toast("스터디룸 예약이 완료되었습니다.", "success");
      }
      else if (reserveStatus == 2) {
        //alert("스터디룸 예약이 완료되었습니다. 지정시간내 입금이 되지 않을 경우 예약은 자동 취소 됩니다.");
        this.$refs.cf.toast("스터디룸 예약이 완료되었습니다. 지정시간내 입금이 되지 않을 경우 예약은 자동 취소 됩니다.", "success");
      }
      else {
        //alert("예약이 취소 되었습니다.");
        this.$refs.cf.toast("예약이 취소 되었습니다.", "success");
      }
    },

    /**
     * 선택된 시간 저장 - 2023.04.14
     * @param {선택된 시간 정보} timeInfo
     */
    onTimeSelect(timeInfo) {
      Com.log("ReserveRoom.onTimeSelect timeInfo", timeInfo);
      this.startTime = timeInfo.time.mngTmCd;

      // 예약 가능 시간에 따른 예약 가능 상품 설정 - 2023.04.15
      this.setDispPrdtList(timeInfo.availableCnt);

      this.timeSelectDialog = false;
    },

    /** 예약일 기준 사용가능한 상품리스트 설정 - 2023.04.15
     * @param {30단위 예약가능 타임 개수} availableCnt 
     */
    setDispPrdtList(availableCnt) {
      this.dispPrdtList = [];

      // 선택상품 초기화
      this.selPrdtId = undefined;

      // 리스트에 추가할 데이터 건수
      const limitTime = (availableCnt ? availableCnt * 0.5 : 100);
      
      // 서버상품이 조회된 경우
      if (this.strPrdtList) {
        // 예약가능한 상품 추가
        // 상품시간이 1시간 이상 이며 남은 시간 보다 작거나 같은 상품
        // - 30분짜리 상품은 연장 상품임
        for (let i = 0; i < this.strPrdtList.length; ++i) {
          //Com.log("ReserveRoom.setDispPrdtList", this.strPrdtList[i]);

          if (this.strPrdtList[i].usPd <= limitTime &&
            this.strPrdtList[i].usPd >= 1
          ) {
            this.dispPrdtList.push(this.strPrdtList[i]);
          }
        }

        // 사용시간(usPd) 순으로 정렬 - 2023.04.17 
        this.dispPrdtList.sort((a, b) => a.usPd - b.usPd);
      }
    },

    /** 데이터 변경에 대해 이벤트 핸들러 - 2023.04.14
     */
     dateChanged(dateInfo) {
      Com.log("ReserveRoom.dateChanged", dateInfo);
     
      if (Com.getFormatDate(this.date, 'yymmdd') !== Com.getFormatDate(dateInfo.date)) {
        this.date = dateInfo.date;

        // 주단위 반복 선택 리스트 생성 - 2023.04.15
        this.setRepeatWeekList(dateInfo.weekToLimit);
      }
    },

    /** 주단위 반복 선택 리스트 생성 - 2023.04.15
     */
    setRepeatWeekList(weekToLimit) {

      // 주단위 반복 선택 리스트 생성 - 2023.04.15
      this.repeatableWeek = [{
        week: 1,
        usDate: this.date,
        usDe: Com.getFormatDate(this.date, 'yyyymmdd'),
        usDeTitle: Com.getFormatDate(this.date, 'm/d(w)'),
        title: '반복없음' 
      }];

      // 남은 주간에 대한 반복 선택 리스트 생성
      for (let i = 1; i <= weekToLimit; ++i) {
        const usDate = Com.getDateAfterNday(this.date, i * 7);
        const usDe = Com.getFormatDate(usDate, 'yyyymmdd');
        const usDeTitle = Com.getFormatDate(usDate, 'm/d(w)');

        this.repeatableWeek.push({
          week: i + 1,
          usDate: usDate,
          usDe: usDe,
          usDeTitle: usDeTitle,
          title: usDeTitle + '까지 동일시간 ' + (i + 1) + '주간 반복',
        });
      }
      //{ week: 2, title: '3/23(목)까지 동일시간 2주간 반복', disable: false },

      Com.log("ReserveRoom.setRepeatWeekList repeatableWeek", this.repeatableWeek);

    },

    /* 시간 형식 - 2023.03.16 */
    getTimeFormat() {
      if (this.startTime) {
        return Com.stringFormatter(this.startTime, 'AMPM-HHMI1');
      }
      else {
        return "시작 시간 지정";
      }
    },

    /** 즉시 예약 - 2023.04.19
     */
     async directReserveItem() {

      // 1. 예약 가능 여부 확인
      const res = await this.isInputAvailable();

      if (res.status === 0) {
        // 입력 내용에 대한 예약 요청
        this.callConfirmReserve(res.reserveList);
      }
    },

    /** 예약 리스트 추가 - 2023.04.15
     */
    async addReserveItem() {

      // 최대 예약 가능 건수 확인 - 2023.04.16
      if (this.reserveItems.length + this.repeatWeek > MAX_RESERVE_CNT) {
        //alert("최대 예약 가능 건수를 초과하였습니다. 최대 예약 가능 건수는 " +
        //  MAX_RESERVE_CNT + "건 입니다.")
        await this.$refs.cf.msgOk("최대 예약 가능 건수를 초과하였습니다. 최대 예약 가능 건수는 " +
          MAX_RESERVE_CNT + "건 입니다.", "warning");
        return;
      }

      // 1. 예약 가능 여부 확인
      const res = await this.isInputAvailable();

      if (res.status === 0) {

        this.reserveItems.push.apply(this.reserveItems, res.reserveList);

        // 정렬 (스터디룸, 예약일자, 시작시간)
        this.reserveItems.sort((a, b) => {
          if (a.stNo > b.stNo) return 1;
          if (a.stNo === b.stNo) {
            if (a.usDe + a.mngTmCd > b.usDe + b.mngTmCd) return 1;
            if (a.usDe + a.mngTmCd === b.usDe + b.mngTmCd) return 0;
            return -1;
          }
          return -1;
        });

        // 입력값 초기화 - 2023.04.16
        this.date = new Date();
        this.setDispPrdtList();

        //alert(this.repeatWeek + "건이 추가되었습니다.");
        await this.$refs.cf.msgOk(this.repeatWeek + "건이 추가되었습니다.");
      }

    },

    /** 예약 대기 목록 삭제 - 2023.04.16
     *  - item이 지정되지 않는 경우 전체 삭제
     */
    removeReserveItem(item) {
      if (!item) {
        this.reserveItems = [];
      }
      else {
        // item index 찾기
        const idx = this.reserveItems.indexOf(item);
        // index로 item 삭제
        this.reserveItems.splice(idx, 1);
      }
    },

    /** 입력값에 대한 예약 존재여부 확인 - 2023.04.16
     */
    async isInputAvailable() {

      if (!this.startTime) {
        //alert('사용 시작시간을 입력해주세요.');
        await this.$refs.cf.msgOk("사용 시작시간을 입력해주세요.", "warning");
        return 2;
      }

      if (!this.selPrdtId) {
        //alert('사용시간(상품)을 선택해주세요.')
        await this.$refs.cf.msgOk("사용시간(상품)을 선택해주세요.", "warning");
        return 2;
      }

      // 예약일자 리스트 구성
      const usDeList = [];
      this.repeatableWeek
        .filter((item) => item.week <= this.repeatWeek)
        .forEach((item) => usDeList.push({usDe: item.usDe}));

      Com.log("ReserveRoom.isInputAvailable usDeList", usDeList);
      
      const param = {
        shpId: this.branch.shpId,
        stNo: this.stNo,
        usDeList: usDeList,
        mngTmCd: this.startTime,
        prdtId: this.selPrdtId,
      };

      // 서버 조회
      const res = await Com.callServer(URL.PBLC.GET_STR_EXIST_LIST, param);

      // 서버조회 결과값이 있는 경우
      if (res !== null) {

        // 예약이 존재하지 않는 경우
        if (res.rStatus === 0) {

          // 예약 정보
          const reserveList = [];

          // 선택된 상품
          const selPrdt = this.dispPrdtList
            .filter((item) => item.prdtId === this.selPrdtId)[0];
          
          // 시작시간 리스트 화면표시용
          const mngTmNm = Com.stringFormatter(this.startTime, 'HHMI2');
          // 시작시간 confirm 화면표시용
          const mngTmNm4Cfn = Com.stringFormatter(this.startTime, 'AMPM-HHMI1');
          

          this.repeatableWeek.filter((item) => item.week <= this.repeatWeek)
            .forEach((item) => {
              // 예약정보 리스트 추가
              reserveList.push({
                stNo: this.stNo,
                roomNam: this.selectedSTR.roomNam,
                usDe: item.usDe,
                usDeTitle: item.usDeTitle,
                mngTmCd: this.startTime,
                mngTmNm: mngTmNm,
                mngTmNm4Cfn: mngTmNm4Cfn,
                prdtId: this.selPrdtId,
                prdtNm: selPrdt.prdtNm,
                prdtAmt: selPrdt.prdtAmt,
                prdtAmtNm: selPrdt.prdtAmtNm,
                prdtPdNm: selPrdt.usPdNm,
                usStCnt: this.usStCnt,    /* 사용인원 추가 - 2023.04.17 */
              });

            });

          // 결과 return
          return {
            status: 0,
            reserveList: reserveList
          };
        }
        // 이미 예약이 존재하는 경우
        else if (res.rStatus === 31) {
          Com.log("ReserveRoom.isInputAvailable duplicate", res.resList);
          //alert("이미 예약이 존재합니다.");
          await this.$refs.cf.msgOk("이미 예약이 존재합니다.", "warning");
          //alert(res.retList);
          await this.$refs.cf.msgOk(res.retList, "warning");

          return {
            status: 1,
            usDeList: usDeList
          };
        }
        // 오류가 발생한 경우
        else {
          Com.log("ReserveRoom.isInputAvailable", res.errorMsg, "error");
        }
      }

      return {
        status: 2,
        usDeList: usDeList
      };
    },

    /** 다중예약 대기목록 예약 요청 - 2023.04.17
     */
    passReserveItemsToConfirm() {

      // 다중예약리스트에 대한 예약 요청
      this.callConfirmReserve(this.reserveItems);
    },

    /** 예약 화면 호출 - 2023.04.17
    * @param {예약정보목록} reserveInfo
    */
    async callConfirmReserve(reserveInfo) {

      // 전체 금액 설정 - 2023.04.18
      this.totalAmt = 0;
      reserveInfo.forEach((item) => this.totalAmt += (item.prdtAmt*1));

      // 예약확인창 전달 파라미터 저장
      this.reserveInfo = reserveInfo;

      // 예약확인창 화면 표시
      this.confirmDialog = true;
    },

    /**
     * 결제 정보 호출 - 2024.07.03
     * 비로그인 구매 기능 추가로 변경됨
     */
    async goToPay() {
      // 로그인 여부 확인 - 2024.07.03
      const isLogined = await Com.isLogined();

      // 예약확인 창 닫기
      this.confirmDialog = false

      // 로그인 된 경우
      if (isLogined) {
        // 무통장 입금 화면 표시
        this.confirmDialog = true;
        this.bankDialog = true
      }
      // 비로그인 구매의 경우
      else {
        // 구매동의 창 화면 표시 - 2024.03.25
        this.buyAgreementDialog = true;
      }
    },
  },
}
</script>

<style scoped>
  .border {
    border: 1px solie rgb(43, 42, 36);
  }
</style>