<template>
  <v-container
    class="ma-0"
    fluid
  >

    <confirm-view ref="cf"/>

    <v-card
      class="ma-0 px-5 pt-3 pb-0"
      elevation="0"
      min-width="320"
      rounded="lg"
    >
      <div class="text-subtitle-1 text-medium-emphasis d-flex">*휴대폰번호</div>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="buyMpnum"
            density="compact"
            placeholder="'-'제외 숫자만입력"
            prepend-inner-icon="mdi-phone-outline"
            variant="outlined"
            @change="mpnumChanged()"
            @keypress="onlyNumber(event)"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 구매 패스워드 입력 begin -->
      <div class="text-subtitle-1 text-medium-emphasis d-flex">*구매 비밀번호</div>
      <v-text-field
        :append-inner-icon="visible.password ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible.password ? 'text' : 'password'"
        v-model="buyPwd"
        density="compact"
        placeholder="비밀번호(숫자4자리)를 입력하세요."
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible.password = !visible.password"
        @keypress="onlyNumber(event)"
        @change="checkBuyPwdConfirm()"
      ></v-text-field>

      <div class="text-subtitle-1 text-medium-emphasis d-flex">*구매 비밀번호확인</div>
      <v-text-field
        :append-inner-icon="visible.confirm ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible.confirm ? 'text' : 'password'"
        v-model="buyPwdConfirm"
        density="compact"
        placeholder="비밀번호를 한번더 입력하세요."
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        :bg-color="buyPwdConfirmColor"
        @click:append-inner="visible.confirm = !visible.confirm"
        @keypress="onlyNumber(event)"
        @keyup="checkBuyPwdConfirm()"
      ></v-text-field>
      <!-- 패스워드 입력 end -->

      <!-- 구매 약관동의 begin - 2024.07.02 -->
      <div class="d-flex align-end justify-left mt-0">
        <v-sheet class="pb-3">
          <v-checkbox
            v-model="isAgree"
            density="compact"
            color="deep-purple"
            style="height: 20px;"
          ></v-checkbox>
        </v-sheet>
        <h4 class="chk-text-color ml-2 cursor-pointer" 
        @click="policyDialog = true"
        >
          (필수) 구매 약관에 동의
        </h4>
      </div>
      <v-card height="100px" width="350px" style="overflow-y: scroll;" class="mt-2">
        <v-card-text>
          <pre>{{buyAgreement}}</pre>
        </v-card-text>
      </v-card>
      <!-- 구매 약관동의 end - 2024.07.02 -->

      <v-row>
        <v-col>
          <v-btn
            block
            class="mt-4"
            color="blue"
            size="large"
            variant="tonal"
            @click="signUp()"
          >
            예약하기
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';

import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from "vue";
import { useStore } from 'vuex';

export default {

  components: { ConfirmView },
  
  props: {
    defaultBuyMpnum: {  /* 구매자 휴대폰 번호(이전 입력 정보) - 2024.07.03 */
      type: String,
      default: null
    }
  },

  setup() {

    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { branch };
  },

  data: () => ({
    buyMpnum: undefined,        /* 비회원 구매용 휴대폰 번호 - 2024.03.25 */
    buyPwd: undefined,          /* 비회원 구매용 구매 비밀번호 - 2024.03.25 */
    buyPwdConfirm: undefined,   /* 비회원 구매용 구매 비밀번호 확인 - 2024.03.25  */
    isLogined: false,           /* 로그인 여부 - 2024.03.25 */
    buyAgreement: undefined,    /* 구매약관 동의 내용 - 2024.03.26 */
    isAgree: false,             /* 구매약관 동의 여부 - 2024.07.02  */
    buyPwdConfirmColor: undefined,  /* 구매 비번 배경색 - 2024.07.02  */

    visible: {                  /* 패스워드 입력시 화면표시 여부 - 2024.07.02 */
      password: false,
      confirm: false
    },

  }),

  mounted() {
    // 구매자 휴대폰 번호 설정
    this.buyMpnum = this.defaultBuyMpnum;
    
    // 지점 약관 정보 조회
    this.getBranchTerms();
  },

  methods: {

    /**
     * 지점 약관 정보 조회 - 2024.07.02
     */
    async getBranchTerms() {
      const param = {
        shpId: this.branch.shpId,
      };

      // 서버 조회
      const res = await Com.callServer(URL.PBLC.GET_BRANCH_TERMS, param);

      // 서버조회 결과값이 있는 경우
      if (res !== null) {

        // 약관 정보가 존재하는 경우
        if (res.rStatus === 0) {

          // 구매약관(개인정보제공동의 약관) 저장
          this.buyAgreement = res.retObject.prslInfoUseCnst;
        }
        // 약관정보가 존재하지 않는 경우
        else if (res.rStatus === 31) {
          Com.log("BuyAgreement.getBranchTerms not exist", res.retObject);
          await this.$refs.cf.msgOk("구매 약관 정보가 존재하지 않습니다. 관리자에게 문으해 주세요.", "warning");
        }
        // 오류가 발생한 경우
        else {
          Com.log("BuyAgreement.getBranchTerms", res.errorMsg, "error");
        }
      }
    },

    /** 휴대폰 번호 변경 이벤트 핸들러 - 2024.07.02
     */
    mpnumChanged() {
      Com.log("BuyAgreement.mpnumChanged", this.buyMpnum);
      if (this.buyMpnum) {
        this.buyMpnum = this.buyMpnum.trim();
        this.buyMpnum = this.buyMpnum.replace(/-/gi, '').trim();
      }
    },

    /** 숫자입력 이벤트 핸들러 - 2024.07.02
     * @param {keypress 이벤트} event 
     */
    onlyNumber(event) {
      event = (event) ? event : window.event;
      const expect = event.target.value.toString() + event.key.toString();

      if (!Com.isOnlyNumber(expect)) {
        // 입력 차단
        event.preventDefault();
        this.$refs.cf.msgOk("숫자만 입력 가능합니다.", "warning");
      }

      return true;
    },

    /**
     * 구매 비밀 번호 체크 - 2024.07.02
     */
    checkBuyPwdConfirm() {
      if (this.buyPwd != this.buyPwdConfirm) {
        this.buyPwdConfirmColor = "red";
      }
      else {
        this.buyPwdConfirmColor = "";
      }
    },

    /**
     * 구매 약관 동의 - 2024.07.02
     */
    async signUp() {

      // 휴대폰 번호 입력 값 확인 - 2024.07.03
      if (this.buyMpnum) {
        if (this.buyMpnum.length !== 11 ||
          this.buyMpnum.substr(0, 3) !== '010') {

          this.$refs.cf.msgOk("휴대폰번호가 유효하지 않습니다. 휴대폰번호를 확인해주세요.", "warning");
          return;
        }
      }
      else {
        this.$refs.cf.msgOk("휴대폰번호를 입력해주세요.", "warning");
        return;
      }

      // 구매 비밀번호 및 확인 값 입력 확인 - 2024.07.03
      if (!this.buyPwd || this.buyPwd.length != 4) {
        this.$refs.cf.msgOk("구매비밀번호를 4자리 숫자로 입력해 주세요.", "warning");
        return;
      }
      else if (this.buyPwd != this.buyPwdConfirm) {
        this.$refs.cf.msgOk("구매비밀번호와 확인 값이 일치하지 않습니다. 구매비밀번호와 동일한 값을 한번더 입력해 주세요.", "warning");
        return;
      }

      // 구매약관 동의 여부 확인 - 2024.07.03
      if (!this.isAgree) {
        this.$refs.cf.msgOk("구매을 진행하시려면 구매 약관에 동의해야 합니다. ", "warning");
        return;
      }

      // parent에 구매 동의 정보 전달 - 2024.07.03
      this.$emit('agree', {
        buyMpnum: this.buyMpnum,
        buyPwd: this.buyPwd,
      });
    }
  },

}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  border: none;
  font-size: 14px !important;
}
.cell-text {
  background-color: #ebecea;
  border: none;
  font-size: 14px;
}
</style>
