// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Icon
//import kakaoIcon from '@/assets/kakaotalk_logo_icon'
//import { aliases } from "vuetify/iconsets/mdi-svg";

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from "vuetify/iconsets/mdi";

/*
const aliasesCustom = {
    ...aliases,
//    mdi,
    kakaoIcon
}
*/

export default createVuetify({
        icons: {
            defaultSet: 'mdi',
            aliases,
/*            
            aliases: {
                ...aliasesCustom
            },
*/            
            sets: {
                mdi,
            }
        },
    }
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
