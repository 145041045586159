<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :area-labelledby="iconName"
    :style="{zIndex: zIndex}"
  >
    <title :id="iconName" lang="en">{{iconName}}</title>>
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>
<script>
//import Com from '@/lib/common';

export default{
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16"
    },
    iconName: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 36
    },
    iconColor: {
      type: String,
      default: "currentColor"
    },
    zIndex: {
      type: Number,
      defualt: 0
    }
  },
/*
  mounted() {
    Com.log("IconBase " + this.iconName, 'viewBox:' + this.viewBox +
      ", width: " + this.width + ", height: " + this.height +
      ", iconColor: " + this.iconColor);
  }
*/  
}
</script>
