<template>
  <v-container
    class="ma-0"
    fluid
  >

    <confirm-view ref="cf"/>

    <v-card
      class="ma-0 px-5 pt-3 pb-0"
      elevation="0"
      min-width="320"
      rounded="lg"
    >
    <div class="text-subtitle-1 text-medium-emphasis d-flex">*예약 휴대폰번호</div>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="mpnum"
            density="compact"
            placeholder="'-'제외 숫자만입력"
            prepend-inner-icon="mdi-phone-outline"
            variant="outlined"
            @keypress="onlyNumber(event)"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 구매 패스워드 입력 begin -->
      <div class="text-subtitle-1 text-medium-emphasis d-flex">*예약 비밀번호</div>
      <v-text-field
        :append-inner-icon="visible.password ? 'mdi-eye-off' : 'mdi-eye'"
        :type="visible.password ? 'text' : 'password'"
        v-model="buyPwd"
        density="compact"
        placeholder="비밀번호(숫자4자리)를 입력하세요."
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="visible.password = !visible.password"
        @keypress="onlyNumber(event)"
      ></v-text-field>
      <!-- 패스워드 입력 end -->

      <v-row>
        <v-col cols="6">
          <v-btn
            block
            class="mt-4"
            color="blue"
            size="large"
            variant="tonal"
            @click="checkBuyPwd()"
          >
            확인
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            block
            class="mt-4"
            color="blue"
            size="large"
            variant="tonal"
            @click="$emit('cancel')"
          >
            취소
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';

import ConfirmView from '@/components/ConfirmView.vue';

export default {

  components: { ConfirmView },
  
  props: {
    item: {  /* 예약정보 - 2024.07.05 */
      type: Object,
      default: null
    },
  },

  data: () => ({
    mpnum: undefined,     /* 비회원 예약 휴대폰번호 - 2024.07.05 */
    buyPwd: undefined,    /* 비회원 예약 비밀번호 - 2024.07.04 */
    visible: {            /* 패스워드 입력시 화면표시 여부 - 2024.07.04 */
      password: false,
    },

  }),

  mounted() {
    Com.log("InputBuyPwd.mounted", this.buyMpnum + ":" + this.buyPwd);
  },

  methods: {

    /** 숫자입력 이벤트 핸들러 - 2024.07.02
     * @param {keypress 이벤트} event 
     */
    onlyNumber(event) {
      event = (event) ? event : window.event;
      const expect = event.target.value.toString() + event.key.toString();

      if (!Com.isOnlyNumber(expect)) {
        // 입력 차단
        event.preventDefault();
        this.$refs.cf.msgOk("숫자만 입력 가능합니다.", "warning");
      }

      return true;
    },

    /**
     * 예약 정보 확인 - 2024.07.04
     */
    async checkBuyPwd() {

      // 예약 휴대폰번호 확인 - 2024.07.05
      if (!this.mpnum || this.mpnum.length < 1) {
        this.$refs.cf.msgOk("예약 휴대폰번호를 입력해 주세요.", "warning");
        return;
      }
      // 예약 휴대폰번호 확인 - 2024.07.05
      else if (this.mpnum != this.item.mpnum) {
        this.$refs.cf.msgOk("예약 휴대폰번호가 일치하지 않습니다.", "warning");
        return;
      }

      // 예약 비밀번호 확인 - 2024.07.04
      if (!this.buyPwd || this.buyPwd.length < 1) {
        this.$refs.cf.msgOk("예약 비밀번호를 입력해 주세요.", "warning");
        return;
      }
      else if (this.buyPwd != this.item.buyPwd) {
        this.$refs.cf.msgOk("예약 비밀번호가 일치하지 않습니다.", "warning");
        return;
      }

      // parent에 예약자 체크 ok 전달 - 2024.07.04
      this.$emit('ok');
    }
  },

}
</script>
