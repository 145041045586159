<template>
	<path class="st39" d="M8.9,16c0,0.6,0.1,1.2,0.2,1.8L11,16l-1.8-1.8C9,14.8,8.9,15.4,8.9,16z"/>
	<path class="st40" d="M16,23.1c-3.3,0-6-2.2-6.8-5.2l-6.7,6.7C5.3,29,10.3,32,16,32c3.1,0,6-0.9,8.5-2.5l-6.7-6.7
		C17.2,23,16.6,23.1,16,23.1z"/>
	<path class="st41" d="M32,13.8c-0.1-0.5-0.5-0.8-1-0.8H16c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5.3c-0.9,1.4-2.2,2.3-3.5,2.8
		l6.7,6.7C29,26.7,32,21.7,32,16c0-0.3,0-0.5,0-0.7C32.1,14.9,32.1,14.4,32,13.8z"/>
	<path class="st42" d="M8.9,16c0-0.6,0.1-1.2,0.2-1.8L2.5,7.5C0.9,10,0,12.9,0,16s0.9,6,2.5,8.5l6.7-6.7C9,17.2,8.9,16.6,8.9,16z"/>
	<path class="st43" d="M28.5,6c-1.1-1.4-2.5-2.6-4-3.6C22,0.9,19.1,0,16,0C10.3,0,5.3,3,2.5,7.5l6.7,6.7c0.8-3,3.6-5.2,6.8-5.2
		c0.6,0,1.2,0.1,1.8,0.3c0.9,0.3,1.7,0.8,2.6,1.5c0.3,0.3,0.7,0.3,1.1,0.1l6.7-3.3c0.3-0.1,0.5-0.4,0.5-0.7
		C28.8,6.6,28.7,6.3,28.5,6z"/>
</template>

<script>
export default {};
</script>

<style>
.st0{fill:#FFFFFF;}
.st1{fill:#3A559F;}
.st2{fill:#F4F4F4;}
.st3{fill:#FF0084;}
.st4{fill:#0063DB;}
.st5{fill:#00ACED;}
.st6{fill:#FFEC06;}
.st7{fill:#FF0000;}
.st8{fill:#25D366;}
.st9{fill:#0088FF;}
.st10{fill:#314358;}
.st11{fill:#EE6996;}
.st12{fill:#01AEF3;}
.st13{fill:#FFFEFF;}
.st14{fill:#F06A35;}
.st15{fill:#00ADEF;}
.st16{fill:#1769FF;}
.st17{fill:#1AB7EA;}
.st18{fill:#6001D1;}
.st19{fill:#E41214;}
.st20{fill:#05CE78;}
.st21{fill:#7B519C;}
.st22{fill:#FF4500;}
.st23{fill:#00F076;}
.st24{fill:#FFC900;}
.st25{fill:#00D6FF;}
.st26{fill:#FF3A44;}
.st27{fill:#FF6A36;}
.st28{fill:#0061FE;}
.st29{fill:#F7981C;}
.st30{fill:#EE1B22;}
.st31{fill:#EF3561;}
.st32{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
.st33{fill:#0097D3;}
.st34{fill:#01308A;}
.st35{fill:#019CDE;}
.st36{fill:#FFD049;}
.st37{fill:#16A05D;}
.st38{fill:#4486F4;}
.st39{fill:none;}
.st40{fill:#34A853;}
.st41{fill:#4285F4;}
.st42{fill:#FBBC05;}
.st43{fill:#EA4335;}
</style>