import { createStore } from 'vuex'
import Com from '@/lib/common'
import URL from '@/lib/url'

// 화면 표시 모드 - 2023.05.02
const DISP_MODE = {
  ROOM_MODE: 0, // 특정 일자 조회 (설정 컬럼개수 단위로 전체 스터디룸 조회)
  DATE_MODE: 1, // 특정 스터디룸 조회 (설정 컬럼개수 단위로 예약가능 전체 일자 조회)
};

// 최대 화면표시 개수 - 2023.05.02
const MAX_COLUMN = 7;

export default createStore({
  state: {
    /* 로그인 정보  */
    login: {
      isLogined: false,
      mpnum: '',
      mberNam: '',
    },

    /* 전체 지점 정보 - 2023.01.10  */
    branches: undefined,
/*      
     [
      {
        shpId: '1',
        bookLimitDay: 30,
        mblSiteId: deokso,
        shpNm: '덕소스터디라인',
        lat: '37.591225973659505',
        lng: '127.2194772453161',
        bankAcct: '농협 1038943 김길동',
        depositLimitMinute: 20,
      },
      {
        shpId: '2',
        bookLimitDay: 30,
        mblSiteId: dosim,
        shpNm: '도심스터디라인',
        lat: '37.57938111813199',
        lng: '127.22139250297214',
        bankAcct: '농협 1038943 김길동',
        depositLimitMinute: 20,
      },
      {
        shpId: '3',
        bookLimitDay: 30,
        mblSiteId: runpublic,
        shpNm: '도농스터디라인',
        lat: '37.60947201858021',
        lng: '127.16000401879646',
        bankAcct: '농협 1038943 김길동',
        depositLimitMinute: 20,
      },
    ],
*/      
    /* 선택 지점 - 2023.01.10 */
    selectedBranch: {
      shpId: 0,
      shpNm: '',
      bookLimitDay: 30,    /* 사용전 예약 가능 일수 */
    },
    /* vue page parameter info - 2023.04.05 */
    pageParam: {
      signup: {
        snsTy: null,
        snsId: null,
        name: null,
        birthYear: null,
        gender: null,
        mpnum: null,
      },
    },

    /* 스터디룸 목록 - 2023.03.13 */
    studyRoomList: [],
    strPrdtList: [],  /* 스터디룸 상품 - 2023.03.16 */

    /* 스터디룸 관련 - 2023.08.20 */
    strstatus: {
      shpId: 0, /* 조회된 지점 ID - 2023.09.12 */
      lastday: null,
      displayMode: 0,
      stNoIdx: 0,
      dayIdx: 0,
      //studyRoomList: [],
      dateList: [],   // idx, date(yyyymmdd), title(mm월 dd일), week(월)
      timeLine: [],   // 스터디룸 운영시간(30분 단위 정보) 목록 - 2023.08.20
      dispPageIdx: {datePageIdx: 0, roomPageIdx: 0},        // 날짜 및 룸의 화면 표시 인덱스 - 2023.08.20
      DISP_MODE: DISP_MODE,                     // 표시되는 화면의 유형(타이틀 기준) - 2023.08.20
      PAGE_SIZE: { // 화면표시 컬럼의 개수 - 2023.08.20
        ROOM_MODE: 7,
        DATE_MODE: 7,                       
      },
      isInitialized: false,   // 초기화 완료 여부 - 2023.08.20
      initParameter: {        // 사용자 정보에서 초기 조회를 위한 파라미터 - 2023.08.20
        stNo: undefined,      // 스터디룸 번호
        date: undefined,      // 예약일자
        isValid: false,       // 파라미터 저장여부
      },
      isGuideAlertNoted: false, // 스터디룸 선택 방법 안내 여부(최초 한번만 표시하기 위함) - 2023.08.20
    },

  },
  getters: {
    /** 로그인 정보 - 2023.04.04
     * @param {저장소} state 
     * @returns 로그인 정보
     */
    login(state) {
      return state.login;
    },
    
    /** 스터디룸 목록 조회 - 2023.03.13
     * @param {저장소} state
     * @returns 스터디룸 목록
     */
    studyRoomList(state) {
      return state.studyRoomList;
    },

    /** 스터디룸 크기별 상품 목록 - 2023.04.14
     * @param {저장소} state 
     * @returns 스터디룸 규모별 상품목록
     */
    strPrdtList: (state) => (usNmpr) => {
      if (state.strPrdtList) {
        return state.strPrdtList.filter(prdt =>
          prdt.usNmpr === usNmpr && prdt.slAt === 'Y');
      }
      else {
        return undefined;
      }
    },

    /** 선택된 지점 조회 - 2023.04.04
     * @param {저장소} state 
     * @returns 
     */
    selectedBranch(state) {
      return state.selectedBranch;
    },

    /** vue 페이지 파라미터 - 2023.04.05
     * @param {저장소} state 
     * @returns vue 페이지별 파라미터
     */
    getPageParam: (state) => (pageName) => {
      return state.pageParam[pageName];
    },

    /**
     * 스터디룸 관련 저장소 정보 조회 - 2023.08.21
     * @param {저장소} state 
     * @returns 스터디룸 관련 저장소 정보
     */
    getSTRStatusInfo: (state) => () => {
      return state.strstatus;
    },

    /**
     * 지점정보 리스트 조회 - 2023.10.19
     * @param {저장소} state 
     * @returns 지점 정보 리스트
     */
    getBranchList: (state) => () => {
      return state.branches;
    }

  },
  mutations: {
    /** 로그인 상태 설정 - 2023.04.06
     * @param {저장소} state 
     */
    setIsLogined(state) {
      state.login.isLogined = (
        state.selectedBranch.shpId && state.login.mpnum &&
        state.selectedBranch.shpId ===state.login.shpId
      );

      Com.log("store.setIsLogined", state.login.isLogined);
    },

    /** login 정보 삭제 - 2022.12.31
     */
    clearLogin(state) {
      state.login.mpnum = undefined;
      state.login.mberNam = undefined;

      Com.log("store.clearLogin", state.login);
    },

    /** 로그인 정보저장
     * @param {저장소 state} state 
     * @param {로그인정보} login 
     */
    setLogin(state, login) {
      state.login.shpId = login.shpId;
      state.login.mpnum = login.mpnum;
      state.login.mberNam = login.mberNam;

      Com.log("store.setLogin", state.login);
    },

    /** 선택지점 정보 삭제 - 2023.01.11
     * @param {저장소} state 
     */
    clearBranch(state) {
      state.selectedBranch.shpId = 0;
      state.selectedBranch.shpNm = "";
      state.selectedBranch.bookLimitDay = 0;

      Com.log("store.clearBranch", state.selectedBranch);
    },

    /** 선택지점 정보 저장 - 2023.01.11
     * @param {저장소} state 
     * @param {선택지점정보} branch 
     */
    setBranch(state, branch) {
      for (let i=0; i<state.branches.length; i++) {

        // 지점 정보 존재 확인 및 저장
        if (branch.shpId == state.branches[i].shpId) {
          state.selectedBranch.shpId = state.branches[i].shpId;
          state.selectedBranch.shpNm = state.branches[i].shpNm;
          state.selectedBranch.bookLimitDay = state.branches[i].bookLimitDay * 1;
          state.selectedBranch.canclLimitHour = state.branches[i].canclLimitHour;
          state.selectedBranch.bankAcct = state.branches[i].bankAcct;
          // 무통장 입금 대기시간(분) 추가 - 2023.09.19
          state.selectedBranch.depositLimitMinute = state.branches[i].depositLimitMinute;
          // 모바일 사이트 ID 추가 - 2024.01.08
          state.selectedBranch.mblSiteId = state.branches[i].mblSiteId;

          break;
        }
      }

      Com.log("store.setBranch", state.selectedBranch);
    },

    /** 스터디룸 목록 저장 - 2023.03.13
     * @param {저장소} state 
     * @param {스터디룸 목록} studyRoomList 
     */
    setStudyRoomList(state, studyRoomList) {
      state.studyRoomList = studyRoomList;
      if (state.studyRoomList) {
        state.studyRoomList.forEach(item => {
          item.title = item.roomNam + ' (' + item.stCnt + '인)'
        });
      }

      Com.log("store.setStudyRoomList", state.studyRoomList);
    },

    /** 스터디룸 목록 삭제 - 2023.04.12
     * @param {저장소} state 
     */
    clearStudyRoomList(state) {
      Com.log("store.clearStudyRoomList");
      state.studyRoomList = undefined;
    },

    /** 스터디룸 상품 목록 저장 - 2023.04.14
     * @param {저장소} state 
     * @param {스터디룸 상품 목록} strPrdtList 
     */
    setStrPrdtList(state, strPrdtList) {
      state.strPrdtList = strPrdtList;
      if (state.strPrdtList) {
        state.strPrdtList.forEach(item => {
          item.prdtAmtNm = Com.stringFormatter(item.prdtAmt, 'CURRENCY'); 
          item.title = item.prdtNm + ' (' + item.prdtAmtNm + '원)';
          item.usPd *= 1;

          // 상품 사용시간 계산 - 2023.09.03
          // tmTyCd는 '02'-시간, '03'-분 으로만 설정되어야 함
          item.time = (item.tmTyCd==='02'?item.usPd * 1 : item.usPd / 60);

          // 시간표시 명칭 설정
          item.usPdNm =
            (item.usPd >= 1 ? Math.floor(item.usPd) + '시간' : '') +
            (item.usPd !== Math.floor(item.usPd) ? ' 30분' : '') +
            ' (' + item.prdtAmtNm + '원)'
            ;
        });
      }

      Com.log("store.setStrPrdtList", state.strPrdtList);
    },

    /** 스터디룸 상품 목록 삭제 - 2023.04.14
     * @param {저장소} state 
     */
    clearStrPrdtList(state) {
      Com.log("store.clearStrPrdtList");
      state.strPrdtList = undefined;
    },

    /** vue 페이지 파라미터 저장 - 2023.04.05
     * @param {저장소} state 
     * @param {페이지명(소문자), param} paramInfo 
     */
    setPageParam(state, paramInfo) {
      Com.log("store.setPageParam", paramInfo);
      state.pageParam[paramInfo.targetPage] = paramInfo.param;
    },

    /** vue 페이지 파라미터 삭제 - 2023.04.05
     * @param {저장소} state 
     * @param {페이지명(소문자)} targetPage 
     */
    clearPageParam(state, targetPage) {
      Com.log("store.clearPageParam", targetPage);
      state.pageParam[targetPage] = undefined;
    },

    /** 지점목록 저장 - 2023.04.06
     * @param {저장소} state 
     * @param {지점목록} branches 
     */
    setBranchList(state, branches) {
      Com.log("store.setBranchList", branches);
      state.branches = branches;
    },

    // 조회일자 리스트 저장 - 2023.08.20
    setDateList: function (state, data) {
      state.strstatus.dateList = data;

      Com.log("store.setDateList", state.strstatus.dateList);
    },

    // 스터디룸 운영시간 설정 - 2023.08.20
    setTimeLine(state, timeLine) {
      Com.log("setTimeLine");
      state.strstatus.timeLine = timeLine;

      Com.log(state.timeLine);
    },

    // 초기화 완료 설정 - 2023.08.20
    setInitComplated(state, value) {
      state.strstatus.isInitialized = value;
    },

    // 조회일자 수정 - 2023.08.20
    setDayIdx: function (state, idx) {
      if (idx >= 0 && idx < state.strstatus.dateList.length) {
        state.strstatus.dayIdx = idx;
        // 전체 모드로 전환
        state.strstatus.displayMode = DISP_MODE.ROOM_MODE;
      }

      Com.log("setDayIdx:" + state.strstatus.dayIdx);
    },

    // 조회 스터디룸 수정 (by room idx) - 2023.08.20
    setStNoIdx: function (state, idx) {
      if (idx >= 0 && idx < state.studyRoomList.length) {
        state.strstatus.stNoIdx = idx;
        // 단일 스터디룸 조회 모드로 전환
        state.strstatus.displayMode = DISP_MODE.DATE_MODE;
      }
    },

    // 조회 스터디룸 수정 (by room no) - 2023.08.20
    setStNo: function (state, stNo) {
      //Com.log("setStNo : " + stNo);
      for (let idx = 0; idx < state.studyRoomList.length; ++idx) {
        //Com.log(idx + ":" + state.studyRoomList[idx].stNo + "===" + stNo);
        if (state.studyRoomList[idx].stNo === stNo) {
          state.strstatus.stNoIdx = idx
          // 단일 스터디룸 조회 모드로 전환
          state.strstatus.displayMode = DISP_MODE.DATE_MODE;
          //Com.log("setStNo:" + idx);
        }
      }
    },

    /* 화면표시 페이지 인덱스 저장 - 2023.08.20 */
    setDispPageIdx: function (state, dispPageIdx) {
      state.strstatus.dispPageIdx = dispPageIdx;
      Com.log("dispPageIdx");
      Com.log(dispPageIdx);
    },

    /** 스터디룸 조회 화면에서 보여줄 커럼 개수 설정 - 2023.08.20
     * - 7개를 기준으로 스터디룸 표시 화면개수 산정
     * - 화면개수별 적정한 스터디룸 개수를 위해 재계산
     */
    setPageSize(state) {
      // 일자 표시 개수 설정
      /*
      const pageCntDate = Math.floor(state.dateList.length / MAX_COLUMN) +
        (state.dateList.length % MAX_COLUMN === 0 ? 0 : 1);
      state.PAGE_SIZE.DATE_MODE = Math.floor(state.dateList.length / pageCntDate);
      */
      state.strstatus.PAGE_SIZE.DATE_MODE = 7;

      // 스터디룸 표시 개수 설정
      const pageCntRom = Math.floor(state.studyRoomList.length / MAX_COLUMN) +
        (state.studyRoomList.length % MAX_COLUMN === 0 ? 0 : 1);
      state.strstatus.PAGE_SIZE.ROOM_MODE = Math.floor(state.studyRoomList.length / pageCntRom);
    },

    /**
     * 스터디룸 정보를 조회한 지점 ID 저장 (정보 재로드 필요 여부 판단에 사용) - 2023.09.12
     * @param {저장소} state 
     * @param {스터디룸 조회한 지점 ID} shpId 
     */
    setShpId4STR(state, shpId) {
      state.strstatus.shpId = shpId;
    },

    /** 초기조회를 위한 파라미터 저장 - 2023.08.20
     * 
     * @param {저장소} state 
     * @param {초기조회용 파라미터(stNo, date)} data 
     */
    setInitParameter(state, data) {
      if (data && data.stNo) {
        state.initParameter.stNo = data.stNo;
        state.initParameter.date = data.date;
        state.initParameter.isValid = true;
      }
      else {
        state.initParameter.isValid = false;
      }
    },

    /** 초기파라미터 삭제 - 2023.08.20
     * 
     * @param {저장소} state 
     */
    clearInitParameter(state) {
      state.initParameter = {
        stNo: undefined,
        date: undefined,
        isValid: false,
      }
    },

    /** 스터디룸 선택안내 표시 완료로 설정 - 2023.08.20
     * 
     * @param {저장소} state 
     */
    setGuideAlertNoted(state) {
      state.strstatus.isGuideAlertNoted = true;
    },

  },
  actions: {
    setLogin({commit}, payload) {
      commit('setLogin', payload);
      commit('setIsLogined');
    },

    clearLogin({commit}) {
      commit('clearLogin');
      commit('setIsLogined');
    },

    async setBranch(context, payload) {
      context.commit('setBranch', payload);
      context.commit('setIsLogined');

      // 스터디룸 정보 조회 및 설정 - 2023.04.12
      if (context.getters.selectedBranch.shpId) {
        const param = {
          shpId: context.getters.selectedBranch.shpId
        };
        
/* - 2023.08.30
        // 서버 스터디룸 목록 조회
        const res = await Com.callServer(URL.PBLC.FETCH_STUDYROOM_LIST, param);

        // 조회 성공의 경우
        if (res !== null) {
          // 조회 성공의 경우
          if (res.rStatus === 0) {
            // 스터디룸 저장
            context.commit('setStudyRoomList', res.retList);
          }
          // 조회 오류의 경우
          else {
            Com.log("store.setBranch",
              "studyRoomList 조회 오류 : " + res.errorMsg, "error");
          }
        }
*/

        // 스터디룸 상품 목록 조회
        const prdtRes = await Com.callServer(URL.PBLC.FETCH_STUDYROOM_PRDT_LIST, param);

        // 조회 성공의 경우
        if (prdtRes !== null) {
          // 조회 성공의 경우
          if (prdtRes.rStatus === 0) {
            // 스터디룸 상품 저장
            context.commit('setStrPrdtList', prdtRes.retList);
          }
          // 조회 오류의 경우
          else {
            Com.log("store.setBranch",
              "studyRoom 상품 조회 오류 : " + prdtRes.errorMsg, "error");
          }
        }

      }
    },

    /** 지점 정보 삭제 - 2023.04.14
     * @param {저장소} param0 
     */
    clearBranch({commit}) {
      commit('clearBranch');
      commit('setIsLogined');
      commit('clearStudyRoomList');
      commit('clearStrPrdtList');
    },

    /** 스터디룸 목록 설정 및 기준일 설정 - 2023.08.20
     * 
     * @param {스토어} store 
     */
    async initSTRInfo (store) {
      Com.log("store.initSTRInfo");

      const param = {
        shpId: store.getters.selectedBranch.shpId
      };

      Com.log("store.initSTRInfo param", param);

      // 스터디룸 목록 및 기준일자 조회
			const data = await Com.callServer(URL.PBLC.FETCH_STUDYROOM_LIST, param);

      if (data !== null && data.rStatus === 0) {

        Com.log("strstatus.fetchStudyRoomList", data);
  
        // 1. 스터디룸 목록 설정
        if (data.retList) {
          const strList = data.retList;
          let idx = 0;

          strList.forEach(studyRoom => {
            // 화면표시 타이틀 설정
            studyRoom.title = studyRoom.roomNam + " (" + studyRoom.stCnt + "인)";
            // click시 사용될 인덱스 설정
            studyRoom.idx = idx++;
          });

          store.commit("setStudyRoomList", strList);
        }

        // 2. 예약가능일자 설정
        if (data.retObject) {

          // 2.1. 예약가능일자 설정
          if (data.retObject.today) {
            const bookLimitDay = store.getters.selectedBranch.bookLimitDay;
            const today = data.retObject.today;
            // 예약가능한 마지막 일자
            const lastDay = Com.getNDayAfterSomeDay(today, bookLimitDay, 'yyyymmdd');

            // 조회 일자 리스트 구성
            // idx, date(yyyymmdd), title(mm월 dd일), week(월)
            const buildDateList = ((bgnDay, endDay) => {
              const week = ['일', '월', '화', '수', '목', '금', '토'];
              let retDate = [];
      
              // 시작일자
              let bgnDate = new Date(bgnDay.substr(0, 4), bgnDay.substr(4, 2) * 1 - 1, bgnDay.substr(6, 2));
              // 종료일자
              let endDate = new Date(endDay.substr(0, 4), endDay.substr(4, 2) * 1 - 1, endDay.substr(6, 2));
      
              // 날짜생성
              for (let i=0, curDate=bgnDate; curDate<=endDate; curDate.setDate(curDate.getDate() + 1), i++) {
                  retDate.push({
                      idx: i,
                      date: Com.dateToStr(curDate),
                      //title: (curDate.getMonth() + 1) + "월 " + curDate.getDate() + "일",
                      title: (curDate.getMonth() + 1) + "/" + curDate.getDate(),
                      week: week[curDate.getDay()]
                  });
              }
      
              return retDate;              
            }); // end of const buildDateList = 

            // 조회 일자 리스트 구성
            // idx, date(yyyymmdd), title(mm월 dd일), week(월)
            const dateList = buildDateList(today, lastDay);

            Com.log("store.initSTRInfo dateList", dateList);

            // 조회일자리스트 설정
            store.commit("setDateList", dateList);
          } // end of if (data.retObject.today)

          // 2.2. 스터디룸 운영시간 설정
          if (data.retObject.strMngTm) {
            const strMngTm = data.retObject.strMngTm;
            const timeLine = [];

            for (let idx = 0; idx < strMngTm.length; ++idx) {

              //Com.log("timeLine: " + idx + ", " + strMngTm[idx].usAt + ", " +
              //  (timeLine.length > 0 ? timeLine[timeLine.length - 1].usAt : ''));

              // 중복된 영업외시간 삭제
              if (strMngTm[idx].usAt === 'N' &&
                timeLine.length > 0 &&
                timeLine[timeLine.length - 1].usAt === 'N') {

                continue;
              }

              // 타임라인 추가
              timeLine.push(strMngTm[idx]);
              //Com.log("timeLine added");

            } // end of for (let idx = 0; idx < strMngTm.length; ++idx)

            // 타임라인 정보 저장
            store.commit("setTimeLine", timeLine);

          } // end of if (data.retObject.strMngTm)

        } // end of if (data.retObject)

        // 3. 조회 모드 설정 (오늘일자 조회로 설정)
        store.commit("setDayIdx", 0);

        // 4. 화면 표시 컬럼 개수 설정
        store.commit("setPageSize");

        // 5. 초기화 완료 설정
        store.commit("setInitComplated", true);

			} // end of if (data !== null && data.rStatus === 0)
      else {
        Com.log("initSTRInfo", data);
        //alert("스터디룸 관련 초기화에 실패하였습니다.");
      }
    },
    
  },
  modules: {
  }
})
