<template>
  <v-container>

    <confirm-view ref="cf"/>

    <v-dialog
      v-model="dialog"
      width="auto"
      class="pa-0"
    >
      <v-card class="pa-0">
        <v-card-item class="pa-0">
          <my-room-detail v-if="selectedItem.prdtTyCd=='05'" :pytItem="selectedItem"></my-room-detail>           
          <my-locker-detail v-else-if="selectedItem.prdtTyCd=='06'" :pytItem="selectedItem"></my-locker-detail>           
          <my-seat-use-list v-else :pytItem="selectedItem"></my-seat-use-list>           
        </v-card-item>
        <v-card-actions>
          <v-btn color="primary" block @click="dialog = false">닫기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-table
      density="compact"
      fixed-header
      class="border"
      height="600px"
    >
      <thead class="title">
        <tr>
          <th class="text-center">
            상품명
          </th>
          <th class="text-center">
            결제일
          </th>
          <th class="text-center">
            상태
          </th>
          <th class="text-center">
            남은시간
          </th>
          <th class="text-center">
            유효일
          </th>
        </tr>
      </thead>
      <tbody class="text">
        <tr
          v-for="item in payList"
          :key="item.pytId"
          @click="viewDetail(item)"
        >
          <td>{{ item.prdtNm }}</td>
          <td>{{ item.PYT_DT }}</td>
          <td>{{ item.usSttusNm }}</td>
          <td>{{ item.RMN_TIM }}</td>
          <td>{{ item.VALD_PD }}</td>
        </tr>
      </tbody>
    </v-table>   
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url';
import MySeatUseList from '@/components/MySeatUseList.vue';
import MyLockerDetail from '@/components/MyLockerDetail.vue';
import MyRoomDetail from '@/components/MyRoomDetail.vue';
import ConfirmView from '@/components/ConfirmView.vue';

export default {
  components: { MySeatUseList, MyLockerDetail, MyRoomDetail, ConfirmView },

  data: () => ({

    selectedItem: null,       // 선택된 결제 내역 - 2023.03.21
    dialog: false,            // 팝엽 표시여부 - 2023.03.21
    payList: [
/*      
      {
        pytId: 3,
        pytDt: '210629185820', // 결제시간 (yymmddhhmiss) - 2023.03.17
        prdtTyCd: '03',        // 상품유형코드 01-정액권, 02-당일권, 03-자유석, 04-고정석, 05-스터디룸, 06-사물함
        prdtNm: '자유석1개월',
        usPd: 30,             // 사용기간
        payAmt: 1000,
        pytMtdCd: '02',       // 결제방법 02 - 현금, 01 - 카드, 03 - 휴대폰 - 2023.03.17
        usSttusCd: '02',      // 사용상태
        usSttusNm: '사용중',  // 사용상태명
        rmnTim: 20,           // 남은 시간
        tmTyCd: '01',         // 기간 유형 01-일, 02-시간, 03-분
        valdPd: '20210926',   // 유효기간 yyyymmdd
      },
*/      
    ],

  }),

  mounted() {
    this.getPayList();
  },

  methods: {

    /**
     * 결제내역 상세 조회 - 2023.03.21
     * @param {클릭한 결제 내역} item 
     */
    viewDetail(item) {
      if (item.usSttusCd === '01' ||
        item.usSttusCd === '02' ||
        item.usSttusCd === '03'
      ) {
        this.selectedItem = item;
        this.dialog = true;
      }
      else {
        this.$refs.cf.toast("최소된 결제입니다.", "warning");
      }
    },


    /**
     * 사용자의 1년이내 결제정보 조회 - 2023.10.28
     */
    async getPayList() {

      // 결제정보 조회
      const res = await Com.callServer(URL.MBER.GET_PAY_LIST);

      if (res !== null && res.rStatus === 0) {
        this.payList = res.retList;
      }

      this.setDispFormat();
    },


    /**
     * 결제 리스트 화면 표시 텍스트 설정 - 2023.03.21
     */
    setDispFormat() {
      this.payList.forEach(pay => {
        pay.RMN_TIM = this.formatter(pay, 'RMN-TIM');
        pay.PAY_AMT = this.formatter(pay, 'PAY-AMT');
        pay.PYT_DT = this.formatter(pay, 'PYT-DT');
        pay.VALD_PD = this.formatter(pay, 'VALD-PD');
      });
    },

    /**
     * 화면에 표시되는 형식으로 문자열 편집 - 2023.03.17
     * @param {화면 표시 데이터 행} item 
     * @param {화면 표시 데이터 필드명} field 
     */
    formatter(item, field) {

      let retVal = '';

      switch (field) {
        case 'RMN-TIM': /* 남은 기간  */
          switch(item.tmTyCd) {
            case '01':
              retVal = item.rmnTim + '일';
              break;

            case '02':
              retVal = item.rmnTim + '시간';
              break;

            case '03':
              retVal = (item.rmnTim > 60 ? Math.floor(item.rmnTim / 60)  + '시간' : '') +
                      (item.rmnTim % 60) + '분' ;
              break;

            default:
              retVal = '확인안됨';
              break;
          }

          break;

        case 'PAY-AMT': /* 결제 금액  */
          retVal = Com.stringFormatter(item.payAmt, 'CURRENCY');
          break;

        case 'PYT-DT': /* 결제 일자 (yyyymmddhhmi)  */
          retVal = Com.stringFormatter(item.crtDt, 'DAY4');
          break;

        case 'VALD-PD': /* 유효기간 (yyyymmdd)  */
          retVal = Com.stringFormatter(item.valdPd, 'DAY4');
          break;

        default:
          retVal = '잘못된 유형';
          break;
      }

      return retVal;
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 12px !important;
}
.text {
  font-size: 12px !important;
}
</style>