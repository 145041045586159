<template>
  <v-container>

      <!-- 진행중 표시 begin - 2023.04.05 -->
      <v-progress-circular
        v-if="isRunning"
        indeterminate
        color="indigo-darken-2"
        :size="100"
      ></v-progress-circular>
      <!-- 진행중 표시 end - 2023.04.05 -->

      <div v-if="!isRunning" class="d-flex justify-start ma-3">
        <v-sheet>
          {{ alert.text }}
        </v-sheet>        
      </div>
      <div class="d-flex justify-center">
        <v-btn
          v-if="alert.cancel"
          depressed
          @click="clickBtn(alert.cancel.action)"
          class="ma-2"
        >
          {{ alert.cancel.name }}
        </v-btn>         
        <v-btn
          v-if="alert.ok"
          depressed
          color="primary"
          @click="clickBtn(alert.ok.action)"
          class="ma-2"
        >
          {{ alert.ok.name }}
        </v-btn>         
      </div>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
//import URL from '@/lib/url.js';
import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  props: {
    item: {    /* 로그인 정보 - 2023.03.31 */
      type: Object,
      default: null
    },
  },

  setup() {
    const store = useStore();
    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { store, branch };
  },

  data: () => ({
    isRunning: true,  /* 로그인 진행중 여부 - 2023.04.05 */
    alert: {          /* 로그인 실패에 대한 메시지 표시 정보 - 2023.04.05 */
      text: ''
    }
  }),

  mounted() {
    this.login();
  },

  methods: {

    /**
     * 전달된 props 값을 이용하여 로그인 - 2023.04.04
     */
    async login() {
      Com.log("LoginAction.login item", this.item, "info");

      // 로그인 파라미터 구성 - 2023.04.08
      let param = this.item;
      param.shpId = this.branch.shpId;

      // 서버 로그인
      const res = await Com.login(param);

      Com.log("LoginAction.login res", res);

      if (res !== null) {
        // 로그인이 성공한 경우
        if (res.rStatus === 0) {

          // 이동할 페이지가 있는 경우 해당 페이지 이동 - 2023.04.08
          if (this.$route.query.toPage) {
            Com.log("LoginAction.login go toPage", this.$route.query.toPage);
            this.$router.replace({path: '/' + this.branch.mblSiteId + '/' + this.$route.query.toPage});
          }
          // 호출된 페이지가 있는 경우 해당 페이지 이동 - 2023.04.05
          else if (this.$route.query.fromPage) {
            Com.log("LoginAction.login go fromPage", this.$route.query.fromPage);
            this.$router.replace({path: '/' + this.branch.mblSiteId + '/' + this.$route.query.fromPage});
          }
          else {
            // 초기페이지로 이동 - 2023.04.08
            Com.log("LoginAction.login go /home");
            this.$router.replace({path: '/' + this.branch.mblSiteId + '/seatStatus'});
          }
        }
        // 로그인이 실패한 경우 - 2023.04.05
        else if (res.rStatus > 30 && res.rStatus < 40) {
          // MPNUM / PW로 로그인을 시도한 경우
          if (this.item.snsTyCd === 'SA') {
            this.showAlert(
              '휴대폰 번호 또는 비밀번호가 일치하지 않습니다.',
              {
                name: '확인',
                action: 'close'
              }
            );
          }
          // SNS로 로그인을 시도한 경우
          else {
            const snsTyDesc = this.item.snsTyCd === 'GO' ? 'Google 계정으로 ' :
                            this.item.snsTyCd === 'KA' ? 'Kakao 계정으로 ' :
                            this.item.snsTyCd === 'NA' ? 'Naver 계정으로 ' :
                            '';

            Com.log("LoginAction.login snsTyDesc", snsTyDesc);

            this.showAlert(
              '로그인정보가 존재하지 않습니다. 지금 ' + snsTyDesc + '생성하시겠습니까?',
              {
                name: '회원가입',
                action: 'signup'
              },
              {
                name: '취소',
                action: 'close'
              }
            );
          }
        }
        // 기타 오류가 발생한 경우 - 2023.04.08
        else {
          this.showAlert(
            '로그인중 오류가 발생하였습니다.(' + res.errorMsg + ')',
            {
              name: '확인',
              action: 'close'
            }
          );
        }
      }
      // 서버에서 오류가 발생한 경우
      else {
        this.showAlert(
          '로그인 중 오류가 발생했습니다.',
          {
            name: '확인',
            action: 'close'
          },
        );
      }
    },

    /** 메시지를 화면에 표시 - 2023.04.05
     * 
     * @param {알림 유형} type success info warning error 중 사용가능
     * @param {제목} title 
     * @param {내용} text 
     */
    showAlert(text, ok, cancel) {
      // 메시지 정보 저장
      this.alert.text = text;

      // ok 버튼 설정
      this.alert.ok = ok

      // cancel 버튼 설정
      this.alert.cancel = cancel;

      // 메시지 화면 표시
      this.isRunning = false;
    },

    /** 버튼 클릭 이벤트 핸들러 - 2023.04.05
     * 
     * @param {버튼 클릭 액션} btnAction 
     */
    clickBtn(btnAction) {
      Com.log('LoginAction.clickBtn', btnAction);

      switch(btnAction) {
        case 'close': // 화면 닫기
          // parent의 close 이벤트 호출
          this.$emit('close');
          break;

        case 'signup': // 회원가입 이동

          Com.log("LoginAction.clickBtn item", this.item);

          // 페이지 이동전 연동된 회원정보 전달
          this.store.commit("setPageParam", {
            targetPage: 'signup',
            param: this.item
          });

          // 호출된 페이지로 이동 - 2023.04.05
          this.$router.replace({ path: '/' + this.branch.mblSiteId + '/signUp', query: {fromPage: this.$route.query.fromPage, toPage: this.$route.query.toPage}});
          break;
      }
      
    }

  }

}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  font-size: 13px !important;
}
.cell-text {
  background-color: #ebecea;
  font-size: 13px;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
