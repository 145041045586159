import { useCookies } from "vue3-cookies";
import store from "@/store";
import URL from "@/lib/url";
import http from "./http-common";

const { cookies } = useCookies();

// 쿠키: 접근토큰 명
const COOKIE_LOGIN_ACCESS_TOKEN = 'login.accessToken';
// 쿠키: 재발행토큰 명
const COOKIE_LOGIN_REFRESH_TOKEN = 'login.refreshToken';
// 쿠키: 지점아이디
const COOKIE_BRANCH_ID = 'branch.shpId';
// 쿠키: 지점 명
const COOKIE_BRANCH_NAME = 'branch.shpNm';
// 쿠키: 모바일 사이트 ID - 2024.01.08
const COOKIE_MBL_SITE_ID = 'branch.mblSiteId';

// 로그 디버그 모드 - 2023.04.04
const IS_DEBUG = (location.host.indexOf('localhost') >= 0 ? true : false);

// 지점 정보 설정 대기 타이머 - 2023.04.12
//let waitBranchTimer = null;
//let waitBranchCallback = null;

export default {

  /**
   * 토큰정보를 저장한다.
   * @param {로그인 정보} token 
   */
  saveToken(token) {
    // accessToken 저장, 유효기간 29분
    cookies.set(COOKIE_LOGIN_ACCESS_TOKEN, token.accessToken, 60 * 29);
    // refreshToken 저장, 유효기간 30일
    cookies.set(COOKIE_LOGIN_REFRESH_TOKEN, token.refreshToken, 60 * 60 * 24 * 30);
  },

  /**
   * 로그인 정보를 cookie와 store에 저장 - 2022.12.31
   * @param {사용자 로그인정보} login 
   */
  saveTokenNLogin(login) {
    this.log("common.saveTokenNLogin");

    // token 저장
    this.saveToken(login);

    // login 정보 store 저장
    store.dispatch("setLogin", login);
  },

  /**
   * cookie와 store에서 로그인 정보를 조회 - 2022.12.31
   * 로그인 연장이 필요한 경우 로그인 연장
   * @returns 로그인 정보
   */
  readLoginFromCookie() {
    this.log("common.readLoginFromCookie");

    let login = {
      isLogined: store.getters.login.isLogined,
      mpnum: store.getters.login.mpnum,
      mberNam: store.getters.login.mberNam
    };

    // accessToken 조회
    if (cookies.get(COOKIE_LOGIN_ACCESS_TOKEN) != null) {
      login.accessToken = cookies.get(COOKIE_LOGIN_ACCESS_TOKEN);
    }

    // refreshToken 조회
    if (cookies.get(COOKIE_LOGIN_REFRESH_TOKEN) != null) {
      login.refreshToken = cookies.get(COOKIE_LOGIN_REFRESH_TOKEN);
    }

    return login;
  },

  /**
   * 로그인 정보조회 - 2023.09.06
   * @returns token refresh 후 login 정보
   */
  async getLogin() {

    // 쿠키에서 로그인 정보 조회
    let login = this.readLoginFromCookie();

    // token refresh가 필요한 경우 - 2023.09.06
    if (login.refreshToken && login.accessToken == undefined) {
      await this.tokenRefresh();

      // 로그인 정보 다시 읽기 - 2023.04.20
      login = this.readLoginFromCookie();
    }

    return login;
  },

  /**
   * 로그인 여부 조회 - 2023.09.06
   * @returns 로그인 여부
   */
  async isLogined() {
    const login = await this.getLogin();

    return login && login.isLogined;
  },

  /**
   * 모든 쿠키 삭제 - 2023.10.27
   */
  clearAllCookie() {
    cookies.keys().forEach(cookie => cookies.remove(cookie)); 
  },

  /**
   * cookie 와 store에서 로그인 정보 삭제 - 2022.12.31
   */
  logout() {
    this.log("common.logout");

    // store 에서 login 정보 삭제
    store.dispatch("clearLogin");

    // 지점 정보를 제외한 모든 쿠키 지우기 - 2023.10.27
    // 모바일 사이트 ID 제외 추가 - 2024.01.08
    cookies.keys().forEach(cookie => {
      if(cookie !== COOKIE_BRANCH_ID && cookie !== COOKIE_BRANCH_NAME && cookie !== COOKIE_MBL_SITE_ID) {
        cookies.remove(cookie);
      }
    });

/* 2023.10.27
    // accessToken 삭제
    cookies.remove(COOKIE_LOGIN_ACCESS_TOKEN);
    // refreshToken 삭제
    cookies.remove(COOKIE_LOGIN_REFRESH_TOKEN);
*/    
  },

  /**
   * 지점 정보 저장 - 2023.01.13
   * @param {지점 정보} branch 
   */
  saveBranchToCookie(branch) {
    this.log("common.saveBranchToCookie", branch);

    // branch 정보(shpId) 저장, 유효기간 10년
    cookies.set(COOKIE_BRANCH_ID, branch.shpId, 60 * 60 * 24 * 365 * 10);
    // branch 정보(shpNm) 저장, 유효기간 10년
    cookies.set(COOKIE_BRANCH_NAME, branch.shpNm, 60 * 60 * 24 * 365 * 10);
    // branch 정보(mblSiteId) 저장, 유효기간 10년 - 2024.01.08
    cookies.set(COOKIE_MBL_SITE_ID, branch.mblSiteId, 60 * 60 * 24 * 365 * 10);

    // branch 정보 store 저장
    store.dispatch("setBranch", branch);
  },

  /**
   * 쿠키에 저장된 branch 정보 조회 - 2023.01.14
   * @returns 쿠키에 저장된 branch 정보
   */
  readBranchFromCookie() {

    // COOKIE_MBL_SITE_ID 추가 - 2024.01.08
    if (cookies.get(COOKIE_BRANCH_ID) != null && cookies.get(COOKIE_BRANCH_NAME) != null && cookies.get(COOKIE_MBL_SITE_ID) != null) {
      const branch =  {
        shpId: cookies.get(COOKIE_BRANCH_ID),
        shpNm: cookies.get(COOKIE_BRANCH_NAME),
        mblSiteId: cookies.get(COOKIE_MBL_SITE_ID),
      };

      this.log("common.readBranchFromCookie", branch);

      return branch;
    }

    this.log("common.readBranchFromCookie", "branch is null");

    return null;
  },

  /**
   * 지점 정보 동기화 - 2023.01.13
   * 쿠키에 지점정보가 존재할 경우 저장소에 저장한다. 
   */
  checkBranchFromCookieNSync() {
    this.log("common.checkBranchFromCookieNSync");

    if (cookies.get(COOKIE_BRANCH_ID) != null && cookies.get(COOKIE_BRANCH_NAME) != null && cookies.get(COOKIE_MBL_SITE_ID) != null) {
      const branch = {
        shpId: cookies.get(COOKIE_BRANCH_ID),
        shpNm: cookies.get(COOKIE_BRANCH_NAME),
        mblSiteId: cookies.get(COOKIE_MBL_SITE_ID),
      };

      // branch 정보 store 저장
      store.dispatch("setBranch", branch);
    }
  },

  /**
   * 디버그 모드 로그 출력 - 2023.04.04
   * @param {메시지} msg 
   * @param {제목} title 
   * @param {분류} category info, error 
   */
  log (title, msg, category) {

    if (category === "error") {
      if (title !== undefined) {
        console.error(title, msg);
      }
      else {
        console.error(msg);
      }
    }
    else if (IS_DEBUG) {
      if (msg !== undefined) {
        console.log(title, msg);
      }
      else {
        console.log(title);
      }
    }
  },

  /* 형식 변경 - 2023.01.28 */
  stringFormatter: function (value, type) {
    let ret = '';

    if (value === undefined || value === null || type === undefined || type == null) {
        return value;
    }

    switch(type) {
        case 'DAY1': /* yyyymmdd**** -> yyyy-mm-dd  */
            ret = value.substr(0,4) + "-" + value.substr(4,2) + "-" + value.substr(6,2);
            break;

        case 'DAY2':  /* yyyymmdd**** -> yyyy년 mm월 dd일  */
            ret = value.substr(0,4) + "년 " + value.substr(4,2) + "월 " + value.substr(6,2) + "일";
            break;

        case 'DAY3': { /* yyyymmdd**** -> 토 */
            const week = ['일', '월', '화', '수', '목', '금', '토'];
            const date = new Date(value.substr(0, 4), value.substr(4, 2) * 1 - 1, value.substr(6, 2));

            ret = week[date.getDay()];
            }
            break;

        case 'DAY4':  /* yyyymmdd**** -> m/d  */
            ret = (value.substr(4,2)*1) + "/" + (value.substr(6,2)*1);
            break;

        case 'DAY5':  /* yyyymmdd**** -> m월 d일  */
            ret = (value.substr(4,2)*1) + "월 " + (value.substr(6,2)*1) + "일";
            break;

        case 'MMDDHHMI1':  /* yyyymmdd**** -> m/d hh:mi  */
            ret = (value.substr(4,2)*1) + "/" + (value.substr(6,2)*1) + " "
                + value.substr(8,2) + ":" + value.substr(10,2);
            break;

        case 'DAYTIME':  /* yyyymmddhhmi**** -> yyyy-mm-dd hh:mi */
            ret = value.substr(0,4) + "-" + value.substr(4,2) + "-" + value.substr(6,2) + " "
                + value.substr(8,2) + ":" + value.substr(10,2);
            break;

        case 'HHMI1':  /* hhmi**** -> h시m분 */
            ret = (value.substr(0,2)*1) + "시" + (value.substr(2,2)*1) + "분";
            break;

        case 'HHMI2': /* hhmi**** -> hh:mi */
            ret = value.substr(0,2) + ":" + value.substr(2,2);
            break;

        case 'AMPM-HHMI1': { /* hhmi**** -> 오후 h:mi */
            const hour = value.substr(0,2) * 1;
            //ret = (hour < 12 ? '오전 ' : '오후 ') + (hour%12) + "시" + (value.substr(2,2)) + "분";
            ret = (hour < 12 ? '오전 ' : '오후 ') + ((hour+11)%12+1) + ":" + (value.substr(2,2));
            }
            break;

        case 'DATETIME-HHMI1': /* yyyymmddhhmi**** -> h시mi분 */
            ret = (value.substr(8,2)*1) + "시" + value.substr(10,2) + "분";
            break;

        case 'DATETIME-HHMI2': /* yyyymmddhhmi**** -> h:mi */
            ret = (value.substr(8,2)*1) + ":" + value.substr(10,2);
            break;

        case 'DATETIME-DAY1': /* yyyymmdd**** -> m월d일 */
            ret = (value.substr(4,2)*1) + "월" + (value.substr(6,2)*1) + "일";
            break;

        case 'DATETIME-DAY2': /* yyyymmdd**** -> m/d */
            ret = (value.substr(4,2)*1) + "/" + (value.substr(6,2)*1);
            break;

        case 'CURRENCY': /* 9999 -> 9,999 */
            {
                let len;
                let point;
                let str;

                value = value + "";
                point = value.length % 3;
                len = value.length;

                str = value.substring(0, point);
                while (point < len) {
                    if (str != "") str += ",";
                    str += value.substring(point, point + 3);
                    point += 3;
                }
                ret = str;
            }
            break;
            
        case 'TEL': /* 01012345678 -> 010-1234-5678 */
            ret = value.substr(0,3) + "-" + value.substr(3,4) + "-" + value.substr(7);
            break;

        case 'TEL-MASK1': /* 01012345678 -> 5678 */
            ret = value.substr(7);
            break;

        case 'TEL-MASK2': /* 01012345678 -> 010-**34-**78 */
            ret = value.substr(0,3) + "-**" + value.substr(5,2) + "-**" + value.substr(9);
            break;

        case 'TEL-MASK3': /* 01012345678 -> 1234 */
            ret = value.substr(3,4);
            break;

        default:
            ret = value;
            break;
    }

    //this.log("ret:" + ret);
    return ret;
  },

  /** 문자열이 숫자로만 이루어져 있는지 여부 - 2023.04.09
   * 숫자값의 경우 : if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
   * @param {입력값} str 
   * @returns { true | false }
   */
  isOnlyNumber(str) {
    if (!/^[0-9]*$/.test(str)) {
      return false;
    }
    else {
      return true;
    }
  },

  /* 서버 호출 - 2023.04.03   */
  async callServer(url, param) {
    this.log("callServer url", url);
    this.log("callServer param", param);

    let serverParam = (param===undefined? {} : param);

    // 처리중 화면 표시
    //store.commit("process/setViewStatus", {key: "wait", value: true}, {root: true});

    // token이 없는 경우 호출
    if (!cookies.get(COOKIE_LOGIN_ACCESS_TOKEN)) {
      await this.tokenRefresh();
    }

    const token = cookies.get(COOKIE_LOGIN_ACCESS_TOKEN);
    let config = {headers: {TOKEN: token,}};

    try {
      //const response = await vuePage.axios.post(param.url, serverParam, config);
      const response = await http.post(url, serverParam, config);

      this.log("common.callServer", response);

      // http status가 정상인 경우
      if (response.status == 200) {
        const ret = response.data;

        // 프로세스별 결과 처리
        if (ret.rStatus < 200) {
          return ret;
        }
        // rStatus >= 200 경우 서버 오류에 대한 공통 처리
        else {
          this.log("callServer 서버 오류 발생(rStatus>=200)", response.errorMsg, "error");
        }
      }
      // http status가 200 이 아닌 경우
      else {
        this.log("callServer 서버 오류 발생(http status!=200)", response.message, "error");
      }
    }
    catch (err) {
      this.log("callServer 서버 오류 발생(exception)", err, "error");
    }
 
    return null;
  },


  /**
   * token refresh - 2023.04.04
   * @returns 
   */
  async tokenRefresh() {

    this.log("tokenRefresh");
    const refreshtoken = cookies.get(COOKIE_LOGIN_REFRESH_TOKEN);

    if(refreshtoken !== null && refreshtoken !== undefined) {
      const serverUrl = URL.LOGIN.USER_REFRESH_TOKEN;

      this.log("refreshtoken exist so try refresh token.");

      try {
        const response = await http.post(serverUrl, {refreshtoken: refreshtoken});
        
        this.log("Com.tokenRefresh response for " + serverUrl);
        this.log(response);

        if (response.status == 200) {
          const res = response.data;

          // refreshtoken은 retObject에 있음
          if (res.retObject != null) {

            const token = {
              accessToken: res.retObject.token,
              refreshToken: res.retObject.refreshtoken,
            };

              // token 저장 - 2023.04.04
            this.saveToken(token);
          }
          else {
            this.log("refreshToken", "httpStatus 200 but retObject null.", "error");
          }
        }
        else {
          this.log("서버 오류 발생", response.statusText, "error");
        }
      }
      catch (ex) {
        this.log("서버 오류 발생", ex.message, "error");
      }
    }
  },

  /** 사용자 로그인 - 2023.04.05
   * @param {로그인 파라미터} param 
   * @returns 서버 로그인 결과
   */
  async login(param) {
    this.log("common.login", param, "info");

    let url = '';

    // 로그인 유형에 따른 URL 구분
    if (param.snsTyCd == 'SA') {
      url = URL.LOGIN.USER_ID_LOGIN;
    }
    else {
      url = URL.LOGIN.USER_SNS_LOGIN;
    }

    // 서버 로그인
    const res =  await this.callServer(url, param);

    if (res !== null) {

      // 로그인이 성공한 경우
      if (res.rStatus === 0) {
        // 로그인 정보 구성
        const loginInfo = {
          accessToken: res.retObject.token,
          refreshToken: res.retObject.refreshtoken,
          shpId: res.retObject.shpId,
          mpnum: res.retObject.mpnum,
          mberNam: res.retObject.mberNam,
        };

        // 쿠키 및 저장소 저장
        this.saveTokenNLogin(loginInfo);
      }
    }

    return res;
  },


  /**
   * token을 이용한 로그인 정보 조회 - 2023.10.18
   * @returns true(login success), false(login fail)
   */
  async setLoginInfoFromToken(shpId) {
    this.log("Com.setLoginInfoFromToken",  "shpId : " + shpId);

    // 서버 로그인 정보 조회
    const res =  await this.callServer(URL.MBER.GET_LOGIN_INFO);

    if (res !== null) {

      // 로그인 정보조회 결과와 이전의 지점 정보와 일치하는 경우
      if (res.rStatus === 0 && shpId === res.retObject.shpId) {
        // 로그인 정보 구성
        const login = {
          shpId: res.retObject.shpId,
          mpnum: res.retObject.mpnum,
          mberNam: res.retObject.mberNam,
        };

        // login 정보 store 저장
        store.dispatch("setLogin", login);

        return true;
      }
    }

    return false;
  },


  /** 지점정보 설정 대기(0.5초 단위) 후 콜백 함수 호출 - 2023.04.12
   * @param {지점정보 확인후 호출될 콜백 함수} func 
   */
  waitBranchEvtNExeFunc(func) {
    // 대기시간 50초
    let tryCnt = 100;

    // 타이머 생성
    let waitBranchTimer = setInterval(() => {
      if (store.getters.selectedBranch && store.getters.selectedBranch.shpId > 0) {
        clearInterval(waitBranchTimer);
        func();
        waitBranchTimer = null;
      }

      // 대기시간 초과의 경우
      if (!tryCnt--) {
        clearInterval(waitBranchTimer);
        waitBranchTimer = null;
      }
    }, 500);    
  },


  /** 지점정보 설정 대기(0.5초 단위) 후 콜백 함수 호출 - 2023.04.12
   * @param {지점정보 확인후 호출될 콜백 함수} func 
   */
  waitSTRListEvtNExeFunc(func) {
    // 대기시간 50초
    let tryCnt = 100;

    // 타이머 생성
    let waitTimer = setInterval(() => {
      if (store.getters.studyRoomList.length) {
        clearInterval(waitTimer);
        func();
        waitTimer = null;
      }

      // 대기시간 초과의 경우
      if (!tryCnt--) {
        clearInterval(waitTimer);
        waitTimer = null;
      }
    }, 500);    
  },

  /** 숫자앞에 자리수 만큼 '0'을 붙임 - 2023.04.12
   *  - leadingZero(3, 2) -> "03"
   * @param {변환 숫자} n 
   * @param {자리수} digits 
   * @returns 
   */
  leadingZeros(n, digits) {
    if (n === undefined || n === null) {
      return n;
    }

    var zero = '';
    n = n.toString();

    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
      zero += '0';
    }

    return zero + n;
  },

  /** undefined & null 체크 - 2023.04.12
   * @param { null 확인용 변수 } value 
   * @returns 
   */
  isNoU(value) {
    return value === undefined || value === null;
  },

  /** 날짜 형식화 - 2023.04.12
   * @param { 추출대상 date 타입 변수 } date 
   * @param { yyyymmdd 형태의 문자열 포멧 } format 
   * @returns 날짜에 대해 형식화된 문자열
   */
  getFormatDate(date, format) {
    this.log("common.getFormaDate", 'date: ' + date + ", format: " + format);

    const someDate = new Date(date);

    if (!this.isNoU(format)) {
      const week = ['일', '월', '화', '수', '목', '금', '토'];      
      const dayOfWeek = '' + week[someDate.getDay()];
      const day = '' + someDate.getDate();
      const month = '' + (someDate.getMonth() + 1);
      const fullYear = '' + someDate.getFullYear();
      let formatted = format;

      formatted = formatted.replace(/yyyy/g, fullYear);
      formatted = formatted.replace(/yy/g, fullYear.substring(2, 4));
      formatted = formatted.replace(/mm/g, this.leadingZeros(month, 2));
      formatted = formatted.replace(/m/g, month);
      formatted = formatted.replace(/dd/g, this.leadingZeros(day, 2));
      formatted = formatted.replace(/d/g, day);
      formatted = formatted.replace(/w/g, dayOfWeek);

      this.log("common.getFormaDate", formatted);

      return formatted;
    }
    else {
      return someDate;
    }
  },

  /** 오늘일자(yyyymmdd) 가져오기 - 2023.04.12
   * @param { date format yyyymmdd } format 
   * @returns 오늘날짜에 대해 형식화된 문자열 
   */
  getToday(format) {

    if (this.isNoU(format)) {
      return this.getFormatDate(new Date(), 'yyyymmdd');
    }
    else {
      return this.getFormatDate(new Date(), format);
    }
  },

  /** date 로부터 nDay 이후의 날짜값 계산 - 2023.04.15
   * 
   * @param {기준날짜} date 
   * @param {일수} nDay 
   */
  getDateAfterNday(date, nDay) {
    const retDate = new Date(date);
    retDate.setDate(date.getDate() + (nDay * 1));

    this.log("common.getDateAfterNday",
      "date: " + date + ", nDay: " + nDay + ", retDate: " + retDate);
    return retDate;
  },

  /* 포멧에 맞춰 특정일부터 특정일수 이후의 날짜 구하기 - 2023.08.30 */
  getNDayAfterSomeDay: function(aDay, nDay, format) {
    const numOfDay = parseInt(nDay);
    let someDay = new Date(this.stringFormatter(aDay, "DAY1"));
    let aFormat = format;

    // 기본 형식 지정
    if (this.isNoU(aFormat)) {
      aFormat = 'yyyymmdd';
    }

    this.log("getNDayAfterSomeDay:(" + someDay + "," + nDay + ", " +  aFormat + ")");


    // 날짜수 체크
    if (!this.isNoU(numOfDay)) {
      return this.getFormatDate(someDay.setDate(someDay.getDate() + numOfDay), aFormat);
    }
    else {
      return this.getFormatDate(new Date(), aFormat);
    }
  },

  /* 날짜타입을 yyyymmdd 형식의 문자열로 변경 - 2023.08.30 */
  dateToStr: function (date) {

    const sYear = String(date.getFullYear());
    const iMonth = date.getMonth() + 1;
    const iDate = date.getDate();

    const sMonth = iMonth > 9 ? String(iMonth) : "0" + iMonth;
    const sDate  = iDate > 9 ? String(iDate) : "0" + iDate;

    return sYear + sMonth + sDate;
  },

}