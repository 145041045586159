<template>
  <v-container
      class="ma-auto"
      fluid
  >
    <v-card
        class="elevation-12 ma-auto"
        outlined
        max-width="700"
    >

      <v-card-title
          class="mt-5 font-italic text-h4"
      >Study-Line
      </v-card-title>

      <v-card-text
      >
        <v-row
            align="center"
            class="mt-5"
        >
          <v-col
          >
            <v-text-field
                prepend-icon="mdi-account"
                label="이름"
                counter="5"
                required
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row
            align="center"
            class="mt-5"
        >
          <v-col>
            <v-text-field
                v-model="phNumber"
                @keyup="getMask(phNumber)"
                prefix="010 - "
                prepend-icon="mdi-phone"
                label="- 를 빼고 입력하세요"
                counter="12"
            >
            </v-text-field>
          </v-col>
        </v-row>


        <v-row
            class="mb-2"
            justify="start"
        >
          <v-btn
              class="bg-deep-purple ml-13"
          >
            인증번호 발송
          </v-btn>
        </v-row>

        <v-alert
            class="mt-5 ml-10 justify-md-start"
            color="#BBD"
        >
          휴대폰번호와 이름을 입력 후 인증번호 발송 버튼을 눌러 주세요.
        </v-alert>

        <v-row
            class="my-auto"
            justify="end"
        >
          <v-btn
              class="text-blue-darken-1"
              elevation="0"
              plain
          >
            로그인 페이지
          </v-btn>
        </v-row>
        <v-row
            class="my-auto"
            justify="end"
        >
          <v-btn
              class="text-blue-darken-1"
              elevation="0"
              plain
          >
            아이디/비밀번호 찾기
          </v-btn>
        </v-row>
      </v-card-text>


      <v-card-actions
          class="ma-4"
          style="background-color: #BBD"
      >
        <v-btn
            color="blue lighten-4"
            block
        >
          Sign Up
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    phNumber: '',
    name: ''
  })
  ,
  methods: {
    getMask(val) {
      let res = this.getPhoneNumberMask(val)
      this.phNumber = res
    },
    getPhoneNumberMask(phoneNumber) {
      if (!phoneNumber) return phoneNumber

      phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
      let res = ''
      if (phoneNumber.length <= 4) {
        res = phoneNumber
      }
      else {
        res = phoneNumber.substr(0, 4) + ' - ' + phoneNumber.substr(4,4)
      }

      return res
    }
  }
}
</script>
