<template>
  <v-container>

    <v-card class="mt-4 mb-3 text-center">
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          {{ pytItem.prdtNm }}
        </v-col>
      </v-row>
      <v-row class="border my-0">
        <v-col class="py-1 cell-title">
          결제일자
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.PYT_DT }}
        </v-col>
        <v-col class="py-1 cell-title">
          상태
        </v-col>
        <v-col class="py-1 cell-text">
          {{ pytItem.usSttusNm }}
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-container fluid>
        <v-table
          fixed-header
          height="150px"
          density="compact"
          class="px-0 mx-0"
        >
          <thead>
            <tr>
              <th class="text-center px-1">
                스터디룸
              </th>
              <th class="text-center px-1">
                시작시간
              </th>
              <th class="text-center px-1">
                종료시간
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item, idx in strHist"
              :key="idx"
            >
              <td class="text-center px-1">{{ item.roomNam }}</td>
              <td class="text-center px-1">{{ item.BGN_TIM }}</td>
              <td class="text-center px-1">{{ item.END_TIM }}</td>
            </tr>
          </tbody>
        </v-table>   
      </v-container>
    </v-card>
 
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url';

export default {
  props: {
    pytItem: {    /* 화면 표시용 결제 내역 - 2023.03.18 */
      type: Object,
      default: null
    },
  },

  data: () => ({
    strHist: [
/*      
      {
        stNo: 127,
        roomNam: '룸2',     // 스터디룸 이름
        bgnTim: '20210626220742',  // 시작시간 yyyymmddhhmi
        endTim: '20210627000742',  // 종료시간 yyyymmddhhmi
      },
*/      
    ],

  }),

  mounted() {
    this.getStrUseDetail();
  },

  methods: {

    /**
     * 사용자의 스터디룸 이용내역 상세 조회 - 2023.10.28
     */
    async getStrUseDetail() {

      const param = { pytId: this.pytItem.pytId };

      // 스터디룸 이용내역 조회
      const res = await Com.callServer(URL.MBER.GET_STR_USE_DETAIL, param);

      if (res !== null && res.rStatus === 0) {
        this.strHist = res.retList;
      }

      this.setDispFormat();
    },


    /**
     * 결제 리스트 화면 표시 텍스트 설정 - 2023.03.21
     */
     setDispFormat() {
      this.strHist.forEach(item => {
        item.BGN_TIM = this.formatter(item, 'BGN-TIM');
        item.END_TIM = this.formatter(item, 'END-TIM');
      });
    },

    /**
     * 화면에 표시되는 형식으로 문자열 편집 - 2023.03.17
     * @param {화면 표시 데이터 행} item 
     * @param {화면 표시 데이터 필드명} field 
     */
     formatter(item, field) {

      let retVal = '';

      switch (field) {
        case 'BGN-TIM': /* 시작시간  */
          retVal = Com.stringFormatter(item.bgnTim, 'MMDDHHMI1');
          break;

        case 'END-TIM': /* 종료시간  */
          retVal = Com.stringFormatter(item.endTim, 'DATETIME-HHMI2');
          break;

        default:
          retVal = '잘못된 유형';
          break;
      }

      return retVal;
      },

  }

}
</script>

<style scoped>
.cell-title {
  background-color: #ced1ce;
  font-size: 13px !important;
}
.cell-text {
  background-color: #ebecea;
  font-size: 13px;
}
</style>
