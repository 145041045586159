<template>
  <v-container class="ma-auto">

    <confirm-view ref="cf"/>

    <!-- 로그인 확인 팝업 begin - 2023.04.04 -->
    <v-dialog
      v-model="loginDialog"
      :persistent="true"
      width="auto"
      class="pa-0"
    >
      <v-card class="pa-0">
        <v-card-item class="pa-0">
          <login-action
            :item="loginInfo"
            @close="loginDialog = false"
          ></login-action>           
        </v-card-item>
      </v-card>
    </v-dialog>
    <!-- 로그인 확인 팝업 end - 2023.04.04 -->

    <v-card
      class="mx-auto px-12 pt-3 pb-0"
      elevation="8"
      max-width="350"
      rounded="lg"
    >
      <v-card-title class="mt-0 text-h5">
        로그인
      </v-card-title>

      <div class="text-subtitle-1 text-medium-emphasis mt-0 mb-0">소셜계정으로 간편 로그인</div>
<!--
      <v-btn class="mr-6 my-2" fab small icon>
        <v-icon size="48px">$kakaoIcon</v-icon>
      </v-btn>
-->
      <div class="d-flex justify-center mt-2" id="kakao-login-btn" @click="loginWithKakao()">
        <img src="https://k.kakaocdn.net/14/dn/btroDszwNrM/I6efHub1SN5KCJqLm1Ovx1/o.jpg" width="207" alt="카카오 로그인 버튼" />
      </div>

<!--
      <v-btn class="mr-6 my-2" color="green darken-1" fab small icon @click="googleLogin()">
        <v-icon>mdi-alpha-n-box</v-icon>
      </v-btn>
-->
 
      <!--<div id="naver_id_login"></div>-->
      <div class="d-flex justify-center mt-3" id="naverIdLogin">
        <img src="https://static.nid.naver.com/oauth/big_g.PNG" width="200">
      </div>

      <div class="d-flex justify-center mt-1">
<!--
        <GoogleLogin :callback="callback">
        <v-btn class="mr-6 my-2" small icon>
          <v-icon>mdi-google</v-icon>
          <icon-base
            viewBox="-8 -8 48 48"
            width="48"
            height="48"
            icon-color="rgba(255, 0, 0, 0.80)"
            icon-name="pin">
            <google-icon />
          </icon-base>
        </v-btn>
        </GoogleLogin>
-->          
        <GoogleLogin :callback="googleCallback"></GoogleLogin>
      </div>

      <div class="text-subtitle-1 text-medium-emphasis my-3">또는</div>

      <div class="text-subtitle-1 text-medium-emphasis d-flex">계정 ('-' 제외 휴대폰번호)</div>
      <v-text-field
        v-model="mpnum"
        density="compact"
        placeholder="'-'제외 번호만 입력"
        prepend-inner-icon="mdi-phone-outline"
        variant="outlined"
        @keypress="onlyNumber(event)"
      ></v-text-field>
  
      <!--<div class="text-subtitle-1 text-medium-emphasis d-flex">비밀번호</div>-->
  
      <v-text-field
        :append-inner-icon="pwdVisible ? 'mdi-eye-off' : 'mdi-eye'"
        :type="pwdVisible ? 'text' : 'password'"
        v-model="pwd"
        density="compact"
        placeholder="비밀번호 입력"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        @click:append-inner="pwdVisible = !pwdVisible"
      ></v-text-field>
  
      <div class="my-auto d-flex justify-end" justify="end">
        <v-btn
          class="text-blue-darken-1 mt-0 pt-0"
          elevation="0"
          plain
          :to="'/' + branch.mblSiteId + '/signUp'"
          density="compact"
        >
          회원가입
        </v-btn>
        <v-btn
          class="text-blue-darken-1 mt-0 pt-0"
          elevation="0"
          plain
          @click="findPassword()"
          density="compact"
        >
          비밀번호 찾기
        </v-btn>
      </div>

      <v-btn
        block
        class="mb-7"
        color="blue"
        size="large"
        variant="tonal"
        @click="login()"
      >
        로그인
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import { decodeCredential } from 'vue3-google-login';
//import { googleSdkLoaded } from 'vue3-google-login';
import KakaoLib from '@/plugins/kakao.js';
import NaverLib from '@/plugins/naver.js';
//import store from "@/store";
import LoginAction from '@/components/LoginAction.vue';
import ConfirmView from '@/components/ConfirmView.vue';
import { computed } from "vue";
import { useStore } from 'vuex';



export default {
  components: { LoginAction, ConfirmView },

  setup() {
    const store = useStore();
    // 지점 정보
    const branch = computed(() => {
      return store.getters.selectedBranch;
    });

    const callback = (response) => {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      Com.log("handle the response", response);

      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential);
      Com.log("Handle the userData", userData);
    };

    // 페이지 파라미터 정보 조회 - 2023.04.06
    const param = computed(() => store.getters.getPageParam("loginview"));

    return { store, branch, callback, param };
  },

  data: () => ({
    mpnum: undefined,     /* 직접 로그인 휴대폰번호 - 2023.04.08 */
    pwd: undefined,       /* 직접 로그인 비밀번호 - 2023.04.08 */
    pwdVisible: false,    /* 비밀번호 표시 여부 - 2023.03.24 */
    loginInfo: {},        /* 로그인 정보 - 2023.04.04 */
    loginDialog: false,   /* 로그인 팝업 표시 여부 - 2023.04.04 */
    fromPage: undefined,  /* 요청 페이지 정보 - 2023.04.06 */
    toPage: undefined,    /* 이동중 로그인이 호출된 페이지 정보 - 2023.04.06 */
  }),

  mounted() {

    // 네이버 초기화 - 2023.03.29
    this.naverInit();

    Com.log("LoginView redirectUri", KakaoLib.getRedirectUri());
  },
  
  methods: {

    /** 구글 버튼 초기화 - 2023.03.29
     * 
     */
/*
     googleLogin() {
      googleSdkLoaded((google) => {
        google.accounts.oauth2.initCodeClient({
          client_id: '556589072308-hqki4ei457fr5j6r0fcu3h3evrskohdu.apps.googleusercontent.com',
          scope: 'email profile openid',
          callback: (response) => {
            Com.log('Handle the response', response);
          }
        }).requestCode()
      })
    },
*/

    /**
     * 구굴 로그인 처리 콜백 - 2023.03.29
     * @param {구글 로그인 처리 결과} response 
     */
    googleCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      //Com.log("handle the response", response);

      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential);
      Com.log("Handle the userData", userData);

      // 정상적으로 decode 된 경우
      if (userData) {
        //Com.log("google id", userData.sub);
        //Com.log("google name", userData.name);

        // 로그인 호출
        this.showDialog({
          //shpId: this.branch.shpId, // LoginAction에서 처리 - 2023.04.08
          snsTyCd: 'GO',
          snsId: userData.sub,
          mberNam: userData.name,
        });

      }
      else {
        Com.error("google user data not valid", "error");
      }
    },

    /** 숫자입력 이벤트 핸들러 - 2023.04.09
     * @param {keypress 이벤트} event 
     */
     onlyNumber(event) {
      event = (event) ? event : window.event;
      const expect = event.target.value.toString() + event.key.toString();

      if (Com.isOnlyNumber(expect)) {
        return true;
      }
      else {
        // 입력 차단
        event.preventDefault();

        //Com.log("SignUpUsrInfo.onlyNumber expect", expect);
        // 최초 입력시에만 입력 알림 - 2023.04.10
        if (expect.length === 1) {
          //alert("숫자만 입력 가능합니다.");
          this.$refs.cf.msgOk("숫자만 입력 가능합니다.", "warning");
        }
      }
    },

    /** 패스워드 찾기 안내 - 2024.01.06 */
    findPassword() {
      this.$refs.cf.msgOk("신규회원으로 가입하시면 기존의 회원정보를 갱신하며 패스워드를 초기화할 수 있습니다.", "warning");
    },

    /**
     * 로그인 - 2023.04.08
     */
    login() {
      // 휴대폰 번호 체크
      if (this.mpnum) {
        this.mpnum = this.mpnum.replace(/-/gi, '').trim();
        if (this.mpnum.length !== 11) {
          //alert("휴대폰번호가 유효하지 않습니다. 휴대폰번호를 확인해주세요.");
          this.$refs.cf.msgOk("휴대폰번호가 유효하지 않습니다. 휴대폰번호를 확인해주세요.", "warning");
          return;
        }
      }
      else {
        //alert("휴대폰번호를 입력해주세요.");
        this.$refs.cf.msgOk("휴대폰번호를 입력해주세요.", "warning");
        return;
      }
      // 비밀번호 체크
      if (this.pwd) {
        this.pwd = this.pwd.trim();
        if (this.pwd.length < 6) {
          //alert("비밀번호는 6자 이상입니다. 비밀번호를 확인해주세요.");
          this.$refs.cf.msgOk("비밀번호는 6자 이상입니다. 비밀번호를 확인해주세요.", "warning");
          return;
        }
      }
      else {
        //alert("비밀번호를 입력해주세요.");
        this.$refs.cf.msgOk("비밀번호를 입력해주세요.", "warning");
        return;
      }

      // 로그인 호출
      this.showDialog({
        snsTyCd: 'SA',
        mpnum: this.mpnum,
        pwd: this.pwd,
      });

    },

    /**
     * 로그인 취소 - 2023.01.01
     */
    cancel() {
        // 호출한 페이지로 이동
        this.$router.replace({path: '/' + this.branch.mblSiteId + '/' + this.$route.query.fromPage});
    },

    /**
     * 카카오 로그인 - 2023.03.28
     */
    loginWithKakao() {

      // 카카오 로그인 호출 - 2023.04.09
      KakaoLib.loginWithKakao(this.showDialog);
    },

    /**
     * 네이버 로그인 정보 조회를 위한 초기화 - 2023.03.29
     */
    naverInit() {
      Com.log('LoginView.naverInit callbackUrl', NaverLib.loginCallback());
      // 연동결과 조회를 위한 네이버 초기화
      NaverLib.initNaver({
        loginButton: {color: "green", type: 3, height: 45},
        isPopup: true,
        callbackUrl: NaverLib.loginCallback(),
      });

      // 네이버 연동 결과 조회
      //NaverLib.getLoginStatus(this.naverCallback);
      NaverLib.getLoginStatus(NaverLib.readProfile, this.showDialog);
    },

    /** 로그인 팝업 표시 - 2023.04.09
     * @param {로그인 정보} loginInfo 
     */
    showDialog(loginInfo) {
      // 지점 정보 설정
      // LoginAction에서 처리 - 2023.04.08
      //loginInfo.shpId = this.branch.shpId;

      // 정보 저장
      this.loginInfo = loginInfo;

      Com.log('LoginView.showDialog', loginInfo);

      // 로그인 다이얼로그 표시
      this.loginDialog = true;
    }
  },
}
</script>
