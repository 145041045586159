<template>

  <td
    :class="[variantClass, isSelected? 'selected' : '']"
    class="text-center align-middle p-0 m-0 cellcommon"
    :rowspan="rowSpan"
    @click="onCellSelect"
  >
    <confirm-view ref="cf"/>

    <v-row v-if="item" align-h="center" class="p-0 m-0">
      <v-col cols="12" class="p-0 m-0">
        <div
          style="font-size: 11px;"
        >
          <!--<span>{{header.roomNam}} ({{day}})</span><br/>-->
          <!--<span>{{term}}</span><br/>-->
          <span>{{reserveStatus}} : {{bookText}}</span>
        </div>
      </v-col>
    </v-row>
  </td>

</template>

<script>
import Com from '@/lib/common';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

// 현재 셀의 유형
const TYPE = {
  NEW_RESERVED: 1,
  NEW_AVALABLE: 2,
  CHANGE_RESERVED: 3,
  CHANGE_AVALABLE: 4,
  CHANGE_ROOM_UNAVAILABLE: 5,
  CHANGE_TIME_UNAVAILABLE: 6,
  UNUSED: 7,
};

export default {
  name: 'TableCell',

  components: { ConfirmView },

  props: {
    header: Object,
    item: Object,
    changeReserve: Object,
    bookInfo: Object,
    row: Number,
    col: Number,
    curTime: String,
  },

  setup() {
    const store = useStore();

    // 로그인 정보
    const login = computed(() => store.getters.login);

    // 스터디룸 관련 저장소 정보 조회 - 2023.08.30
    const strstatus = computed(() => store.getters.getSTRStatusInfo());

    return { store, login, strstatus };
  },

  data() {
    return {
      type: TYPE.UNUSED,
      isSelected: false, /* 2023.06.06 - cell 이 선택되었는지 여부 */
    }
  },

  mounted() {

    // 현재 시간 이전인 경우 - 2023.05.07
    if (this.curTime && this.curTime > this.header.date + this.timeLine[this.row].mngTmCd) {
      this.type = TYPE.UNUSED;
    }
    else {
      // 예약이 있는 경우
      if (this.item) {
        this.type = this.changeReserve ? TYPE.CHANGE_RESERVED : TYPE.NEW_RESERVED;
      }
      // 예약이 없는 경우
      else {
        // 예약 변경의 경우
        if (this.changeReserve) {
          // 스터디룸 가격(인원기준)이 동일한 경우 - 2023.05.09
          if (this.changeReserve.header.usNmpr === this.header.usNmpr) {

            // 예약가능 슬롯이 존재하는 경우 - 2023.05.09
            if (this.changeReserve.item.timeSlotCnt <= this.availableTimeSlotCnt) {
              this.type = TYPE.CHANGE_AVALABLE;
            }
            // 예약가능 슬롯이 존재하지 않는 경우 - 2023.05.09
            else {
              this.type = TYPE.CHANGE_TIME_UNAVAILABLE;
            }
          }

          // 스터디룸 가격(인원기준)이 다른 경우 - 2023.05.09
          else {
            this.type = TYPE.CHANGE_ROOM_UNAVAILABLE;
          }

        }

        // 신규 예약의 경우  
        else {
          this.type = TYPE.NEW_AVALABLE;
        }
      }
    }

  },

  computed: {

    // 스터디룸 운영시간 - 2023.05.02
    timeLine() {
      return this.strstatus.timeLine;
    },

    /** 셀(TD) 유형 - 2023.04.26
     * - 1: 예약시작 시간, 2: 연결예약 시간, 3. 예약 없음
     */
    variantClass() {
      switch (this.type) {
        case TYPE.CHANGE_RESERVED:
        case TYPE.CHANGE_ROOM_UNAVAILABLE:
        case TYPE.CHANGE_TIME_UNAVAILABLE:
          return this.item === this.changeReserve.item ? "changeCurrent" : "reserved";

        case TYPE.CHANGE_AVALABLE:
          //return (this.row + this.col) % 2 === 1 ? "changeAvailable1" : "changeAvailable2";
          // 2023.05.29 - 색상변경
          return this.timeLine[this.row].mngTmCd.substring(2, 4) === '00' ? "changeAvailable1" : "changeAvailable2";

        case TYPE.NEW_RESERVED:
          return this.isMyBook ? "mine" : "reserved";

        case TYPE.NEW_AVALABLE:
          //return (this.row + this.col) % 2 === 1 ? "newAvailable1" : "newAvailable2";
          // 2023.05.29 - 색상변경
          return this.timeLine[this.row].mngTmCd.substring(2, 4) === '00' ? "newAvailable1" : "newAvailable2";

        case TYPE.UNUSED:
          return "unused";

        default:
          return undefined;
      }
    },

    isMyBook: {
      get: function() {
        if (this.login &&
          this.login.isLogined &&
          this.login.mpnum !== undefined &&
          this.item &&
          this.login.mpnum === this.item.mpnum) {

          return true;
        }
        else {
          return false;
        }
      }
    },

    /* 관리자 취소 가능 여부 - 2021.07.10 by joung9 */
    // 사용자가 신용카드로 결제한 경우 관리자가 취소할 경우 카드 환불을 수동으로 진행해야 함
    bookText: {
      get: function() {
        return this.isMyBook ? Com.stringFormatter(this.item.mpnum, "TEL") : Com.stringFormatter(this.item.mpnum, "TEL-MASK1");
      }
    },

    term: {
      get: function() {
        return Com.stringFormatter(this.item.bgnTim, 'DATETIME-HHMI2') +
          " ~ " + 
          Com.stringFormatter(this.item.endTim, 'DATETIME-HHMI2');
      }
    },

    day: {
      get: function() {
        //return Com.stringFormatter(this.item.usDe, 'DAY5');
        // 2023.06.06 - 24시 이후의 예약도 표시하게되어 예약 시작시간으로 변경
        return Com.stringFormatter(this.item.bgnTim, 'DAY5');
      }
    },

    /** 예약 가능한 타임 슬롯(30분) 개수 - 2023.05.07 */
    availableTimeSlotCnt() {
      let timeSlotCnt = 1;

      // 예약가능한 timeSlot(30분 단위) 개수 계산
      for (let idx = this.row + 1; idx < this.timeLine.length; ++idx) {
        //Com.log("cell.onCellSelect", this.timeLine[idx]);

        // 운영시간이 아니거나 예약이 있는 경우
        if (this.timeLine[idx].usAt === 'N' ||
          (this.bookInfo && this.bookInfo[this.timeLine[idx].mngTmCd])) {
          
          break;
        }

        timeSlotCnt++;
      }

      return timeSlotCnt;
    },

    rowSpan() {
      let rowCnt = 1;

      // 예약이 있는 경우
      if (this.item) {

        // 예약 변경 모드인 경우
        if (this.changeReserve) {
                    
          // 변경 예약이 아닌 경우에만 rowspan을 적용 - 2023.05.10
          // 변경 예약인 경우 다른 타임슬롯을 선택할수 있도록 rowspan을 1로 조정
          if (this.item !== this.changeReserve.item) {
            rowCnt = this.item.timeSlotCnt;
          }
        }
        else {
          rowCnt = this.item.timeSlotCnt;
        }
      }

      return rowCnt;
    },

    /** 예약 상태 - 2023.09.09 */
    reserveStatus() {
      return this.item.bookSttusCd === '02' ? '예약' : '대기' ;
    },
  },

  methods: {

    onCellSelect() {
      Com.log("cellClick", this.item);
      
      switch (this.type) {
        case TYPE.NEW_AVALABLE: { // 신규 예약의 경우

          // 선택셀 표시 - 2023.06.06
          this.isSelected = true;

          // 예약 팝업 호출(parent) - 2023.05.03
          this.$emit("newReserve", {
            header: this.header,
            strMngTm: this.timeLine[this.row],
            timeSlotCnt: this.availableTimeSlotCnt,
          });

          break;
        }

        case TYPE.CHANGE_AVALABLE: // 예약 변경 유효 시간 선택의 경우 - 2023.05.07

          // 선택셀 표시 - 2023.06.06
          this.isSelected = true;

          this.$emit("changeSelect", {
            header: this.header,
            strMngTm: this.timeLine[this.row],
          });
          break;

        case TYPE.NEW_RESERVED: // 예약 조회의 경우

          Com.log("TableCell.onCellSelect", this.item);

          // 비로그인 예약에 대한 처리 - 2024.07.05
          if (!this.login.isLogined &&
            this.item.buyPwd &&
            this.item.buyPwd.length >= 1) {

            // 선택셀 표시 - 2024.07.05
            this.isSelected = true;
        
            this.$emit("checkBuyPwd", {
              header: this.header,
              strMngTm: this.timeLine[this.row],
              item: this.item,
              term: this.term
            });

          }
          // 나의 예약인 경우에만 조회 가능 - 2023.05.09
          else if (this.isMyBook) {

            // 선택셀 표시 - 2023.06.06
            this.isSelected = true;
        
            this.$emit("viewReserve", {
              header: this.header,
              strMngTm: this.timeLine[this.row],
              item: this.item,
              term: this.term
            });

          }
          else {
            // 다른 사용자 예약 내용 안내 메시지 표시
            this.$refs.cf.toast("다른 사용자의 예약입니다.", "warning");
          }

          break;

        case TYPE.CHANGE_RESERVED:
          //Com.msgBoxOk(this, "선택확인", "이미 예약이 있어 선택할 수 없습니다.", "danger");
          this.$refs.cf.toast("이미 예약이 있어 선택할 수 없습니다.", "warning");
          break;

        case TYPE.CHANGE_TIME_UNAVAILABLE:
          //Com.msgBoxOk(this, "선택확인", "예약은 없지만 유휴시간이 예약시간 보다 작아 선택할 수 없습니다.", "danger");
          this.$refs.cf.toast("예약은 없지만 유휴시간이 예약시간 보다 작아 선택할 수 없습니다.", "warning");
          break;

        case TYPE.CHANGE_ROOM_UNAVAILABLE:
          //Com.msgBoxOk(this, "선택확인", "스터디룸의 이용 가격이 달라 선택할 수 없습니다.", "danger");
          this.$refs.cf.toast("스터디룸의 이용 가격이 달라 선택할 수 없습니다.", "warning");
          break;
      }
      
    }
  }
}
</script>

<style scoped>
  .cellcommon {
    border-left: 1px solid #c3c3c3;
    border-top: 1px solid #c3c3c3;
  }
  .newAvailable1 {
    background-color: #F9F9F9;
  }
  .newAvailable2 {
    background-color: #E9F0EB;
  }
  .changeAvailable1 {
    background-color: #F9F9F9;
  }
  .changeAvailable2 {
    background-color: #E9F0EB;
  }
  .reserved {
    background-color: #B1B8BE;
  }
  .mine {
    background-color: #87D2DE;
    color: #000000;
  }
  .unused {
    background-color: #B1B8BE;
  }
  .changeCurrent {
    background-color: #6610f2;
    color: #d7fff8;
  }
  .selected {
    background-color: #e83e8c
  }
  .gray100 {
    background-color: #f8f9fa;
  }
  .gray300 {
    background-color: #dee2e6;
  }
  .gray500 {
    background-color: #adb5bd;
  }
  .gray600 {
    background-color: #6c757d;
  }
  .gray700 {
    background-color: #495057;
  }
  .gray800 {
    background-color: #343a40;
  }
  .gray900 {
    background-color: #212529;
  }
  .blue {
    background-color: #007bff
  }
  .indigo {
    background-color: #6610f2
  }
  .purple {
    background-color: #6f42c1
  }
  .pink {
    background-color: #e83e8c
  }
  .red {
    background-color: #dc3545
  }
  .orange {
    background-color: #fd7e14
  }
  .yellow {
    background-color: #ffc107
  }
  .green {
    background-color: #28a745
  }
  .teal {
    background-color: #20c997
  }
  .cyan {
    background-color: #17a2b8
  }
</style>