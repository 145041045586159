//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SeatStatus from '../views/SeatStatus.vue'
import AuthPhone from '../views/AuthPhone.vue'
import LoginView from '../views/LoginView.vue'
import MyPayList from '../views/MyPayList.vue'
import MyProfile from '../views/MyProfile.vue'
import ReserveRoom from '../views/ReserveRoom.vue'
import RoomStatus from '../views/RoomStatus.vue'
import LockerStatus from '../views/LockerStatus.vue'
import MyUseList from '../views/MyUseList.vue'
import STRStatus from '../views/STRStatus.vue'
import Com from '@/lib/common.js';
//import store from "@/store";

const routes = [
  /* / 에 대한 reidrect 추가  - 2023.10.23 */
  {
    path: '/',
    redirect: { name: 'selectBranch' }
  },
  {
    path: '/selectBranch',
    name: 'selectBranch',
    meta: { unauthorized: true, isNeedBranch: false },
    component: () => import(/* webpackChunkName: "selectBranch" */ '../views/SelectBranch.vue')
  },
  {
    path: '/:mblSiteId',
    // path: '/home',
    name: 'home',
    meta: { unauthorized: true, isNeedBranch: true },
    component: HomeView,
    children: [
      {
        path: '/:mblSiteId/seatStatus',
        name: 'seatStatus',
        meta: { unauthorized : true, isNeedBranch: true },
        component: SeatStatus
      },
      {
        path: '/:mblSiteId/authPhone',
        name: 'authPhone',
        meta: { unauthorized : true, isNeedBranch: true },
        component: AuthPhone
      },
      {
        path: '/:mblSiteId/login',
        name: 'login',
        meta: { unauthorized : true, isNeedBranch: true },
        component: LoginView
      },
      {
        path: '/:mblSiteId/myPayList',
        name: 'myPayList',
        meta: { unauthorized : false, isNeedBranch: true },
        component: MyPayList
      },
      {
        path: '/:mblSiteId/myProfile',
        name: 'myProfile',
        meta: { unauthorized : false, isNeedBranch: true },
        component: MyProfile
      },
      {
        path: '/:mblSiteId/reserveRoom',
        name: 'reserveRoom',
      /*  meta: { unauthorized : false, isNeedBranch: true }, 비회원 구매을 위해 변경 - 2024.03.22 */
        meta: { unauthorized : true, isNeedBranch: true },
        component: ReserveRoom
      },
      {
        path: '/:mblSiteId/roomStatus', 
        name: 'roomStatus', 
        meta: { unauthorized : true, isNeedBranch: true },
        component: RoomStatus 
      },
      /* 스터디룸 전체 현황 - 2023.08.18 */
      {
        path: '/:mblSiteId/STRStatus', 
        name: 'strStatus', 
        meta: { unauthorized : true, isNeedBranch: true },
        component: STRStatus 
      },
      {
        path: '/:mblSiteId/lockerStatus', 
        name: 'lockerStatus', 
        meta: { unauthorized : true, isNeedBranch: true },
        component: LockerStatus 
      },
      {
        path: '/:mblSiteId/myUseList', 
        name: 'myUseList', 
        meta: { unauthorized : false, isNeedBranch: true },
        component: MyUseList 
      },
      {
        path: '/:mblSiteId/signUp', 
        name: 'signUp', 
        meta: { unauthorized : true, isNeedBranch: true },
        component: () => import(/* webpackChunkName: "signUp" */ '../views/SignUp.vue')
      },
      {
        path: '/:mblSiteId/changePassword',
        name: 'changePassword',
        meta: { unauthorized : false, isNeedBranch: true },
        component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue')
      },
    ],
  },
  {
    path: '/loginAccess',
    name: 'loginAccess',
    meta: { unauthorized : true, isNeedBranch: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loginAccess" */ '../views/LoginAccess.vue')
  },
  {
    path: '/signUpAccess',
    name: 'signUpAccess',
    meta: { unauthorized : true, isNeedBranch: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signUpAccess" */ '../views/SignUpAccess.vue')
  },

  // {
  //   path: '/aboutVuetify',
  //   name: 'aboutVuetify',
  //   meta: { unauthorized : true, isNeedBranch: false },
  //   component: () => import(/* webpackChunkName: "aboutVuetify" */ '../views/AboutVuetify.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   meta: { unauthorized : true, isNeedBranch: false },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  // {
  //   path: '/kakaotest',
  //   name: 'kakaotest',
  //   meta: { unauthorized : true, isNeedBranch: false },
  //   component: () => import(/* webpackChunkName: "kakaotest" */ '../views/kakaotest.vue')
  // },
  
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

// select branch - 2024.01.08
const SEL_BRANCH = "selectBranch";

// 로그인 체크
router.beforeEach(async (to, from, next) => {
  Com.log("router.beforeEach start", to);

  // 쿠키에서 지점 정보 조회 - 2023.04.20
  const branch = Com.readBranchFromCookie();

  // selectBranch 가 아닌 경우
  if (to.name != SEL_BRANCH && to.name != "loginAccess" && to.name != "signUpAccess") {

    const paramMblSiteId = to.params ? to.params.mblSiteId : undefined;
    const branchMblSiteId = branch ? branch.mblSiteId : undefined;
    const query = to.query;
    let toShpId = undefined;

    // 지점 선택 화면이 아니면서 쿼리파라미터가 있는 경우에 대해서만 처리 - 2023.10.19
    if (query) {
  
      for (let key in query) {
        //Com.log("router.beforeEach key", key);
  
        if (key.startsWith("shp")) {
          toShpId = key.substring(3);
          //Com.log("router.beforeEach toShpId", toShpId);
          break;
        } // end of if (key.startsWith("shp")) {
  
      } // end of for (let key in query) {
  
    } // end of if (query) {

    // 지점 선택 파라미터가 있는 경우
    if (toShpId) {

      // query 파라미터 설정 - 2024.01.08
      let queryParam = {
        shpId: toShpId,
      };
      
      // 이동할 페이지가 home이 아니경우 이동할 페이지로 지정 - 2024.01.07
      if (to.name != "home") {
        queryParam.toPage = to.name;
      }

      Com.log("router.beforeEach SEL_BRANCH 이동", toShpId);
      //return next({ path: "/" + toShpId, query: toPage ? { toPage : toPage } : undefined });
      // selectBranch 페이지로 이동
      return next({ name: SEL_BRANCH, query: queryParam});

    } // end of if (toShpId) {
  
    // 접근 지점 정보가 없는 경우 -> 지점 선택
    else if (paramMblSiteId == undefined || !branch) {
      return next({ name: SEL_BRANCH });
    }

    // 저장된 지점과 접근 지점이 다른 경우 -> 지점 변경
    else if (paramMblSiteId != branchMblSiteId) {

      let queryParam = Object.assign({}, to.query);
      queryParam.mblSiteId = paramMblSiteId;

      // 현재페이지가 home 이 아닌 경우에는 지점 설정 후 다시 페이지를 이동하도록 이동 페이지 설정 - 2024.01.07 
      if (to.name != "home") {
        queryParam.toPage = to.name;
      }

      Com.log("router.beforeEach selectBranch 이동 queryParam", queryParam);
      return next({ name: SEL_BRANCH, query: queryParam });
    }
  }

  // 지점선택이 필요한데 지점이 없는 경우 - 2023.04.20
  if (to.matched.some(record => record.meta.isNeedBranch) &&
    (!branch || !branch.shpId || branch.shpId < 1)) {
      Com.log("router.beforeEach 지점정보 선택을 위한 selectBranch 이동");
      return next({ name: 'selectBranch'});
  }

/* - 2023.09.06  
  // 쿠키에서 로그인 정보 조회
  let login = Com.readLoginFromCookie();

  // token refresh가 필요한 경우 - 2023.04.04
  if (login.refreshToken && login.accessToken == undefined) {
    await Com.tokenRefresh();

    // 로그인 정보 다시 읽기 - 2023.04.20
    login = Com.readLoginFromCookie();
  }
*/
  //const isLogined = await Com.isLogined();
  let isLogined = false;

  // 새로고침에 대한 로그인 처리 - 2023.10.18
  const login = await Com.getLogin();
  isLogined = login.isLogined;

  Com.log("router.beforeEach to : ", to);
  Com.log("router.beforeEach login : ", login);

  // 로그인 상태를 아니면서 token이 존재하는 경우 - 2023.10.18
  if (!login.isLogined && login.accessToken && branch && branch.shpId) {
    Com.log("router.beforeEach", "새로고침 되었음");
    isLogined = await Com.setLoginInfoFromToken(branch.shpId);
  }

  // 로그인이 필요한 경우 (login 쿠키가 없거나 login의 refreshToken이 없는 경우)
  if (to.matched.some(record => !record.meta.unauthorized) && !isLogined) {
    return next({ name: 'login', query: {fromPage: from.name, toPage: to.name}});
  }
  // 로그인이 필요없거나 토큰이 있는 경우
  else if (to.matched.some(record => record.meta.unauthorized) || isLogined){
    return next();
  }
  else {
    return next();
/*    
    Com.tokenRefresh().then(ret => {
  
      // token refresh
      if (ret){
        return next();
      }
      else {
        return next('/login');
      }
    });
*/
  }
})

router.afterEach(() => {
  // 쿠키에서 지점 정보 조회 - 2023.12.20
  const branch = Com.readBranchFromCookie();

  // 타이틀 설정
  const title = (!branch || !branch.shpId || branch.shpId < 1) ? "스터디매니저" : branch.shpNm;

  if (title) document.title = title;
})
  
export default router
