<template>

  <!-- 현재 예약이 있는 경우  -->
  <div>

    <confirm-view ref="cf"/>

    <v-card
      class="ma-3 text-center text-size"
      title="스터디룸 예약변경"
      max-width="400px"
    >

      <v-row class="ma-0 pa-0">
        <v-col cols="4" class="text-center py-2 border">
          <div>사용자</div>
        </v-col>
        <v-col cols="8" class="text-center py-2 border">
          {{ dispMpnum }} <!--{{ '(' + item.item.usStCnt + '명)' }}-->
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="4" class="text-center py-3 border">
          예약내용
        </v-col>
        <v-col cols="8" class="text-center py-2 border">
          <span>{{ roomTitle }}</span><br/>
          <span>{{ usDeNm }}</span>
          <span class="ml-2">{{ item.term }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="4" class="changeSelect text-center py-3 border">
          <v-btn
            class="m-0 ml-1 text-size"
            size="sm"
            @click="$emit('reqChangeSelect', item)"
            :disabled="!isPossibleCancel"
          >변경내용</v-btn>
        </v-col>
        <v-col cols="8" class="changeSelect text-center py-2 border">
          <div v-if="isPossibleCancel">
            <div v-if="changeSelectInfo">
              <span>{{ changeRoomTitle }}</span><br/>
              <span>{{ changeUsDeNm }}</span>
              <span class="ml-2">{{ changeTerm }}</span>
            </div>
            <div v-else class="text-left">
              좌측 변경내용 버튼을 클릭하여 스터디룸과 일자를 선택하세요.
            </div>
          </div>            
          <div v-else class="text-left">
            취소 및 변경 가능 시간이 지났습니다. 취소 및 변경은 시작시간 기준으로 {{ canclLimitHour }} 시간 전에 가능합니다.
          </div>
        </v-col>
      </v-row>
      <v-row class="my-2 pa-0" justify="center">
<!--
        <b-col cols="3" class="m-0 p-0">
          <v-btn
            class="m-2"
            @click="onCancelReserve()"
            :disabled="!isPossibleCancel"
          >예약취소</v-btn>
        </b-col>
-->
        <v-col cols="3" class="mx-2 pa-0">
          <v-btn
            class="ma-0 text-size"
            @click="$emit('cancelChangeReserve')"
          >변경취소</v-btn>
        </v-col>
        <v-col cols="3" class="mx-2 pa-0">
          <v-btn
            class="ma-0 savebutton text-size"
            :disabled="changeSelectInfo === undefined"
            @click="onChangeReserve()"
          >변경저장</v-btn>
        </v-col>
      </v-row>

    </v-card>
  </div>

</template>

<script>
import Com from '@/lib/common';
import URL from '@/lib/url';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    item: Object,
    changeSelectInfo: Object,
    canclLimitTim: String,
    canclLimitHour: Number,
  },

  components: { ConfirmView },

  setup() {
    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { branch };
  },

  data() {
    return {
      mpnum: '',
      bookMin: 0,
      usStCnt: 0, /* 사용 인원 - 2023.05.04 */
      isPossibleCancel: false,  // 취소 가능 여부 - 2023.05.09
    }
  },

  mounted() {
    Com.log("reserve", this.item);

    // 취소 가능 여부 설정 ( 서버시간 및 지점별 취소가능 시간 설정 기준 ) - 2023.05.09
    if (this.canclLimitTim <= this.item.item.bgnTim) {
      this.isPossibleCancel = true;
    }
    else {
      this.isPossibleCancel = false;
    }
  },

  computed: {
    roomTitle() {
      return this.item.header.roomNam + " (" + this.item.header.stCnt + "인용)"
    },

    usDeNm() {
      //return Com.stringFormatter(this.item.header.date, 'DAY5');
      // 2023.06.06 - 24시 이후의 예약을 표시하며 시작시간 으로 변경
      return Com.stringFormatter(this.item.item.bgnTim, 'DAY5');
    },

    dispMpnum() {
      return Com.stringFormatter(this.item.item.mpnum, 'TEL');
    },

    changeRoomTitle() {
      if (this.changeSelectInfo) {
        return this.changeSelectInfo.header.roomNam +
          " (" + this.changeSelectInfo.header.stCnt + "인용)"
      }
      else {
        return undefined;
      }
    },

    changeUsDeNm() {
      if (this.changeSelectInfo) {
        const strDay = this.changeSelectInfo.header.date;
        const mngTmCd = this.changeSelectInfo.strMngTm.mngTmCd;
        const hour = mngTmCd.substring(0, 2) * 1;

        //Com.log("changereserve.changeUsDeNm mngTmCd", mngTmCd);
        
        // 선택 날짜 설정
        let date = new Date(strDay.substring(0, 4), strDay.substring(4, 6) * 1 - 1, strDay.substring(6, 8) * 1);
        //Com.log("changereserve.changeUsDeNm before", date);
        date.setHours(hour);

        //Com.log("changereserve.changeUsDeNm after", date);
        //return Com.stringFormatter(this.changeSelectInfo.header.date, 'DAY5');
        // 2023.06.06 - 24시간 이후의 예약 일자 표시
        return Com.stringFormatter(Com.getFormatDate(date, 'yyyymmdd'), 'DAY5');
      }
      else {
        return undefined;
      }
    },

    changeTerm() {
      if (this.changeSelectInfo) {
        const mngTmCd = this.changeSelectInfo.strMngTm.mngTmCd;
        const hour = mngTmCd.substr(0, 2) * 1;
        const min = mngTmCd.substr(2, 2) * 1;

        let date = new Date();
        date.setHours(hour);
        date.setMinutes(min);

        // 시작시간 형식
        const bgnMngTmNm = Com.leadingZeros(date.getHours(), 2) + ':' +
                            Com.leadingZeros(date.getMinutes(), 2);

        // 종료 시간 계산
        date.setMinutes(min + this.item.item.timeSlotCnt * 30);

        // 종료시간 형식
        const endMngTmNm = Com.leadingZeros(date.getHours(), 2) + ':' +
                            Com.leadingZeros(date.getMinutes(), 2);
/*                            
        return Com.stringFormatter(this.changeSelectInfo.strMngTm.mngTmCd, 'HHMI2') +
                ' ~ ' + endMngTmNm;
*/
        // 2023.06.06 - 24시간 이후의 예약 시간 표시
        return bgnMngTmNm + ' ~ ' + endMngTmNm;                
      }
      else {
        return undefined;
      }
    }
    
  },


  methods: {

		/* 예약변경 - 2023.10.08	*/
		async onChangeReserve() {

      // 스터디룸 예약 파라미터 설정
      const param = {
        pytId: this.item.item.pytId,
        bookSrno: this.item.item.bookSrno,
        stNo: this.changeSelectInfo.header.stNo,
        usDe: this.changeSelectInfo.header.date,
        mngTmCd: this.changeSelectInfo.strMngTm.mngTmCd
      };

      Com.log("ChangeReserve.onChangeReserve.param", param);

      const isLogined = await Com.isLogined();

      let res = undefined;

      // 로그인 되어 있는 경우
      if (isLogined) {
        // 스터디룸 예약 변경
        res = await Com.callServer(URL.MBER.CHANGE_STR_BOOK, param);
      }
      // 로그인 안되어 있는 경우 - 2024.07.05
      else {
        param.shpId = this.branch.shpId;
        param.mpnum = this.item.item.mpnum;
        param.buyPwd = this.item.item.buyPwd;

        // 비로그인 스터디룸 예약 변경
        res = await Com.callServer(URL.PBLC.CHANGE_STR_BOOK, param);
      }

      if (res != null && res.rStatus === 0) {
        await this.$refs.cf.msgOk("예약이 변경되었습니다.");
        // 스터디룸 예약 조회 새로고침 - 2023.05.06
        this.$emit("changeComplete");
      }
      else if (res.rStatus === 33) {
        // 예약변경 불가 메시지 표시 - 2023.10.08
        await this.$refs.cf.msgOk("해당시간에 예약이 존재합니다. 다른 시간을 선택해주세요.");

      }
      else if (res.rStatus === 31 || res.rStatus === 32) {
        await this.$refs.cf.msgOk("스터디룸 예약변경이 실패하였습니다(" + res.rStatus + ").", "error");
      }
      else {
        // 오류 메시지 표시
        await this.$refs.cf.msgOk("스터디룸 예약변경이 실패하였습니다.", "error");
      }

    },

		/* 예약취소 - 2023.05.07	*/
/*    
		onCancelReserve() {

      Com.msgBoxConfirm(
        this, // page object
        "예약 취소 확인", // title
        "스터디룸 예약을 취소 하시겠습니까?", // message
        "danger", // variant
        "예약취소",  // button1
        "닫기"   // button2
      ).then(value => {
        //  ok버튼을 클릭한 경우.
        if (value) {

          // 스터디룸 예약 취소 파라미터 설정
          const param = {
            pytId: this.item.item.pytId,
            bookSrno: this.item.item.bookSrno,
          };

          Com.log("ChangeReserve.onCancelReserve.param", param);

          // 스터디룸 예약 변경
          this.$store.dispatch('server/callServer', {url: URL.ADMIN.CANCEL_STR_BOOK, param: param})
            .then((data) => {

                if (data.rStatus === 0) {
                  // 메시지 표시
                  Com.msgBoxOk(this, "스터디룸 예약 취소 성공", "스터디룸 예약이 취소되었습니다.", "danger")
                    .then(() => {
                      // 스터디룸 예약 조회 새로고침 - 2023.05.06
                      this.$emit("changeComplete");
                    });
                }
                else {
                  // 메시지 표시
                  Com.msgBoxOk(this, "스터디룸 예약 취소 실패", "스터디룸 예약취소가 실패하였습니다.", "danger");
                }

            }); // end of then((data) => {

        }
      });

    },
*/
  }
}
</script>

<style scoped>
  .text-size {
    font-size: 12px;
  }
  .savebutton {
    border: none;
    background-color: #1380f5;
    color: #ffffff;
  }
  .changeSelect {
    background-color: #fffccc
  }

</style>