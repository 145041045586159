<template>
  <v-container class="ma-0 pa-0">

    <confirm-view ref="cf"/>

    <!-- 진행중 표시 begin - 2023.04.05 -->
    <v-progress-circular
      v-if="isRunning"
      indeterminate
      color="indigo-darken-2"
      :size="100"
      :style="{
        marginLeft: ((width - 100) / 2) + 'px',
        marginTop: '10px'
      }"
    ></v-progress-circular>
    <!-- 진행중 표시 end - 2023.04.05 -->

    <v-sheet
      class="mx-auto"
      :width="width"
      v-if="!isRunning && items"
    >

      <v-row class="mt-3 mb-1 mr-1" justify="end">
        <v-col cols="2" class="notused text-center pa-0 ma-1">
          미운영
        </v-col>
        <v-col cols="2" class="book text-center pa-0 ma-1">
          예약됨
        </v-col>
        <v-col cols="2" class="vacant text-center pa-0 ma-1">
          예약가능
        </v-col>
      </v-row>

      <table class="mx-auto">
        <thead>
          <tr>
            <th class="text-center header-book" width="16%">
              시간
            </th>
            <th class="text-center header-book" width="17%">
              예약
            </th>
            <th class="text-center header-book" width="16%">
              시간
            </th>
            <th class="text-center header-book" width="17%">
              예약
            </th>
            <th class="text-center header-book" width="16%">
              시간
            </th>
            <th class="text-center header-book">
              예약
            </th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr
            v-for="idx in 16"
            :key="idx"
          >
            <td v-if="items[idx - 1].min==0" rowspan="2" class="header-time">
              {{ items[idx-1].hour<12?'오전':'오후' }}
              <br/>
              {{ getHourFormat(items[idx-1].hour) }}
            </td>
            <td
              :class="{
                book: items[idx - 1].mpnum,
                vacant: items[idx - 1].useAt === 'Y' && !items[idx - 1].mpnum,
                notused: items[idx - 1].useAt === 'N'
              }"
              @click="onSelectTime(items[idx - 1], $event)"
            >
            </td>
            <td v-if="items[16 + idx - 1].min==0" rowspan="2" class="header-time">
              {{ items[16 + idx-1].hour<12?'오전':'오후' }}
              <br/>
              {{ getHourFormat(items[16 + idx - 1].hour) }}
            </td>
            <td
              :class="{
                book: items[16 + idx - 1].mpnum,
                vacant: items[16 + idx - 1].useAt === 'Y' && !items[16 + idx - 1].mpnum,
                notused: items[16 + idx - 1].useAt === 'N'
              }"
              @click="onSelectTime(items[16 + idx - 1], $event)"
            >
            </td>
            <td v-if="items[32 + idx - 1].min==0" rowspan="2" class="header-time">
              {{ items[32 + idx - 1].hour<12?'오전':'오후' }}
              <br/>
              {{ getHourFormat(items[32 + idx - 1].hour) }}
            </td>
            <td
              :class="{
                book: items[32 + idx - 1].mpnum,
                vacant: items[32 + idx - 1].useAt === 'Y' && !items[32 + idx - 1].mpnum,
                notused: items[32 + idx - 1].useAt === 'N'
              }"
              @click="onSelectTime(items[32 + idx - 1], $event)"
            >
            </td>
          </tr>
        </tbody>

      </table>


    </v-sheet>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from "vue";
import { useStore } from 'vuex';

export default {

  props: {
    stNo: {
      type: String,
      default: null
    },
    date: {
      type: Object,
      default: new Date()
    },
    isReserveMode: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 600
    }
  },

  setup() {
    const store = useStore();
    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { branch };
  },

  components: { ConfirmView },
  
  data: () => ({
    items: undefined,
    isRunning: true,  /* 서버 조회중 여부 - 2023.04.19 */
  }),

  mounted() {

    Com.log("RoomTimeStatus.mounted", "stNo: " + this.stNo + ", date: " + this.date);

    // 스터디룸 번호 및 일자가 전달된 경우 - 2023.04.14
    if (this.stNo && this.date) {
      // 예약 목록 조회 - 2023.04.12
      this.getStudyRoomBookList();
    }

  },

  methods: {

    /** 예약 목록 조회 - 2023.04.12
     *  - 조회전에 branch, selectedSTR 이 설정되어 있어야 함
     */
    async getStudyRoomBookList() {

      // 서버 조회 파라미터 설정
      const param = {
        shpId: this.branch.shpId,
        stNo: this.stNo,
        usDe: Com.getFormatDate(this.date, 'yyyymmdd'),
      };

      // 서버 조회
      const res = await Com.callServer(URL.PBLC.FETCH_STUDYROOM_BOOK_LIST, param);

      // 서버 조회 완료
      this.isRunning = false;

      // 서버조회 결과값이 있는 경우
      if (res !== null) {

        // 서버 조회 성공인 경우
        if (res.rStatus === 0) {
          this.items = res.retList;
          this.items.forEach((item) => {
            // 시간값 설정
            item.hour = item.mngTmCd.substr(0, 2) * 1;
            // 분값 설정
            item.min = item.mngTmCd.substr(2, 2) * 1;
          });
        }
        // 오류가 발생한 경우
        else {
          Com.log("RoomTimeStatus.getStudyRoomBookList", res.errorMsg, "error");
          //alert("예약목록 조회중 오류가 발생했습니다.(" + res.errorMsg + ")");
          this.$refs.cf.msgOk("예약목록 조회중 오류가 발생했습니다.(" + res.errorMsg + ")", "error");
        }
      }
    },

    /* 시간 형식 - 2023.02.14 */
    getHourFormat(hour) {

      let hourForm = '';

      if (hour == 0) {
        hourForm = '12시';
      }
      else if (hour < 12) {
        hourForm = hour + '시';
      }
      else {
        // 12시도 함께 처리하기 위해 시간 연산 수행
        hourForm = ((hour-1)%12+1) + '시';
      }
      
      return hourForm;
    },

    /* 예약정보 형식 - 2023.02.14 */
    getBookForm(mpnum) {
      let bookForm = ' ';

      if (mpnum != undefined && mpnum != null) {
        bookForm = '*' + Com.stringFormatter(mpnum, 'TEL-MASK1');
      }
      else {
        bookForm = ' ';
      }

      return bookForm;
    },

    /**
     * 시간선택 이벤트 핸들러 - 2023.04.14
     */
    onSelectTime(item, evt) {
      Com.log("RoomTimeStatus.onSelectTime item", item);
      Com.log("RoomTimeStatus.onSelectTime evt", evt);
      let availableCnt = 0;

      // 예약 모드 인 경우
      if (this.isReserveMode) {

        // 예약된 셀이거나 운영시간이 아닌 경우 - 2023.09.09
        if (item.mpnum || item.useAt === 'N') {
          return;
        }

        evt.target.classList.add("selected");

        // 선택시간 찾았는지 여부
        let isStart = false;

        for (let i = 0; i < this.items.length; ++i) {
          // 목록에서 선택된 시간 찾기
          if (this.items[i].mngTmCd === item.mngTmCd) {
            isStart = true;
          }

          // 목록에서 사용가능한 타임(30분 단위) 개수 카운트
          if (isStart) {
            // 연결된 유효한 타임인 경우
            if (this.items[i].useAt === 'Y' && !this.items[i].mpnum) {
              availableCnt++;
            }
            // 영업시간이 아니거나 이미 예약된 경우
            else {
              break;
            }
          } 
        }
      }

      // 선택시간과 유효한 타임 개수 전달
      this.$emit('timeSelect', { time: item, availableCnt: availableCnt});
    },

    mouseDownHandler(evt) {
      evt.target.classList.add("clickable-text");
    },

    mouseUpHandler(evt) {
      evt.target.classList.remove("clickable-text");
    }
  },
}
</script>

<style scoped>
  table {
    width: 100%;
  }
  th, td {
    height: 12px;
    font-size: 13px;
  }

  .header-time {
    background-color: darkgray;
    font-size: 12px;
  }
  .header-book {
    background-color: rgb(68, 0, 177);
    color: rgb(255, 255, 255);
    font-size: 12px;
  }
  .vacant {
    cursor: pointer;
    background-color: rgb(84, 205, 235);
    font-size: 10px;
  }
  .book {
    background-color: rgb(241, 238, 9);
    font-size: 10px;
  }
  .notused {
    background-color: rgb(209, 209, 200);
    font-size: 10px;
  }
  .selected {
    background-color: rgb(68, 0, 177);
    cursor: pointer;
    font-size: 20px;
    height: 40px;
    width: 100px;
  } 

</style>
