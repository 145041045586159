<template>

  <!-- 현재 예약이 없는 경우  -->
  <div>
    <v-card
      class="ma-3 text-center text-size"
      title="스터디룸 예약하기"
      max-width="420px"
    >

      <v-row class="mx-2 my-0 p-0">
        <v-col cols="4" class="py-2 border">
          스터디룸
        </v-col>
        <v-col cols="8" class="text-center py-2 border">
          {{ roomTitle }}
        </v-col>
      </v-row>
      <v-row class="mx-2 my-0 p-0">
        <v-col cols="4" class="py-2 border">
          사용일시
        </v-col>
        <v-col cols="8" class="text-center py-2 border">
          <span>{{ usDeNm }}</span>
          <span class="ml-4">{{ item.strMngTm.mngTmNm }}</span>
        </v-col>
      </v-row>
      <v-row class="mx-2 my-0 pa-0">
        <v-col cols="4" class="p-0 border">
          사용시간
        </v-col>
        <v-col cols="8" class="ma-0 pt-2 border">
          <v-select
            :items="buyableProd"
            item-title="usPdNm"
            item-value="prdtId"
            v-model="selPrdtId"
            density="compact"
            class="ma-0 pa-0 text-size"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mx-2 my-0 p-0">
        <v-col cols="4" class="py-2 border">
          상품명
        </v-col>
        <v-col cols="8" class="text-center py-2 border">
          <span>{{ selectedProdNm }}</span>
        </v-col>
      </v-row>
      <v-row class="mx-2 my-1 text-left">
        <v-col>
          <div>* 예약된 스터디룸은 시작시간 기준으로 {{ canclLimitHour }} 시간 전에만 취소 및 변경이 가능합니다.</div>
        </v-col>
      </v-row>
      <v-row class="mx-0 my-3 pa-0" justify="center">
        <v-col class="ma-0 pa-0">

          <v-btn
            @click="$emit('closeModal')"
            class="pa-1 ma-0 text-size"
          >취소하기</v-btn>

        </v-col>
<!--
        <v-col v-if="buyableProd.length > 0" class="ma-0 pa-0">
          
          <v-btn
            color="success"
            class="pa-1 text-size"
          >
          예약하기(네이버페이)
          </v-btn>

        </v-col>
-->
        <v-col v-if="buyableProd.length > 0" class="ma-0 pa-0">

          <v-btn
            color="primary"
            @click="payByBank"
            :disabled="!branch.bankAcct"
            class="pa-1 text-size"
          >
            <span v-if="isLogined">
              예약하기(무통장 입금)
            </span>
            <span v-else>
              예약자 정보 입력
            </span>
          </v-btn>

        </v-col>

      </v-row>

    </v-card>
  </div>

</template>

<script>
import Com from '@/lib/common';
//import URL from '@/lib/url';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  props: {
    item: Object,
  },

  setup() {
    const store = useStore();

    // 스터디룸 목록
    const studyRoomList = computed(() => store.getters.studyRoomList);

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    return { studyRoomList, branch, store };
  },

  data() {
    return {
      //bookUseIdx: 1,
      selPrdtId: 0,
      mpnum: '',
      isMpnumInputActive: false,
      isPriceInputActive: false,
      bookMin: 0,
      usStCnt: 0, /* 사용 인원 - 2023.05.04 */
      reserveInfo: undefined,   /* 예약정보 - 2023.09.06 */
      isLogined: false,         /* 로그인 여부 - 2024.07.08 */
    }
  },

  async mounted() {
    Com.log("reserve", this.item);

    // 기본 상품 지정
    if (!this.selPrdt) {
      this.selPrdtId = this.buyableProd[0].prdtId;
    }

    // 로그인 여부 확인 - 2024.07.08
    this.isLogined = await Com.isLogined();

    Com.log("NewReserve selprdt", this.selPrdt);
  },

  computed: {
    /** 스터디룸 이름 - 2023.09.05 */
    roomTitle() {
      return this.item.header.roomNam + " (" + this.item.header.stCnt + "인용)"
    },

    /** 선택일자 - 2023.09.05 */
    usDeNm() {
      const strDay = this.item.header.date;
      const mngTmCd = this.item.strMngTm.mngTmCd;
      const hour = mngTmCd.substring(0, 2) * 1;

      // 선택 날짜 설정
      let date = new Date(strDay.substring(0, 4), strDay.substring(4, 6) * 1 - 1, strDay.substring(6, 8) * 1);
      date.setHours(hour);

      //return Com.stringFormatter(this.item.header.date, 'DAY5');
      // 2023.06.06 - 24시간 이후의 예약 일자 표시
      return Com.stringFormatter(Com.getFormatDate(date, 'yyyymmdd'), 'DAY5');
    },

    /** 남은 시간과 선택 스터디룸을 고려한 구매가능 상품 목록 - 2023.09.04 */
    buyableProd: {
      get: function() {
        Com.log("reserve.buyableProd " + this.item.header.usNmpr + "인용(요금)",
          this.store.getters.strPrdtList(this.item.header.usNmpr));

        if (this.item.header.usNmpr !== undefined &&
          this.store.getters.strPrdtList(this.item.header.usNmpr) !== undefined
        ) {

          const prodList =
          this.store.getters.strPrdtList(this.item.header.usNmpr)
            .filter(item => item.time * 1 <= this.item.timeSlotCnt * 0.5);

          //Com.log("buyableProd prodList", prodList);

          return prodList;
        }
        else {
          return [];
        }
      }
    },

/*
    dispMpnum: {
      get: function() {
        if (this.isMpnumInputActive) {
          // Cursor is inside the input field. unformat display value for user
          // 문자를 제외한 숫자만 남김 - 2022.05.28
          return this.mpnum ? this.mpnum.replace( /^\D+/g, '') : this.mpnum;
        } else {
          // User is not modifying now. Format display value for user interface
          // 문자를 제외한 숫자만 남김 - 2022.05.28
          return Com.stringFormatter(this.mpnum ? this.mpnum.replace( /\D+/g, '') : this.mpnum, 'TEL');
        }
      },
      set: function(modifiedValue) {
        // 문자를 제외한 숫자만 남김 - 2022.05.28
        const newMpnum = modifiedValue ? modifiedValue.replace( /\D+/g, '') : modifiedValue;

        if (newMpnum === null || newMpnum.length < 12) {
          this.mpnum = newMpnum;
        }
      }
    },
*/
    /* 스터디룸 취소 가능 시간 - 2023.05.10 */
    canclLimitHour() {
      return this.branch.canclLimitHour;
    },

    /* 선택 상품의 상품명 (이벤트 상품 노출을 위해 추가) - 2023.09.04 */
    selectedProdNm() {
      if (this.selPrdt) {
        return this.selPrdt.prdtNm;
      }
      else {
        return '상품 미존재';
      }
    },

    /** 선택 상품 정보 - 2023.09.04 */
    selPrdt() {
      if (this.selPrdtId > 0) {
        return this.buyableProd.filter(prdt => prdt.prdtId === this.selPrdtId)[0];
      }
      else {
        return undefined;
      }
    }
  },


  methods: {

    /** 무통장 입금 결제 요청 - 2023.09.06 */
    payByBank() {
      this.$emit('clickBank', {
        prdtId: this.selPrdt.prdtId,               // 상품 ID
        prdtAmt: this.selPrdt.prdtAmt * 1,         // 상품금액
        stNo: this.item.header.stNo,          // 스터디룸 번호
        usDe: this.item.header.date,          // 예약일자
        mngTmCd: this.item.strMngTm.mngTmCd,  // 예약시간
        usStCnt: this.item.header.stCnt,      // 사용자수
      });
    }

		/* 예약	*/
/*    
		onReserve() {

      if (this.usStCnt < 1) {
        Com.msgBoxOk(this, "입력확인", "사용인원을 확인해 주세요.", "danger");
        return;
      }

      // 사용제한 휴대폰 체크 - 2023.08.07
      this.$store.dispatch('product/isLmtMpnOK', { mpnum: this.$store.state.user.mpnum, callback: (isOK) => {

        // 사용제한 휴대폰이 아닌 경우 - 2023.08.07
        if (isOK) {

          // 상품가격
          const prdtAmt = this.buyableProd[this.bookUseIdx].prdtAmt;
          // 상품이름
          const prdtNm = this.buyableProd[this.bookUseIdx].prdtNm;


          // 스터디룸 예약 파라미터 설정
          const param = {
            prdtId: this.buyableProd[this.bookUseIdx].prdtId,
            mpnum: this.$store.state.user.mpnum,
            reserveList: [
              {
                stNo: this.item.header.stNo,
                usDe: this.item.header.date,
                mngTmCd: this.item.strMngTm.mngTmCd,
                prdtId: this.buyableProd[this.bookUseIdx].prdtId,
                usStCnt: this.usStCnt,
              }
            ],
          };

          // 스터디룸 예약
          // 스터디룸 예약 (요청 및 확정)
          this.$store.dispatch('server/callServer', {url: URL.KIOSK.REQ_RESERVE_STR, param: param})
            .then((data) => {

              // 예약 요청이 성공한 경우
              if (data.rStatus === 0) {

                // 카드결제 진행
                Pay.onCreditPay({
                  store: this.$store,
                  pytId: data.retObject.pytId,  // 결제 ID
                  pytNm: prdtNm,                // 상품명   
                  pytAmt: prdtAmt,              // 결제 금액
                  funcPtr: this.confirmSTRBook  // 콜백 함수
                });
              }
              else if (data.rStatus === 31) {
                Com.msgBoxOk(this, "예약시간 확인", "해당시간에 예약이 존재합니다. 다른 시간을 선택해주세요.", "danger");
              }
              else {
                // 메시지 표시
                Com.msgBoxOk(this, "스터디룸 예약 실패", "스터디룸 예약이 실패하였습니다.", "danger");
              }

            }); // end of then((data) => {

        } // end of if (isOK)

      }}, {root: true}); // end of store.dispatch('product/isLmtMpnOK'

    },
*/
    /**
     * 
     * @param {카드결제결과} param { pytId, pytSttusCd, crdCom, crdNo, cnsnNo, pytDt } 
     */
/*    
    confirmSTRBook(param) {

      // 결제수단을 신용카드로 지정
      param.pytMtdCd = '01';

			// 스터디룸 예약 (확정)
			this.$store.dispatch('server/callServer', {url: URL.KIOSK.CONFIRM_STR_BOOK, param: param})
        .then((data) => {

          // 정상적으로 확정된 경우
          if (data.rStatus === 0) {

            // 메시지 표시
            Com.msgBoxOk(this, "스터디룸 예약 성공", "스터디룸 예약이 완료되었습니다.", "danger")
              .then(() => {
                // 사용자 정보 새로고침

                // 스터디룸 예약 조회 새로고침 - 2023.05.06
                this.$emit("refreshSTRBookList");

                // 사용자 정보 새로 고침 - 2023.05.27
                this.$store.dispatch("user/getUserInfo", {mpnum: this.$store.state.user.mpnum});
              });
          }
          // 스터디룸 예약 확정이 실패한 경우
          else {
            // 이전에 정상결제인 경우
            if (param.pytSttusCd === '02') {
                // 직전 결제 취소 처리
                Pay.onCancelLast(this.$store, {pytId: param.pytId});
            }
          }
        });
    }
*/    

  }
}
</script>

<style scoped>
  .text-size {
    font-size: 12px;
  }
  .savebutton {
    border: none;
    background-color: #1380f5;
    color: #ffffff;
    font-size: 12px;
  }
</style>