const WEB_LOGIN_BASE_URL = "/userlogin/V01";
const WEB_PBLC_BASE_URL = "/userpblc/V01";
const WEB_MBER_BASE_URL = "/user/webmber/V01";
const COMMON_BASE_URL = "/common/V01";

export default {
    COMMON: {
        // 배치도 경로 - 2023.06.29
        ASSET_PATH: COMMON_BASE_URL + "/assets",
    },

    LOGIN: {
        // SNS 로그인 - 2023.04.03
        USER_SNS_LOGIN: WEB_LOGIN_BASE_URL + "/loginBySNS",
        // ID/PW 로그인 - 2023.04.03
        USER_ID_LOGIN: WEB_LOGIN_BASE_URL + "/loginByID",
        // mobile 중복 체크 - 2023.04.03
        USER_MPNUM_AVAILABLE: WEB_LOGIN_BASE_URL + "/loginAvailable",
        // OTP 전송 - 2023.04.03
        USER_SEND_OTP: WEB_LOGIN_BASE_URL + "/sendOTP",
        // OTP 검증 - 2023.04.03
        USER_VERIFY_OTP: WEB_LOGIN_BASE_URL + "/verifyOTP",
        // SNS 회원 가입 - 2023.04.03
        USER_SNS_SIGNUP: WEB_LOGIN_BASE_URL + "/signUpBySNS",
        // ID/PW 회원 가입 - 2023.04.03
        USER_ID_SIGNUP: WEB_LOGIN_BASE_URL + "/signUpByID",
        // refresh token - 2023.04.04
        USER_REFRESH_TOKEN: WEB_LOGIN_BASE_URL + "/refreshToken",
        // 지점 목록 조회 - 2023.04.06
        USER_GET_BRANCHLIST: WEB_LOGIN_BASE_URL + "/getBranchList",
    },

    PBLC: {
        // 좌석 현황 조회 - 2023.04.10
        FETCH_SEAT_STATUS: WEB_PBLC_BASE_URL + "/getSeatStatus",
        // 스터디룸 목록 조회 - 2023.04.12
        FETCH_STUDYROOM_LIST: WEB_PBLC_BASE_URL + "/getStudyRoomList",
        // 스터디룸 예약 목록 조회 - 2023.04.12
        FETCH_STUDYROOM_BOOK_LIST: WEB_PBLC_BASE_URL + "/getStudyRoomBookList",
        // 스터디룸 상품 목록 조회 - 2023.04.14
        FETCH_STUDYROOM_PRDT_LIST: WEB_PBLC_BASE_URL + "/getStrPrdtList",
        // 스터디룸 예약 가능여부 확인 - 2023.04.15
        GET_STR_EXIST_LIST: WEB_PBLC_BASE_URL + "/getStrExistList",

        // 스터디룸 예약 목록 조회 - 2023.08.18
        FETCH_STR_BOOK_LIST: WEB_PBLC_BASE_URL + "/getSTRBookList",

        // 사물함 현황 조회 - 2023.10.13
        FETCH_LOCKER_STATUS: WEB_PBLC_BASE_URL + "/getLockerStatus",

        // 비회원 스터디룸 예약 등록 - 2024.03.22
        RESERVE_STR_BY_BANK: WEB_PBLC_BASE_URL + "/reserveSTRByBank",
        // 비회원 스터디룸 예약 변경 - 2024.03.22
        CHANGE_STR_BOOK: WEB_PBLC_BASE_URL + "/changeSTRBook",
        // 비회원 스터디룸 예약 취소 - 2024.03.22
        CANCEL_STR_BOOK: WEB_PBLC_BASE_URL + "/cancelSTRBook",

        // 지점 약관 정보 조회 - 2024.07.02
        GET_BRANCH_TERMS: WEB_PBLC_BASE_URL + "/getBranchTerms",
    },

    MBER: {
        // 스터디룸 예약 등록 - 2023.04.14
        RESERVE_STR_BY_BANK: WEB_MBER_BASE_URL + "/reserveSTRByBank",

        // 스터디룸 예약 변경 - 2023.10.09
        CHANGE_STR_BOOK: WEB_MBER_BASE_URL + "/changeSTRBook",
        // 스터디룸 예약 취소 - 2023.10.09
        CANCEL_STR_BOOK: WEB_MBER_BASE_URL + "/cancelSTRBook",
        
/*        
        // 스터디룸 신규 예약 요청 - 2023.08.18
        REQ_RESERVE_STR: WEB_MBER_BASE_URL + "/strstatus/reqReserveSTR",
        // 스터디룸 신규 예약 확정 - 2023.08.18
        CONFIRM_STR_BOOK: WEB_MBER_BASE_URL + "/strstatus/confirmSTRBook",
*/

        // 문열기 요청 - 2023.08.15
        OPEN_DOOR: WEB_MBER_BASE_URL + "/openDoor",
        // 로그인 정보 조회 - 2023.10.18
        GET_LOGIN_INFO: WEB_MBER_BASE_URL + "/getLoginInfo",
        // 사용자 결제 정보 조회 - 2023.10.28
        GET_PAY_LIST: WEB_MBER_BASE_URL + "/getPayList",
        // 사용자 좌석 이용내역 조회 - 2023.10.28
        GET_SEAT_USE_HIST: WEB_MBER_BASE_URL + "/getSeatUseHist",
        // 스터디룸 이용 상세내역 - 2023.10.28
        GET_STR_USE_DETAIL: WEB_MBER_BASE_URL + "/getStrUseDetail",
        // 사물함 이용 상세내역 - 2023.10.28
        GET_LKR_USE_HIST: WEB_MBER_BASE_URL + "/getLkrUseHist",
    },

}