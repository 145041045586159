/**
 * naver library - 2023.03.29
 */

/**
  네이버 앱 등록 정보
  ID : larala9
  앱이름 : 스터디카페
  2023.03.29
*/
import Com from '@/lib/common'

const clientId = 'lHwPl9YoITQYORwRm3Sz';
//const loginCallback = 'http://localhost:8080/home/loginAccess';
//const loginCallback = location.protocol + "//" + location.host + '/home/loginAccess';
const loginCallback = location.protocol + "//" + location.host + '/loginAccess';
//const signUpCallback = 'http://localhost:8080/home/signUpAccess';
//const signUpCallback = location.protocol + "//" + location.host + '/home/signUpAccess';
const signUpCallback = location.protocol + "//" + location.host + '/signUpAccess';
let naverLogin = null;

export default {
    /** 로그인 콜백 url - 2023.04.09
     * @returns 로그인 콜백 url
     */
    loginCallback() {
      return loginCallback;
    },

    /** 회원가입 콜백 url - 2023.04.09
     * @returns 회원가입 콜백 url
     */
    signUpCallback() {
      return signUpCallback;
    },

    /**
     * 네이버 앱 client-id 조회 - 2023.03.29
     * @returns 네이버 앱 client-id
     */
    getClientId() {
      return clientId;
    },

    /** 네이버 초기화 - 2023.04.06
     * @param {네이버 초기화 설정} paramConfig {callbackUrl, isPopup, loginButton, callbackHandle}
     */
    initNaver(paramConfig) {
      naverLogin = new window.naver.LoginWithNaverId(
        {
          clientId: clientId,
          callbackUrl: paramConfig.callbackUrl,
          isPopup: paramConfig && paramConfig.isPopup ? true : false,
          //loginButton: {color: "green", type: 3, height: 45}
          loginButton: paramConfig? paramConfig.loginButton : undefined,
          callbackHandle: paramConfig? paramConfig.callbackHandle : undefined,
          /* callback 페이지가 분리되었을 경우에 callback 페이지에서는 callback처리를 해줄수 있도록 설정합니다. */
        }
      );

      naverLogin.init();
    },

    /**
     * 네이버 로그인 정보 조회 및 콜백 함수로 전달 - 2023.03.29
     * @param {네이버 로그인 정보 처리 콜백 함수} callbackFunc 
     */
    getLoginStatus(callbackFunc, returnFunc) {

      // 네이버 로그인 된 경우
      if (naverLogin) {
        /* (4) Callback의 처리. 정상적으로 Callback 처리가 완료될 경우 main page로 redirect(또는 Popup close) */
        naverLogin.getLoginStatus(function (status) {

          // callback에 결과 전달
          callbackFunc(status, naverLogin, returnFunc);
        });
      }
      
    },

    /**
     * 네이버 로그인 정보 조회 콜백 함수 - 2023.04.09
     * @param {네이버 로그인 정보} status
     * @param {네이버 로그인 오브젝트} naverLogin
     * @param {화면 return 콜백함수} returnCallback
     */
    readProfile(status, naverLogin, returnCallback) {
      Com.log('naver.readProfile', naverLogin);

      // 상태가 정상적인 경우
      if (status) {

        let naverLoginInfo = {
          //shpId: this.branch.shpId,  // LoginAction에서 처리 - 2023.04.08
          snsTyCd: 'NA',
          snsId: naverLogin.user.id,
          gndrSeCd: naverLogin.user.gender,  // M / F
          mberNam: naverLogin.user.nickname,
          brYr: naverLogin.user.birthyear,
        };

        // 휴대폰 번호 설정 - 2023.04.06
        // naver 010-0000-0000
        if (naverLogin.user.mobile) {
          // dash(-) 삭제
          naverLoginInfo.mpnum = naverLogin.user.mobile.replace(/-/gi, '');
        }

        Com.log('naver.readProfile', naverLoginInfo);

        // 화면 return callback 호출
        returnCallback(naverLoginInfo);
      }
    },    


}