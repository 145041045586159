import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'

import IconBase from '@/components/IconBase.vue'
import IconStopWatch from '@/components/icons/IconStopWatch.vue'
import IconPin from '@/components/icons/IconPin.vue'
import GoogleIcon from '@/components/icons/GoogleIcon.vue'

/* vuetify sass style - 2023.03.24  */
//import './main.scss'
import 'vuetify/styles'

/* vuetify3 date picker - 2023.03.14 */
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

/* google login - 2023.03.27    */
import vue3GoogleLogin from 'vue3-google-login'

/* axios - 2023.03.28   */
import axios from 'axios'
import VueAxios from 'vue-axios'

/* kakao login - 2023.04.06 */
import VueKakaoSdk from 'vue-kakao-sdk'
import kakaoLib from '@/plugins/kakao'
//const apiKey = '79efd976beee78d86768b04e3a222b7e'
//const apiKey = '44c22c478326a2dae429d103600697a7'
// 2023.06.28 - 카카오 키정보 통합
const apiKey = kakaoLib.getClientId();

/* panzoom 좌석현황 zoom 사용 - 2023.04.11 */
//import panZoom from 'vue-panzoom'

loadFonts()

createApp(App).use(store).use(router)
    .use(vuetify)
    .component("IconBase", IconBase)            // component 등록
    .component("IconStopWatch", IconStopWatch)  // component 등록
    .component("IconPin", IconPin)              // component 등록
    .component("GoogleIcon", GoogleIcon)        // google icon 등록
    .component("VeuDatePicker", VueDatePicker)  // vuetify3 date picker component
    .use(vue3GoogleLogin, {                     // google login component 등록
        clientId: '556589072308-hqki4ei457fr5j6r0fcu3h3evrskohdu.apps.googleusercontent.com'
    })
    .use(VueKakaoSdk, {
        apiKey: apiKey
    })          // kakao login component 등록
    .use(VueAxios, axios)       // axios 등록
//    .use(panZoom)         // panZoom 등록
    .mount('#app')
