<template>
  <v-bottom-navigation
    :grow="true"
    :color="color"
    class="mr-5"
    >
    <v-btn v-for="(item, idx) in items" :key="idx" :to="item.to" @click="onClick(item)">
      <v-icon v-if="item.icon != '' && item.icon != undefined">{{item.icon}}</v-icon>
      <div>{{item.title}}</div>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: [ 'items', 'value', 'color' ],

  computed: {
    initValue() {
      return this.value;
    },

    iniitColor() {
      return this.color;
    }
  },

  methods: {
    onClick(item) {
      this.$emit('itemClick', item);
    }
  }
}
</script>