/**
 * kakao library - 2023.03.28
 */

/**
  카카오 앱 등록 정보
  ID : joung9@nate.com
  앱이름 : 스터디카페
  2023.03.29
*/
import Com from '@/lib/common';

const clientId = '44c22c478326a2dae429d103600697a7';
//const redirectUri = 'http://localhost:8080/home/loginAccess';
//const redirectUri = location.protocol + "//" + location.host + '/home/loginAccess';
const redirectUri = location.protocol + "//" + location.host + '/loginAccess';

export default {
    /**
     * 카카오 앱 client-id 조회 - 2023.03.29
     * @returns 카카오 앱 client-id
     */
    getClientId() {
      return clientId;
    },

    /**
     * 카카오 앱 redirect-uri 조회 - 2023.03.29
     * @returns 카카오 앱 redirect-uri
     */
    getRedirectUri() {
      return redirectUri;
    },

    /**
     * 카카오 연결 초기화 - 2023.03.29
     */
    kakaoInit() {
      window.Kakao.init(clientId);
    },

    /** 카카오 로그인 연동 - 2023.04.09
     * 
     * @param {결과 return 콜백} returnCallback 
     */
    loginWithKakao(returnCallback) {

      // 카카오 로그인 - 2023.04.07
      window.Kakao.Auth.login({

        // 로그인 성공의 경우
        success (authObj) {
          Com.log("kakao.loginWithKakao", authObj);

          // 사용자 정보 요청
          window.Kakao.API.request({
            url: '/v2/user/me',
            data: {
              property_keys: ["kakao_account.profile", "kakao_account.gender"]
            },

            // 사용자 정보 조회 성공의 경우
            success: async function (response) {
              Com.log('kakao.loginWithKakao', response);

              // 로그인 정보 구성
              let kakaoLoginInfo = {
                snsTyCd: 'KA',
                snsId: '' + response.id,
                mberNam: response.kakao_account.profile.nickname,
              };

              // 남여 구분 저장
              if (response.kakao_account.gender) {
                kakaoLoginInfo.gndrSeCd =
                  response.kakao_account.gender === 'male' ? 'M' : 'F';
              }

              Com.log('kakao.loginWithKakao', kakaoLoginInfo);

              // 결과값 return
              returnCallback(kakaoLoginInfo);

            }, // End of 사용자 정보 조회 성공의 경우

            // 사용자 정보 조회 실패의 경우
            fail: function (error) {
              Com.log("kakao.loginWithKakao '/v2/user/me' error", error)
              alert('카카오 로그인 정보를 조회 할 수 없습니다.');
            },

          }) // End of 사용자 정보 요청

        }, // End of 로그인 성공의 경우

        // 로그인 실패의 경우
        fail (err) {
          Com.log("kakao.loginWithKakao login error", err)
          alert('카카오 로그인이 실패하였습니다.');
        }

      }); // End of 카카오 로그인      
    },


    /**
     * 카카오 로그인 - 2023.03.29
     */
/*    
    loginWithKakao() {
      window.Kakao.Auth.authorize({
        redirectUri: redirectUri,
      });
    },
*/

    /**
     * 카카오 로그인 연동 후 전달된 code를 이용하여 token 및 사용자 정보 조회 - 2023.03.29
     * @param {vue 페이지 오브젝트} vuePage 
     * @param {카카오 로그인 코드} code 
     * @returns 카카오 로그인 토큰, 사용자 정보
     */
    async getTokenNProfile(vuePage, code) {

      // 결과 데이터 선언
      let retData = {
        result: -1,
        token: null,
        data: null,
        msg: ''
      };

      // header가 제대로 설정되지 않은 경우 접근 오류 발생 함
      const header = {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      };

      // 카카오 토큰 요청 데이터
      const data = {
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: redirectUri,
        code: code,
      };

      try {
        // 카카오 톡 토큰 요청
        const tokenRes = await vuePage.axios.post('https://kauth.kakao.com/oauth/token',
          Object.keys(data)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
          .join('&'),
          { headers: header }
        );

        //Com.log('kakao token res', tokenRes);
        // 토큰 저장
        const token = tokenRes.data.access_token;
        //Com.log("displayToken token", token);

        // 토큰 설정
        window.Kakao.Auth.setAccessToken(token);

        // 토큰 상태 조회
        const statusRes = await window.Kakao.Auth.getStatusInfo();
        //Com.log('kakao login status', statusRes);

        // 토큰 상태가 연결인 경우
        if (statusRes.status == 'connected') {
          Com.log('kakao id', statusRes.user.id);
          Com.log('kakao gender', statusRes.user.kakao_account.gender); // male, female
          Com.log('kakao nickname', statusRes.user.kakao_account.profile.nickname);

          Com.log("kakao token", window.Kakao.Auth.getAccessToken());

          // 결과 데이터 설정
          retData.result = 0;
          retData.token = window.Kakao.Auth.getAccessToken();
          retData.msg = 'success';
          retData.data = {
            snsId: statusRes.user.id,
            snsTyCd: 'KA',
            mberNam: statusRes.user.kakao_account.profile.nickname,
          };

          // gender 설정 - 2023.04.06
          if (statusRes.user.kakao_account.gender) {
            retData.data.gndrSeCd = statusRes.user.kakao_account.gender === 'male' ? 'M' : 'F';
          }
        }
        // 카카오가 연결되지 않은 경우
        else {
          retData.result = 1;
          retData.token = null;
          retData.data = null;
          retData.msg = 'not connected';
          Com.error('not connected');
        }
      }
      // 카카오 토큰 발행 및 사용자 정보 조회 중 예외사항이 발생한 경우
      catch (ex) {
        retData.result = 2;
        retData.token = null;
        retData.data = null;
        retData.msg = ex.message;
        Com.error('get token error', ex);
      }

      return retData;
    },
}