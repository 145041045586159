<template>

<v-app>
  <v-app-bar
    color="deep-purple accent-3"
    dense
    dark
  >
    <!-- -->
<!--
    color="deep-purple accent-3"
    dense
    dark
    app>
    <v-app-bar-nav-icon></v-app-bar-nav-icon>
-->
    <v-btn icon to="/selectBranch">
        <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-toolbar-title>{{ title }}({{ branch.shpNm }})</v-toolbar-title>

<!--
    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>
  -->
    <v-menu
      :left="true"
      :bottom="true"
      offset-y
    >

      <template v-slot:activator="{ props }">

        <v-btn v-if="login.isLogined"
          v-bind="props"
          border
        >
<!--        
          <v-icon>mdi-dots-vertical</v-icon>
-->
<!--
          <v-avatar
            color="brown"
            size="large"
          >
-->          
          <span class="white--text text-h6">
            {{ login.mberNam }}
          </span>
<!--
          </v-avatar>
-->
        </v-btn>
        <v-btn v-else
          @click="signIn"
          border
        >
          <span class="white--text text-h6">로그인</span>
        </v-btn>
      </template>

      <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <!--
              <v-avatar
                color="brown"
              >
                <span class="white--text text-h5">김</span>
              </v-avatar>
            -->              
              <h3>{{ login.mberNam }}</h3>
              <p class="text-caption mt-1">
                {{ login.mpnum }}
              </p>
              <v-divider class="my-3"></v-divider>
<!--              
              <v-row>
                <v-col>
                  <v-btn @click="openDoor()">
                    문열기
                  </v-btn>
                </v-col>
              </v-row>
-->
              <v-row><v-col>
                <v-btn :to="'/' + branch.mblSiteId + '/lockerStatus'">
                  사물함 현황
                </v-btn>
              </v-col></v-row>
              <v-row><v-col>
                <v-btn
                  :to="'/' + branch.mblSiteId + '/myPayList'"
                >
                  결제내역
                </v-btn>
              </v-col></v-row>
              <v-row><v-col>
                <v-btn
                  @click="() => {logout();}"
                >
                  로그아웃
                </v-btn>
              </v-col></v-row>
            </div>
          </v-card-text>
        </v-card>
    </v-menu>
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main class="ma-1">

    <!-- Provides the application the proper gutter -->
    <!--
    <v-container fluid>
    -->

      <!-- If using vue-router -->
      <router-view class="overflow-auto"></router-view>
    <!--
    </v-container>
    -->
  </v-main>

  <bottom-navigation :value="value" :color="color" :items="items" @itemClick="onItemClick"></bottom-navigation>

  <v-footer app>
    <!-- -->
  </v-footer>

  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-text>
        <small>{{dialogMsg}}</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          color="primary"
          @click="dialog = false"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-app>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation.vue';
import Com from '@/lib/common.js';
import URL from '@/lib/url.js';

import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    // 로그인 정보
    const login = computed(() => store.getters.login);
    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    const items = computed(() => 
      [
      // { title: '좌석 현황', to: '/home/seatStatus', icon: 'mdi-history' },
      { title: '좌석 현황', to: '/' + branch.value.mblSiteId + '/seatStatus', icon: 'mdi-history' },
/* 2023.08.25     { title: '스터디룸현황', to: '/home/roomStatus', icon: 'mdi-heart' }, */
      // { title: '스터디룸 현황', to: '/home/strStatus', icon: 'mdi-heart' },
      { title: '스터디룸 현황', to: '/' + branch.value.mblSiteId + '/strStatus', icon: 'mdi-heart' },
      // { title: '스터디룸 예약', to: '/home/reserveRoom', icon: 'mdi-account-details' },
      { title: '스터디룸 예약', to: '/' + branch.value.mblSiteId + '/reserveRoom', icon: 'mdi-account-details' },
/*      { title: '사물함 현황', to: '/home/lockerStatus', icon: 'mdi-heart' },*/
      { title: '문열기', click: 'OPEN_DOOR', icon: 'mdi-door' },
    ]
    );

    return { login, branch, items };
  },

  components: { BottomNavigation },

  data: () => ({
    value: 1,
    color: 'primary',
    dialog: false,
    dialogMsg: '',
    title: '',
  }),

  mounted() {
    //console.log('home', this.$router.currentRoute.value);
    // home으로 들어온 경우에만 default 페이지로 이동 함 - 2023.03.28
    // if (this.$router.currentRoute.value.path == '/home') {
      // this.$router.push(this.items[this.value - 1].to);
      // this.title = this.items[this.value - 1].title;
      // this.$router.push({path: this.items[0].to});

    // home으로 이동해온 경우 seatStatus로 이동
    if (this.$route.name == "home") {
      Com.waitBranchEvtNExeFunc(
        () => {
          Com.log("HomeView mounted go to seatStatus", "/" + this.branch.mblSiteId + "/seatStatus");
          this.$router.push({path: '/' + this.branch.mblSiteId +'/seatStatus'});
          this.title = this.items[0].title;
        }
      )
    }

    // }

  },

  methods: {
    onItemClick(item) {
      if (item) {
        // item에 click이 선언된 경우 호출
        if (item.click) {
          switch(item.click) {
            case 'OPEN_DOOR':
              this.openDoor();
          }
        }
        else {
          this.title = item.title;
        }
      }
    },

    /**
     * 로그인 처리 - 2024.07.09
     * 로그인 후 현재 페이지로 오도록 처리
     */
    signIn() {
      this.$router.push({
        path: '/' + this.branch.mblSiteId + '/login',
        query: {
          fromPage: this.$router.currentRoute.value.name,
          toPage: this.$router.currentRoute.value.name,
        }
      });
    },

    /**
     * 로그아웃 처리 - 2022.12.31
     */
    logout() {
      Com.logout();

      // 현재 페이지가 로그인이 필요한 경우 root로 이동
      if (!this.$router.currentRoute.value.meta.unauthorized) {
        // this.$router.push("/home/seatStatus");
        Com.log("HomeView logout go to seatStatus", "/" + this.branch.mblSiteId + "/seatStatus");
        this.$router.push({path: "/" + this.branch.mblSiteId + "/seatStatus"});
      }
    },

    /**
     * 문열기 - 2023.01.30
     */
    async openDoor() {

      // 로그인이 되어 있는 경우
      if (this.login.isLogined) {

        // 서버 문열기 요청 - 2023.08.15
        const res = await Com.callServer(URL.MBER.OPEN_DOOR);

        // 서버에서 응답을 받은 경우
        if (res !== null) {
          if (res.rStatus === 0) {
            this.dialogMsg = '문열기를 요청하였습니다.';
          }
          else if (res.rStatus === 31) {
            this.dialogMsg = '유효한 좌석 또는 스터디룸이 없습니다.';
          }
          else if (res.rStatus === 32) {
            this.dialogMsg = '문열기 요청이 실패하였습니다.';
          }
          else {
            this.dialogMsg = '문열기 요청중 서버에서 오류가 발생하였습니다.';
          }
        }
        // 서버 응답이 없는 경우
        else {
          this.dialogMsg = '문열기 요청을 위한 서버 접속이 실패하였습니다.';
        } // end of  if (res !== null)
      }
      // 로그인이 안되어 있는 경우
      else {
        this.dialogMsg = '로그인이 필요합니다.';
      } // end of if (this.login.isLogined)

      this.dialog = true;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
</style>
