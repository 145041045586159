<template>
  <v-container class="ma-0 pa-0 locker-container" style="position: absolute;">

    <confirm-view ref="cf"/>

    <div class="p-jc-center"	
      :style="{
                position: 'relative',
                width: pageSize.width+'px',
                height: pageSize.height+'px',
                /*backgroundImage: require('@/assets/floorassign/000120100602.png'), 2023.10.14*/
                backgroundImage: 'url(' + imgPath + ')', /* 이미지 통합 - 2023.06.29 */
                backgroundSize: pageSize.width+'px '+pageSize.height+'px',
                backgroundRepeat: 'no-repeat',
              }"
    >

      <!-- LockerBox.vue -->
      <div v-for="locker in lockers" :key="locker.lkrNo">
        <div class="lockernum pb-1"
          :style="{position: 'absolute',
            left: (locker.posx*1)+'px', top: (locker.posy*1)+'px', zIndex: 1}"
          >{{locker.lkrNam}}</div>

        <v-row
          class="m-0"
          :class="{
            locker: true,
            border: true,
            cantuse: !locker.mpnum,
            mylocker: locker.isMine,
            uselocker: locker.mpnum && !isMine,
          }"
          :style="{
            position: 'absolute', left: locker.posx+'px', top: locker.posy+'px',
            width: locker.lkrWdh+'px', height: locker.lkrHgt+'px'
          }"
          align-h="end">
          <v-col cols="10" class="lockertext m-0" v-if="locker.mpnum">
            <span v-if="isMine" class="lockertitle">내정보</span>
            <br/><span class="lockerdesc">{{endDay}}</span>
          </v-col>
        </v-row>

      </div>
      
    </div>
  </v-container>
</template>

<script>
import Com from '@/lib/common';
import URL from '@/lib/url';
import ConfirmView from '@/components/ConfirmView.vue';

import { computed } from 'vue';
import { useStore } from 'vuex';

const SIZE_WIDTH = 1050;
const SIZE_HEIGHT = 1275;
const ZOOM_LEVEL = 0.8;

export default {
  setup() {
    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    // 로그인 정보 - 2023.10.12
    const login = computed(() => store.getters.login);

    // 배경 이미지 정보 - 2023.06.29
    const imgPath = computed(() => URL.COMMON.ASSET_PATH + "?type=21&shpId=" + branch.value.shpId)

    return { store, branch, login, imgPath };
  },

  components: { ConfirmView },

  data: () => ({
    pageSize: {
      width: 1035,
      height: 562
    },

    bgImg: {},  /* 좌석 배치 배경 이미지 정보 - 2023.10.13 */
    lockers: [
/*      
      {lkrNo: '1', posx: '216', posy: '446', lkrNam: '1', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886'},
      {lkrNo: '2', posx: '216', posy: '491', lkrNam: '2', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '3', lkrBgnDe: '20230318', imgWdh: '1096', pytId: '9355', lkrHgt: '46', lkrEndDe: '20230417', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '3', posx: '216', posy: '536', isuTyCd: '01', existDays: '27', mpnum: '01034705697'}, 
      {lkrNo: '4', posx: '216', posy: '581', lkrNam: '4', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '5', lkrBgnDe: '20230315', imgWdh: '1096', pytId: '9210', lkrHgt: '46', lkrEndDe: '20230411', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '5', posx: '261', posy: '446', isuTyCd: '03', existDays: '21', mpnum: '01029360618'}, 
      {lkrNam: '6', lkrBgnDe: '20230303', imgWdh: '1096', pytId: '9143', lkrHgt: '46', lkrEndDe: '20230402', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '6', posx: '261', posy: '491', isuTyCd: '02', existDays: '12', mpnum: '01029897507', bfrLkrNo: '24'}, 
      {lkrNam: '7', lkrBgnDe: '20221231', imgWdh: '1096', pytId: '8372', lkrHgt: '46', lkrEndDe: '20230331', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '7', posx: '261', posy: '536', isuTyCd: '01', existDays: '10', mpnum: '01041168849'}, 
      {lkrNam: '8', lkrBgnDe: '20230116', imgWdh: '1096', pytId: '8323', lkrHgt: '46', lkrEndDe: '20230326', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '8', posx: '261', posy: '581', isuTyCd: '01', existDays: '5', mpnum: '01040754720'}, 
      {lkrNam: '9', lkrBgnDe: '20230304', imgWdh: '1096', pytId: '9174', lkrHgt: '46', lkrEndDe: '20230403', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '9', posx: '306', posy: '446', isuTyCd: '01', existDays: '13', mpnum: '01064877672'}, 
      {lkrNam: '10', lkrBgnDe: '20230225', imgWdh: '1096', pytId: '9077', lkrHgt: '46', lkrEndDe: '20230326', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '10', posx: '306', posy: '491', isuTyCd: '03', existDays: '5', mpnum: '01041938725'}, 
      {lkrNam: '11', lkrBgnDe: '20230317', imgWdh: '1096', pytId: '9323', lkrHgt: '46', lkrEndDe: '20230415', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '11', posx: '306', posy: '536', isuTyCd: '03', existDays: '25', mpnum: '01051920630'}, 
      {lkrNo: '12', posx: '306', posy: '581', lkrNam: '12', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '13', posx: '351', posy: '446', lkrNam: '13', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '14', lkrBgnDe: '20230302', imgWdh: '1096', pytId: '9125', lkrHgt: '46', lkrEndDe: '20230331', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '14', posx: '351', posy: '491', isuTyCd: '03', existDays: '10', mpnum: '01026255936'}, 
      {lkrNam: '15', lkrBgnDe: '20230309', imgWdh: '1096', pytId: '9228', lkrHgt: '46', lkrEndDe: '20230408', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '15', posx: '351', posy: '536', isuTyCd: '01', existDays: '18', mpnum: '01040323729'}, 
      {lkrNo: '16', posx: '351', posy: '581', lkrNam: '16', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '17', lkrBgnDe: '20230315', imgWdh: '1096', pytId: '9315', lkrHgt: '46', lkrEndDe: '20230414', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '17', posx: '215', posy: '235', isuTyCd: '01', existDays: '24', mpnum: '01030114483'}, 
      {lkrNam: '18', lkrBgnDe: '20230318', imgWdh: '1096', pytId: '9324', lkrHgt: '46', lkrEndDe: '20230401', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '18', posx: '215', posy: '280', isuTyCd: '03', existDays: '11', mpnum: '01033440727'}, 
      {lkrNam: '19', lkrBgnDe: '20230302', imgWdh: '1096', pytId: '9090', lkrHgt: '46', lkrEndDe: '20230327', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '19', posx: '215', posy: '325', isuTyCd: '01', existDays: '6', mpnum: '01090234025'}, 
      {lkrNo: '20', posx: '215', posy: '370', lkrNam: '20', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '21', posx: '260', posy: '235', lkrNam: '21', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '22', lkrBgnDe: '20230306', imgWdh: '1096', pytId: '9180', lkrHgt: '46', lkrEndDe: '20230404', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '22', posx: '260', posy: '280', isuTyCd: '03', existDays: '14', mpnum: '01063642535'}, 
      {lkrNam: '23', lkrBgnDe: '20230225', imgWdh: '1096', pytId: '9084', lkrHgt: '46', lkrEndDe: '20230327', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '23', posx: '260', posy: '325', isuTyCd: '01', existDays: '6', mpnum: '01025440182'}, 
      {lkrNo: '24', posx: '260', posy: '370', lkrNam: '24', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '25', lkrBgnDe: '20230302', imgWdh: '1096', pytId: '9134', lkrHgt: '46', lkrEndDe: '20230401', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '25', posx: '305', posy: '235', isuTyCd: '02', existDays: '11', mpnum: '01028321091', bfrLkrNo: '18'}, 
      {lkrNam: '26', lkrBgnDe: '20230223', imgWdh: '1096', pytId: '9041', lkrHgt: '46', lkrEndDe: '20230324', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '26', posx: '305', posy: '280', isuTyCd: '03', existDays: '3', mpnum: '01028725228', bfrLkrNo: '31'}, 
      {lkrNo: '27', posx: '305', posy: '325', lkrNam: '27', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '28', posx: '305', posy: '370', lkrNam: '28', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '29', posx: '212', posy: '43', lkrNam: '29', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '4', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '30', posx: '212', posy: '89', lkrNam: '30', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '3', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '31', posx: '212', posy: '135', lkrNam: '31', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '32', posx: '212', posy: '181', lkrNam: '32', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '33', lkrBgnDe: '20230317', imgWdh: '1096', pytId: '9326', lkrHgt: '47', lkrEndDe: '20230416', lkrWdh: '47', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '33', posx: '258', posy: '43', isuTyCd: '02', existDays: '26', mpnum: '01027161565', bfrLkrNo: '30'}, 
      {lkrNam: '34', lkrBgnDe: '20221231', imgWdh: '1096', pytId: '8353', lkrHgt: '47', lkrEndDe: '20230330', lkrWdh: '47', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '34', posx: '258', posy: '89', isuTyCd: '03', existDays: '9', mpnum: '01085847606', bfrLkrNo: '18'}, 
      {lkrNo: '35', posx: '258', posy: '135', lkrNam: '35', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '36', lkrBgnDe: '20230313', imgWdh: '1096', pytId: '9281', lkrHgt: '47', lkrEndDe: '20230328', lkrWdh: '47', flr: '1', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '36', posx: '258', posy: '181', isuTyCd: '01', existDays: '7', mpnum: '01033109291'}, 
      {lkrNam: '37', lkrBgnDe: '20230312', imgWdh: '1096', pytId: '9227', lkrHgt: '47', lkrEndDe: '20230410', lkrWdh: '47', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '37', posx: '536', posy: '43', isuTyCd: '03', existDays: '20', mpnum: '01049335932'}, 
      {lkrNo: '38', posx: '536', posy: '89', lkrNam: '38', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '3', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '39', posx: '536', posy: '135', lkrNam: '39', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '40', posx: '536', posy: '181', lkrNam: '40', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '41', posx: '582', posy: '43', lkrNam: '41', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '4', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '42', posx: '582', posy: '89', lkrNam: '42', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '3', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '43', posx: '582', posy: '135', lkrNam: '43', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '44', posx: '582', posy: '181', lkrNam: '44', imgWdh: '1096', lkrHgt: '47', lkrWdh: '47', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '45', lkrBgnDe: '20230305', imgWdh: '1096', pytId: '9170', lkrHgt: '46', lkrEndDe: '20230403', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '45', posx: '491', posy: '234', isuTyCd: '03', existDays: '13', mpnum: '01026582645', bfrLkrNo: '44'}, 
      {lkrNam: '46', lkrBgnDe: '20230304', imgWdh: '1096', pytId: '9175', lkrHgt: '46', lkrEndDe: '20230403', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '46', posx: '491', posy: '279', isuTyCd: '01', existDays: '13', mpnum: '01064124982'}, 
      {lkrNo: '47', posx: '491', posy: '324', lkrNam: '47', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '48', posx: '491', posy: '369', lkrNam: '48', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '49', lkrBgnDe: '20230223', imgWdh: '1096', pytId: '9058', lkrHgt: '46', lkrEndDe: '20230325', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '49', posx: '536', posy: '234', isuTyCd: '02', existDays: '4', mpnum: '01088342443', bfrLkrNo: '52'}, 
      {lkrNam: '50', lkrBgnDe: '20230220', imgWdh: '1096', pytId: '9022', lkrHgt: '46', lkrEndDe: '20230421', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '50', posx: '536', posy: '279', isuTyCd: '02', existDays: '31', mpnum: '01050695361', bfrLkrNo: '48'}, 
      {lkrNo: '51', posx: '536', posy: '324', lkrNam: '51', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '52', posx: '536', posy: '369', lkrNam: '52', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '53', lkrBgnDe: '20230308', imgWdh: '1096', pytId: '9168', lkrHgt: '46', lkrEndDe: '20230406', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '53', posx: '581', posy: '234', isuTyCd: '03', existDays: '16', mpnum: '01083389691'}, 
      {lkrNo: '54', posx: '581', posy: '279', lkrNam: '54', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '3', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNam: '55', lkrBgnDe: '20230220', imgWdh: '1096', pytId: '9023', lkrHgt: '46', lkrEndDe: '20230322', lkrWdh: '46', flr: '2', gndrUsCd: 'FM', imgHgt: '886', lkrNo: '55', posx: '581', posy: '324', isuTyCd: '02', existDays: '1', mpnum: '01037147159', bfrLkrNo: '47'}, 
      {lkrNo: '56', posx: '581', posy: '369', lkrNam: '56', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '1', gndrUsCd: 'FM', imgHgt: '886'}, 
      {lkrNo: '57', posx: '774', posy: '365', lkrNam: '57', imgWdh: '1096', lkrHgt: '46', lkrWdh: '46', flr: '4', gndrUsCd: 'FM', imgHgt: '886'}, 
*/      
    ]
  }),

  mounted() {
    // 직접 호출된 경우 지점 정보 조회 보다 먼저 호출됨 - 2023.04.12
    // 지점 정보가 설정될 때까지 기다림
    Com.waitBranchEvtNExeFunc(this.init);
  },

  methods: {


    /** 사물함 현황 조회 - 2023.10.13
     */
    async init() {

      const param = { shpId: this.branch.shpId};

      // 서버 사물함 현황 조회
      const res = await Com.callServer(URL.PBLC.FETCH_LOCKER_STATUS, param);

      // 사물함 현황을 성공적으로 조회한 경우
      if (res !== null) {
        if (res.rStatus === 0) {
          // return
          //  - retObject : { imgPath, imgWdh, imgHgt }
          //  - retList : { lkrNo, lkrNam, posx, posy, lkrWdh, lkrHgt, flr, gndrUsCd, mpnum, pytId, lkrBgnDe, lkrEndDe, bfrLkrNo, isuTyCd, existDays }
          this.lockers = res.retList;

          this.lockers.forEach((locker) => {
            locker.posx = Math.floor(locker.posx * ZOOM_LEVEL);
            locker.posy = Math.floor(locker.posy * ZOOM_LEVEL);
            locker.lkrWdh = Math.floor(locker.lkrWdh * ZOOM_LEVEL);
            locker.lkrHgt = Math.floor(locker.lkrHgt * ZOOM_LEVEL);
            // 내 사물함 여부 설정 - 2023.10.12
            locker.isMine = (this.login.isLogined && this.login.mpnum === locker.mpnum);
          });

          // 배경 이미지 설정
          this.bgImg = {
            path:  res.retObject.stImgPath + res.retObject.imgPath,
            height: res.retObject.imgHgt * ZOOM_LEVEL,
            width: res.retObject.imgWdh * ZOOM_LEVEL,
          };

          const WIDTH = SIZE_WIDTH * ZOOM_LEVEL;
          const HEIGHT = (SIZE_HEIGHT - 100) * ZOOM_LEVEL;
          const imgRatio = this.bgImg.height / this.bgImg.width;
          const bgRatio = HEIGHT / WIDTH;

          // 세로의 비율이 큰경우
          if (imgRatio >= bgRatio) {
            this.pageSize.height = HEIGHT;
            this.pageSize.width = Math.floor( this.bgImg.width * HEIGHT / this.bgImg.height);
          }
          // 가로의 비율이 큰경우
          else {
            this.pageSize.width = WIDTH;
            this.pageSize.height = Math.floor(this.bgImg.height * WIDTH / this.bgImg.width);
          }
        }
        else {
          Com.log("LockerStatus.init", res.errorMsg, "error");
          //alert("사물함현황 조회중 오류가 발생했습니다.(" + res.errorMsg + ")");
          this.$refs.cf.msgOk("사물함현황 조회중 오류가 발생했습니다.(" + res.errorMsg + ")", "error");
        }
      }
    },


  },
/*  
  // [생명 주기 : 라이프 사이클]
  beforeCreate() {console.log("설 명 : 인스턴스 초기화 준비");},
  created() {console.log("설 명 : 인스턴스 생성 완료");},
  beforeMount() {console.log("설 명 : DOM 렌더링 준비");},
  mounted() {console.log("설 명 : DOM 렌더링 완료");},
  beforeUpdate() {console.log("설 명 : DOM 상태 및 데이터 변경 시작");},
  updated() {console.log("설 명 : DOM 상태 및 데이터 변경 완료");},
  beforeUnmount() {console.log("설 명 : 인스턴스 마운트 해제 준비");},
  unmounted() {console.log("설 명 : 인스턴스 마운트 해제 완료");},
*/  
}
</script>

<style scoped>
  .locker-container {
    height: 600px;
    overflow: auto;
  }
	.locker {
		background-color: #ffffff;
	}
	.border {
		border: 1px solid black;
	}
	.locker1 {
		background-color: white;
		border: 1px solid red;
		opacity: 1.0;
	}
	.locker2 {
		background-color: green;
		border: 1px solid red;
		opacity: 1.0;
	}
	.locker3 {
		background-color: gray;
		border: 1px solid red;
		opacity: 1.0;
	}
  .cantuse{
		background-color: gray;
		border: 1px solid gray;
		opacity: 1.0;
  }
	.oldlocker {
		background-color: blue;
		border: 1px solid red;
		opacity: 1.0;
	}
	.selected {
		background-color: red;
		border: 1px solid green;
		opacity: 1.0;
	}
  .mylocker {
    background-color:blueviolet;
    color: floralwhite;
  }
  .uselocker {
    background-color:coral;
    color: floralwhite;
  }
  .lockertext {
    text-align: end;
    padding: 12px 0px 0px 0px;
  }
  .lockertitle {
    font: 9px "verdana";
    padding: 0px;
  }
  .lockerdesc {
    font: 11px "verdana";
    padding: 0px;
  }
  .lockernum {
    font: 11px "verdana";
    padding: 2px 3px;
  }

</style>