<template>
  <!-- STRStatus.vue -->
  <div id="strstatus" class="table-container">

    <confirm-view ref="cf"/>

    <table v-if="isInitialized" :key="tableKey" bordered>

      <t-header
        :isChangeReserveMode="changeReserve !== undefined"
        :stNo="stNo"
        :date="date"
        @fetchSTRBookList="getSTRListWithEnv"
        @cancelReqChangeSelect="cancelReqChangeSelect"
        ref="header"
      ></t-header>

      <!--신규 예약 bgn - 2023.05.04-->
      <v-dialog
        v-model="newReserveModal"
        width="auto"
        class="pa-0"
        :persistent="true"
      >
        <v-card class="pa-0">
          <v-card-item class="pa-0">

            <new-reserve
              :item="reserveInfo"
              @closeModal="newReserveModal = false, refreshList()"
              @refreshSTRBookList="getSTRList"
              @clickBank="payByBank"
            ></new-reserve>

          </v-card-item>
        </v-card>
      </v-dialog>
      <!--신규 예약 end - 2023.05.04-->

      <!-- 구매 약관(비로그인 정보입력) 팝업 begin - 2024.07.08 -->
      <v-dialog
        v-model="buyAgreementDialog"
        width="auto"
        class="pa-0"
      >
        <v-card class="pa-0">
          <v-card-item class="pa-0">
            <buy-agreement
              :default-buy-mpnum="buyerInfo.buyMpnum"
              @agree="agreeForBuy"
            ></buy-agreement>           
          </v-card-item>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              @click="buyAgreementDialog = false, newReserveModal = true"
            >
              취소
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 구매 약관(비로그인 정보입력) 팝업 end - 2023.07.08 -->

      <!-- 무통장 입금 팝업 begin - 2023.09.04 -->
      <v-dialog
        v-model="bankDialog"
        width="auto"
        class="pa-0"
        :persistent="true"
      >
        <v-card class="pa-0">
          <v-card-item class="pa-0">
            <reserve-by-bank
              :items="reserveList"
              :totalAmt="totalAmt"
              :buyer-info="buyerInfo"
              @completed="bankDialog = false, getSTRList()"
            ></reserve-by-bank>           
          </v-card-item>
          <v-card-actions>
            <v-btn color="primary" block @click="bankDialog = false, newReserveModal = true">이전으로</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 무통장 입금 팝업 end - 2023.09.04 -->

      <!-- 비로그인 예약 비번조회 팝업 bgn - 2024.07.05-->
      <v-dialog
        v-model="buyPwdCheckModal"
        width="auto"
        class="pa-0"
        :persistent="true"
      >
        <v-card class="pa-0">
          <v-card-item class="pa-0">

            <input-buy-pwd
              :item="reserveInfo.item"
              @ok="buyPwdCheckModal = false, changeReserveModal = true"
              @cancel="buyPwdCheckModal = false, getSTRList()"
            ></input-buy-pwd>

          </v-card-item>
        </v-card>
      </v-dialog>
      <!-- 비로그인 예약 비번조회 팝업 end - 2024.07.05-->

      <!--예약 조회 및 변경 bgn - 2023.05.04-->
      <v-dialog
        v-model="changeReserveModal"
        width="auto"
        class="pa-0"
        :persistent="true"
      >
        <v-card class="pa-0">
          <v-card-item class="pa-0">

            <change-reserve
              :item="reserveInfo"
              :canclLimitTim="canclLimitTim"
              :canclLimitHour="canclLimitHour"
              :changeSelectInfo="changeSelectInfo"
              @reqChangeSelect="reqChangeSelect"
              @cancelChangeReserve="cancelChangeReserve"
              @changeComplete="changeComplete"
            ></change-reserve>
            
          </v-card-item>
        </v-card>
      </v-dialog>
      <!--예약 조회 및 변경 end - 2023.05.04-->

      <tbody :key="tbodyKey">
        <tr
          v-for="(time, rIdx) in timeLine"
          :key="rIdx"
          class="pa-0 align"
          :style="{
            height: (time.hourAt === 'Y' ? height.on : height.off) + 'px' 
          }"
        >
          <!-- 시간 표시 begin - 2023.04.26 -->
          <td
            v-if="time.hourAt === 'Y'"
            rowspan="2"
            class="timeTitle text-center align-middle"
          >{{ time.mngTmNm }}</td>

          <td
            v-else-if="time.usAt === 'N'"
            :colspan="secondHeaders.length + 1"
            class="unused"
          >영업외 시간</td>
          <!-- 시간 표시 end - 2023.04.26 -->

          <!-- 예약정보 표시 begin - 2023.04.26 -->
          <template v-if="time.usAt === 'Y'">
            <template v-for="(header, cIdx) in secondHeaders">

              <table-cell
                v-if="isDraw(header.id, time)"
                :key="cIdx"
                :header="header"
                :item="getCellData(header.id, time)"
                :changeReserve="changeReserve"
                :bookInfo="strBookInfo[header.id]"
                :row="rIdx"
                :col="cIdx"
                :curTime="curTime"
                @checkBuyPwd="checkBuyPwd"
                @viewReserve="viewReserve"
                @newReserve="newReserve"
                @changeSelect="changeSelect"
              >
              </table-cell>

            </template>
          </template>
          <!-- 예약정보 표시 end - 2023.04.26 -->

        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableCell from '@/components/TableCell.vue';
import THeader from '@/components/THeader.vue';
import NewReserve from '@/components/NewReserve.vue';
import ChangeReserve from '@/components/ChangeReserve.vue';
import ReserveByBank from '@/components/ReserveByBank.vue';
import ConfirmView from '@/components/ConfirmView.vue';
import InputBuyPwd from '@/components/InputBuyPwd.vue';
import BuyAgreement from '@/components/BuyAgreement.vue';

import Com from '@/lib/common';
import URL from '@/lib/url';

import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  name: 'STRStatus',
  components: { THeader, TableCell, NewReserve, ChangeReserve, ReserveByBank, ConfirmView, InputBuyPwd, BuyAgreement },
  
  setup() {
    const store = useStore();

    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);

    // 로그인 정보
    const login = computed(() => store.getters.login);

    // 페이지 파라미터 정보 조회 - 2023.08.20
    const param = computed(() => store.getters.getPageParam("strstatus"));

    // 스터디룸 관련 저장소 정보 조회 - 2023.08.21
    const strstatus = computed(() => store.getters.getSTRStatusInfo());

    return { store, branch, login, param, strstatus };
  },

  data() {
    return {
      tableKey: 0,        // 날짜 포함 전체 새로고침을 위한 인덱스 - 2023.05.09
      tbodyKey: 0,        // 예약내용 새로고침을 위한 인덱스 - 2023.05.09

      changeReserve: undefined,  // 변경시 이전 예약 정보 - 2023.05.01
      changeSelectInfo: undefined,   // 예약변경시 변경 선택 정보 - 2023.05.07

      height: {
        on: 16,
        off: 16,
      },
      strBookInfo: {},  // 조회된 예약 목록 - 2023.05.03

      reserveInfo: {},  // 선택된 예약정보 - 2023.05.03
      reserveList: [],  // 무통장입금 규격에 맞춰 예약 목록을 전달 함 - 2023.09.05
      totalAmt: 0,      // 무통장입금 규격에 맞춰 예약 금액을 전달 함 - 2023.09.05

      secondHeaders: [],

      newReserveModal: false,     // 신규 예약 모달 표시여부 - 2023.05.03
      changeReserveModal: false,  // 예약 변경 모달 표시여부 - 2023.05.04
      bankDialog: false,          /* 무통장 입금 예약 dialog - 2023.09.04  */
      buyAgreementDialog: false,  /* 구매 동의 용 dialog - 2024.07.08  */
      buyPwdCheckModal: false,    /* 비회원 구매 패스워드 입력 화면 모달 표시 여부 - 2024.07.04 */
      selectedDay: undefined,     // 선택된 일자 - 2023.05.06
      selectedStudyRoom: undefined, // 선택된 스터디룸 - 2023.05.06
      curTime: undefined,         // 30분단위 서버 시간 (yyyymmddhhmi) - 2023.05.07
      canclLimitTim: undefined,   // 취소 가능 시간 (yyyymmddhhmi) - 2023.05.09
      canclLimitHour: undefined,  // 예약시작시간 기준 취소 가능 시간 (hour) - 2023.05.09

      stNo: undefined,            // theader 전달 파라미터 - 2023.05.10
      date: undefined,            // theader 전달 파라미터 - 2023.05.10
      isParamProcessed: false,    // theader 전달 파라미터 처리완료 여부 - 2023.05.10

      buyerInfo:                  /* 비회원 구매시 구매자 정보 - 2024.07.08 */
      { buyMpnum: '', buyPwd: '' },
    }
  },

  created() {
    Com.log("strstatus.mounted");
    Com.log("strstatus", this.strstatus);
  },

  mounted() {
    //const param = this.$store.state.strstatus.initParameter;
    //Com.log("strstatus.mounted initParameter", param);

    // 지점이 변경된 경우 초기화 여부 set false - 2023.09.12
    if (this.strstatus.shpId !== this.branch.shpId) {
      this.store.commit("setInitComplated", false);
    }

    // 초기화 되지 않은 경우 초기화 수행 - 2023.09.12
    if (!this.strstatus.isInitialized) {
      // 스터디룸 목록 조회 및 기준일 설정 - 2023.08.27
      this.store.dispatch("initSTRInfo");
    }

    // 초기파라미터가 있는 경우 파라미터 전달 - 2023.05.10
    //if (this.param.isValid) {
    if (this.param) {
      this.stNo = "" + this.param.stNo;
      this.date = "" + this.param.date;
      //this.$store.commit("strstatus/clearInitParameter");
      // 파라미터 지우기
      this.store.commit("clearPageParam", "strstatus");
    }
    // 파라미터 초기화 완료
    this.isParamProcessed = true;

    // 안내문구 표시되지 않은 경우에만 표시 - 2023.05.11
    if (!this.strstatus.isGuideAlertNoted) {

      // 신규 예약 안내 메시지 표시
      this.$refs.cf.toast("스터디룸을 예약하시려면 비어있는 스터디룸이름과 시간의 셀을 선택해 주세요.");

      // 안내완료로 설정 - 2023.08.21
      this.$store.commit("setGuideAlertNoted");
    }
  },

  computed: {

    DISP_MODE: {
      get: function() {return this.strstatus.DISP_MODE;}
    },

    displayMode: {
      get() {return this.strstatus.displayMode;}
    },

    // 스터디룸 운영시간 - 2023.08.21
    timeLine() {
      return this.strstatus.timeLine;
    },

    // 초기화 완료 여부 - 2023.08.21
    isInitialized() {
      Com.log("STRStatus strstatus.isInitialized", this.strstatus.isInitialized);
      Com.log("STRStatus isParamProcessed", this.isParamProcessed);

      return this.strstatus.isInitialized && this.isParamProcessed;
    },
  },

  methods: {

    /**
     * 구매동의 완료 이벤트 핸들러 - 2024.07.08
     * @param {비로그인 구매자 정보} buyerInfo 
     */
     agreeForBuy(buyerInfo) {
      Com.log("STRStatus.agreeForBuy", buyerInfo);

      this.buyerInfo = buyerInfo;

      // 구매 동의 창닫고 결제 정보 창 열기
      this.buyAgreementDialog = false;
      this.bankDialog = true;
    },

    /** 
     * 무통장 입금 예약 정보 설정 - 2023.09.05 
     * 2024.07.08 - 비로그인 예약을 위한 로직 추가
     */
    async payByBank(reserveItem) {
      Com.log("STRStatus.payByBank reserveItem", reserveItem);
      // 예약 정보를 리스트로 구성
      this.reserveList = [];
      this.reserveList.push(reserveItem);
      // 전체 가격 설정 (1건 예약)
      this.totalAmt = reserveItem.prdtAmt * 1;

      // 로그인 여부 확인 - 2024.07.08
      const isLogined = await Com.isLogined();

      // 로그인 된 경우
      if (isLogined) {
        // 무통장 입금 모달 표시
        this.newReserveModal = false;
        this.bankDialog = true;
      }
      // 비로그인 구매의 경우 - 2024.07.08
      else {
        // 구매동의 창 화면 표시 - 2024.07.08
        this.newReserveModal = false;
        this.buyAgreementDialog = true;
      }

    },

    /* 키값을 증가 시켜 화면을 refresh 함 - 2023.08.21 */
    /** header를 포함한 전체 새로고침 - 2023.08.21
     * 
     */
    refreshAll() {
      this.tableKey++;
    },

    /** 예약 목록 새로고침 - 2023.08.21
     * 
     */
    refreshList() {
      this.tbodyKey++;
    },

    /**
     * 
     * @param {스터디룸 또는 일자} headerId 
     * @param {시간-HHMI} time 
     */
    isDraw(headerId, time) {
      // 해당 ID(일자 혹은 스터디룸)에 대한 예약이 있는 경우
      if (this.strBookInfo[headerId]) {

        // 해당 시간대에 예약이 있는 경우
        if(this.strBookInfo[headerId][time.mngTmCd]) {
          const curItem = this.strBookInfo[headerId][time.mngTmCd];

          // 예약수정 셀 선택의  경우 - 2023.05.10
          // - 시작시간을 제외한 나머지 셀은 선택이 가능해야 함
          if (this.changeReserve) {

            const changeItem = this.changeReserve.item;

            // 현재 변경 중인 예약의 경우 모든 셀 표시
            if (curItem.pytId === changeItem.pytId &&
              curItem.bookSrno === changeItem.bookSrno) {

              Com.log("isDraw curItem === changeItem");

              return true;              
            }
          }

          // 시작시간 셀에만 셀을 표시함
          return curItem.bgnSlotAt === 'Y';
        }

        // 해당 시간대 예약이 없는 경우
        else {
          return true;
        }

      }

      // 해당 ID(일자 혹은 스터디룸)에 대한 예약이 없는 경우
      else {
        return true;
      }
    },

    /** 셀의 정보 조회 - 2023.08.22
     * @param {header ID(룸번호 또는 일자)} headerId 
     * @param {좌측 시간표시 오브젝트} time 
     */
    getCellData(headerId, time) {
      //Com.log("getCellData " + headerId + ", " + time.mngTmCd, this.strBookInfo[headerId]);

      if (this.strBookInfo[headerId] && this.strBookInfo[headerId][time.mngTmCd]) {
        //Com.log("getCellData " + headerId + ", " + time.mngTmCd, this.strBookInfo[headerId][time.mngTmCd]);

        const curItem = this.strBookInfo[headerId][time.mngTmCd];

        // 예약수정 셀 선택의  경우 - 2023.08.22
        // - 시작시간을 제외한 나머지 셀은 선택이 가능해야 함
        if (this.changeReserve) {

          const changeItem = this.changeReserve.item;

          Com.log("getCellData curItem", curItem);
          Com.log("getCellData changeItem", changeItem);

          // 현재 변경 중인 예약의 경우 모든 셀 표시
          if (curItem.pytId === changeItem.pytId &&
            curItem.bookSrno === changeItem.bookSrno) {

            Com.log("getCellData curItem === changeItem");

            if (curItem.bgnSlotAt === 'Y') {
              return curItem;
            }
            else {
              return undefined;
            }
          }
        }
       
        return curItem;
      }
      
      return undefined;
    },

    /** 조회 정보 저장 및 스터디룸 예약리스트 조회 - 2023.08.22
     * 
     */
    getSTRListWithEnv(headerInfo) {

      Com.log("getSTRList", headerInfo);

      this.secondHeaders = headerInfo.secondHeaders;
      this.selectedDay = headerInfo.selectedDay;
      this.selectedStudyRoom = headerInfo.selectedStudyRoom;

      this.getSTRList();
    },

    /** 스터디룸 예약리스트 조회 - 2023.08.22
     * 
     */
    async getSTRList() {

      const selectedDay = this.selectedDay;
      const selectedStudyRoom = this.selectedStudyRoom;

      // 서버전송 파라미터 정의
      let param = {
        shpId: this.branch.shpId,
        stNoList: [],
        bgnUsDe: null,
        endUsDe: null,
      };

      // 컬럼에 스터디룸이 표시되는 경우
      if (this.displayMode === this.DISP_MODE.ROOM_MODE) {
        // 조회할 스터디룸
        const headers = this.secondHeaders;
        headers.forEach(studyRoom =>  param.stNoList.push(studyRoom.stNo));

        // 조회 기간
        param.bgnUsDe = param.endUsDe = selectedDay.date;
      }
      // 컬럼에 일자가 표시되는 경우
      else {
        // 조회할 스터디룸
        param.stNoList.push(selectedStudyRoom.stNo);

        // 조회 기간
        const headers = this.secondHeaders;
        param.bgnUsDe = headers[0].date;
        param.endUsDe = headers[headers.length-1].date;
      }

      Com.log("getSTRList.param");
      Com.log(param);

			// 스터디룸 목록 및 기준일자 조회
      const res = await Com.callServer(URL.PBLC.FETCH_STR_BOOK_LIST, param);

      if (res != null && res.rStatus === 0) {
          let strBookInfo = {};

          if (res.retList) {

            // 컬럼에 스터디룸이 표시되는 경우
            if (this.displayMode === this.DISP_MODE.ROOM_MODE) {

              for (let idx = 0; idx < res.retList.length; ++idx) {
                const stNo = res.retList[idx].stNo;
                const mngTmCd = res.retList[idx].mngTmCd;

                // 스터디룸 오브젝트 정의
                if (!strBookInfo[stNo]) {
                  strBookInfo[stNo] = {};
                }

                // 스터디룸별 시간대별 예약정보 구성
                strBookInfo[stNo][mngTmCd] = res.retList[idx];
              } // end of for (let idx = 0; idx < res.retList.length; ++idx) {

            }

            // 컬럼에 일자가 표시되는 경우
            else {
              for (let idx = 0; idx < res.retList.length; ++idx) {
                const usDe = res.retList[idx].usDe;
                const mngTmCd = res.retList[idx].mngTmCd;

                // 일단위 오브젝트 정의
                if (!strBookInfo[usDe]) {
                  strBookInfo[usDe] = {};
                }

                // 일자별 시간대별 예약정보 구성
                strBookInfo[usDe][mngTmCd] = res.retList[idx];

              } // end of for (let idx = 0; idx < res.retList.length; ++idx) {

            } // end of if (this.displayMode === this.DISP_MODE.ROOM_MODE) {

            // 구성된 예약정보 저장
            this.strBookInfo = strBookInfo;

          } // end of if (res.retList) {

          // 현재 시간 저장 - 2023.08.21
          if (res.retObject) {
            // 현재 시간
            this.curTime = res.retObject.today + res.retObject.hour + res.retObject.min;
            // 취소 가능 시간 - 2023.08.21
            this.canclLimitTim = res.retObject.canclLimitTim;
            // 시작시간 기준 취소 가능 시간(hour) - 2023.08.21
            this.canclLimitHour = res.retObject.canclLimitHour * 1;
          }

          // 예약 목록 새로 고침 - 2023.08.21
          this.refreshList();

          Com.log("getSTRList.strBookInfo", this.strBookInfo);
      }
      // 스터디룸 정보 조회가 실패한 경우
      else {
        Com.log("getSTRList 조회실패", res);
        this.$refs.cf.toast("스터디룸 정보 조회가 실패하였습니다. 관리자에게 문의해주세요.");
      }

      // 신규예약 팝업 닫기 - 2023.08.22
      this.newReserveModal = false
    },

    /** 신규예약을 위해 셀 선택시 발생하는 이벤트 핸들러 - 2023.08.21
     * Cell 에서 예약 가능 셀 선택 시 호출됨
     * 2024.03.22 - 비회원 예약을 위한 로그인 선택 기능 추가
     * @param {신규예약시 셀선택 정보} selectInfo 
     */
    async newReserve(selectInfo) {

      const isLogined = await Com.isLogined();

      // 로그인이 안되어 있는 경우
      if (!isLogined) {

        // 로그인 진행 확인 - 2023.10.08
        // const res = await this.$refs.cf.msgConfirm("스터디룸 예약은 로그인이 필요합니다. 로그인 하시겠습니까?",
        // 로그인 예약 or 비로그인 예약에 대한 선택 - 2024.03.22
        const res = await this.$refs.cf.msgConfirm("스터디룸 예약을 위해 로그인 하시겠습니까?",
           'info', '로그인', '비로그인 예약');
      
        Com.log("STRStatus.newReserve", res);

        if (res == null) {
          // 창닫기 이벤트 - 2024.07.04
          // 선택 취소를 위한 초기화
          this.refreshList();
        }
        else if (res) {
          //this.$router.push({path: '/' + this.shpId + '/login', query: {fromPage: 'STRStatus', toPage: 'STRStatus'}});
          // 로그인 오류 수정 - 2024.03.25
          this.$router.push({path: '/' + this.branch.mblSiteId + '/login', query: {fromPage: 'STRStatus', toPage: 'STRStatus'}});
        }
        else {
          //this.refreshList();
          // 비로그인 예약 진행 - 2024.03.22
          this.reserveInfo = selectInfo;
          this.newReserveModal = true;
        }
        return;
      }

      // 로그인 되어 있는 경우
      else {
        
        this.reserveInfo = selectInfo;
        this.newReserveModal = true;
        
      }
      
    },

    /**
     * 비로그인 예약 조회를 위한 구매 패스워드 이벤트 핸들러 - 2023.08.21
     * @param {선택된 셀의 기존 예약 정보} bookInfo 
     */
    checkBuyPwd(bookInfo) {
      this.reserveInfo = bookInfo;
      this.buyPwdCheckModal = true;
    },

    /** 기존 예약 상세 조회를 위한 셀선택 이벤트 핸들러 - 2023.08.21
     * Cell 에서 기존 예약 선택 시 호출됨
     * @param {선택된 셀의 기존 예약 정보} bookInfo 
     */
    viewReserve(bookInfo) {
      //Com.log("viewReserve.bookInfo", bookInfo);
      this.reserveInfo = bookInfo;
      this.changeReserveModal = true;
    },

    /** 예약 변경을 위해 시간 셀 선택 요청 이벤트 핸들러 - 2023.08.21
     * ChangeReserve 에서 변경내용 선택 시 호출됨
     * @param {예약변경을 위해 전달된 기존 예약 정보} bookInfo 
     */
    reqChangeSelect(bookInfo) {
      this.changeReserve = bookInfo;
      this.changeReserveModal = false;
      this.refreshList();

      // 변경 취소 안내 메시지 표시
      this.$refs.cf.msgOk("예약 변경 중 취소하시려면 제목하단의 '변경취소' 버튼을 선택해 주세요.");
    },

    /** 예약변경 취소 이벤트 핸들러 - 2023.08.21
     * ChangeReserve 에서  예약변경 취소시 호출됨
     */
    cancelReqChangeSelect() {
      this.changeReserve = undefined;
      this.changeReserveModal = true;
      this.refreshAll();
    },

    /** 예약변경시 변경내용 셀 선택 이벤트 핸들러 - 2023.08.21
     * 예약변경 룸/일시 셀 선택시 호출 됨 
     */
    changeSelect(selectInfo) {
      Com.log("changeSelect.selectInfo", selectInfo);
      this.changeReserve = undefined;
      this.refreshList();

      this.changeSelectInfo = selectInfo;
      this.changeReserveModal = true;
    },

    /** 예약변경 중 변경 취소 이벤트 핸들러 - 2023.08.21
     * ChangeReserve 에서 예약변경 취소 시 호출 됨
     */
    cancelChangeReserve() {
      this.$refs.header.cancelMode();
      this.changeSelectInfo = undefined;
      this.changeReserveModal = false;
      //this.refreshList();
      this.refreshAll();
    },

    /** 예약 변경 및 예약 취소 완료 이벤트 핸들러 - 2023.08.21
     * ChangeReserve 에서 예약취소 완료, 예약변경 완료 시 호출됨
     */
    changeComplete() {
      this.getSTRList();
      this.changeReserveModal = false;
      this.refreshAll();
    }

  }
}
</script>

<style scoped>
table.table-bordered > thead > tr > th{
  border: 1px solid white;
}
.table-container {
  height: 540px;
  overflow: auto;
}
.mouseHand {
  cursor: pointer;
}
.timeTitle {
  background-color: #e0e4e7;
  color:rgb(51, 70, 0);
  border-bottom: 1px solid #717171;
  margin: 0px;
  padding: 0px;
  font: 12px "verdana";
}
.unused {
  background-color: #B1B8BE;
}

</style>