<template>
  <v-container class="ma-0 pa-0">

    <v-sheet
      class="mx-auto border"
      width="400"
    >

    <div class="mt-2" v-if="selectedSTR">
      {{ selectedSTR.roomNam }} ({{ selectedSTR.usNmpr }}인용)
      <v-dialog
        v-model="roomDialog"
        width="auto"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="primary"
            v-bind="props"
            class="px-2 py-0"
          >
            스터디룸 선택
          </v-btn>
        </template>
  
        <v-card>
          <v-card-item>
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-for="studyRoom in studyRoomList"
                  :key="studyRoom.stNo"
                  cols="4"
                >
                  <v-btn
                    color="primary"
                    @click="selectStudyRoom(studyRoom)"
                  >
                    {{ studyRoom.title }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>           
          </v-card-item>
          <v-card-actions>
            <v-btn color="primary" block @click="roomDialog = false">닫기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-sheet class="mx-auto rounded-sm mt-2 py-1 border" width="380">
      <DatePicker
        :date="date"
        @dateChanged="dateChanged"
      >
        <v-row class="justify-center align-center">
          <v-col class="clickable-text">
            {{selectedDate}}
          </v-col>
          <v-col>
            <v-btn color="primary" class="ma-1">
              날짜변경
            </v-btn>
          </v-col>
        </v-row>
      </DatePicker>
    </v-sheet>

    <room-time-status
      v-if="selectedSTR"
      :key="selectedSTR.stNo + ':' + date"
      :st-no="selectedSTR.stNo"
      :date="date"
      :is-reserve-mode="false"
      :width="380"
      class="mb-3"
    ></room-time-status>

  </v-sheet>

  </v-container>
</template>

<script>
import Com from '@/lib/common.js';
import RoomTimeStatus from '@/components/RoomTimeStatus.vue';
import DatePicker from '@/components/DatePicker.vue';

import { computed } from "vue";
import { useStore } from 'vuex';


export default {
  components: { RoomTimeStatus, DatePicker },

  setup() {
    const store = useStore();
    // 스터디룸 목록
    const studyRoomList = computed(() => store.getters.studyRoomList);
    // 지점 정보
    const branch = computed(() => store.getters.selectedBranch);
    // max 예약 가능일

    return { studyRoomList, branch };
  },

  data: () => ({
    selectedSTR: undefined,
    roomDialog: false,        /* 스터디룸 선택 다이얼 로그 오픈 여부 - 2023.03.14  */
    date: new Date(),         /* 선택 일자 - 2023.03.15 */
    selectedDate: undefined,  /* 선택일자에 대한 화면 표시 텍스트 - 2023.04.14 */
  }),

  mounted() {
    // 화면 표시 텍스트 설정 - 2023.04.14
    this.setTextDate();

    // 직접 호출된 경우 지점 정보 조회 보다 먼저 호출됨 - 2023.04.12
    // 지점 정보가 설정될 때까지 기다림
    Com.waitSTRListEvtNExeFunc(() => {
      // 스터디룸이 지정되면 자동으로 RoomTimeStatus가 호출됨
      this.selectedSTR = this.studyRoomList[0];
    });

  },

  methods: {

    /** 선택된 일자에 대한 화면표시 텍스트 설정 - 2023.04.14
     */
    setTextDate() {
      const week = ['일', '월', '화', '수', '목', '금', '토'];      
      const day = this.date.getDate();
      const month = this.date.getMonth() + 1;
      const dayOfWeek = week[this.date.getDay()];

      this.selectedDate = `${month}월${day}일(${dayOfWeek})`;
    },

    /** 데이터 변경에 대해 이벤트 핸들러 - 2023.04.14
     */
    dateChanged(dateInfo) {
      Com.log("RoomStatus.dateChanged", dateInfo);

      if (Com.getFormatDate(this.date, 'yymmdd') !== Com.getFormatDate(dateInfo.date)) {
        this.date = dateInfo.date;
      }

      this.setTextDate();
    },

    /**
     * 스터디룸 선택
     * @param {스터디룸} studyRoom 
     */
    selectStudyRoom(selectedSTR) {

      // 스터디룸 선택 저장
      this.selectedSTR = selectedSTR;

      // 스터디룸 선택 다이얼로그 닫기
      this.roomDialog = false;
    },

  },
}
</script>

<style scoped>
  table {
    width: 98%;
  }
  th, td {
    height: 22px;
    font-size: 13px;
  }

  .header-date {
/*    background-color: rgb(68, 0, 177); */
    background-color: rgb(255, 255, 255);
    color: rgb(68, 0, 177);
    border: solid 1px rgb(68, 0, 177);
  }
  .header-time {
    background-color: darkgray;
  }
  .header-book {
    background-color: rgb(68, 0, 177);
    color: rgb(255, 255, 255);
  }
  .vacant {
    background-color: rgb(84, 205, 235);
  }
  .book {
    background-color: rgb(241, 238, 9);
  }
  .clickable-text {
    color: rgb(68, 0, 177);
    cursor: pointer;
    font-size: 16px;
    height: 50px;
  } 
  .red-color {
    color: #ff0000
  }
  .border {
    border: 1px solie rgb(43, 42, 36);
  }
</style>
