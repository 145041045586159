<template>
  <v-container>

    <v-card>
      <v-tabs
        v-model="tab"
        color="deep-purple-accent-4"
        align-tabs="center"
      >
        <v-tab :value="1">좌석</v-tab>
        <v-tab :value="2">스터디룸</v-tab>
        <v-tab :value="3">사물함</v-tab>
      </v-tabs>
      <v-window v-model="tab">

        <v-window-item :value="1">
          <v-container fluid>
            <v-table
              fixed-header
              height="300px"
            >
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Calories
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr>
              </tbody>
            </v-table>   
          </v-container>
        </v-window-item>

        <v-window-item :value="2">
          <v-container fluid>
            <v-table
              fixed-header
              height="300px"
            >
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Calories
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr>
              </tbody>
            </v-table>   
          </v-container>
        </v-window-item>

        <v-window-item :value="3">
          <v-container fluid>
            <v-table
              fixed-header
              height="300px"
            >
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Calories
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr>
              </tbody>
            </v-table>   
          </v-container>
        </v-window-item>

      </v-window>
    </v-card>
 
  </v-container>
</template>

<script>
//import Com from '@/lib/common.js';
//import RoomTimeStatus from '@/components/RoomTimeStatus.vue';

import { computed } from "vue";
import { useStore } from 'vuex';

//import VueDatePicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css';

export default {
//  components: { VueDatePicker, RoomTimeStatus },

  setup() {
    const store = useStore();
    // 스터디룸 목록
    const studyRoomList = computed(() => store.state.studyRoomList);
    // 지점 정보
    const branch = computed(() => store.state.selectedBranch);

    return { studyRoomList, branch };
  },

  data: () => ({
    tab: null,

    desserts: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
      },
      {
        name: 'Eclair',
        calories: 262,
      },
      {
        name: 'Cupcake',
        calories: 305,
      },
      {
        name: 'Gingerbread',
        calories: 356,
      },
      {
        name: 'Jelly bean',
        calories: 375,
      },
      {
        name: 'Lollipop',
        calories: 392,
      },
      {
        name: 'Honeycomb',
        calories: 408,
      },
      {
        name: 'Donut',
        calories: 452,
      },
      {
        name: 'KitKat',
        calories: 518,
      },
    ],

  }),
}
</script>
