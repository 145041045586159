<template>
  <!-- theader.vue -->
  <thead class="head fixed-header">
    <tr>
      <th rowspan="2" class="text-center align-middle">시간</th>
      <th :colspan="secondHeaders.length" class="text-center align-middle">
        <v-row class="ma-0">
          <v-col cols="2" class="align-start">
            <v-btn v-if="isExistPrevPage"
              density="compact"
              @click="movePage(-1)"
              size="sm"
              class="movebutton pa-1"
            >{{prevPageName}}</v-btn>
          </v-col>
          <v-col cols="2" class="align-start">
            <v-btn v-if="isExistPrev"
              density="compact"
              @click="move(-1)"
              size="sm"
              class="movebutton pa-1"
            >{{prevBtnName}}</v-btn>
          </v-col>
          <v-col cols="4" class="align-midddle mt-1">
            <h6>{{title}}</h6>
            <v-btn
              v-if="displayMode === DISP_MODE.ROOM_MODE && dayIdx !== 0"
              density="compact"
              @click="moveToday" color="info" size="sm" class="ma-0 pa-1 today"
            >오늘날짜로</v-btn>
            <v-btn v-if="isChangeReserveMode"
              density="compact"
              color="danger"
              @click="cancelReqChangeSelect"
              size="sm"
              class="ma-0 pa-1"
            >변경취소</v-btn>
          </v-col>
          <v-col cols="2" class="ma-0">
            <v-btn v-if="isExistNext"
              density="compact"
              @click="move(1)"
              size="sm"
              class="movebutton pa-1"
            >{{nextBtnName}}</v-btn>
          </v-col>
          <v-col cols="2" class="ma-0">
            <v-btn v-if="isExistNextPage"
              density="compact"
              @click="movePage(1)"
              size="sm"
              class="movebutton pa-1"
            >{{nextPageName}}</v-btn>
          </v-col>
        </v-row>
      </th>
    </tr>
    <tr>
      <th
        v-for="(header, idx) in secondHeaders" :key="idx"
        :width="Math.floor(90 / secondHeaders.length) + '%'"
        @click="changeDispMode(header.idx)"
        class="mouseHand header_text"
      >
        {{ header.title }}
      </th>
    </tr>
  </thead>
</template>

<script>
import Com from '@/lib/common';

import { computed } from 'vue';
import { useStore } from 'vuex';


export default {
  name: 'THeader',

  props: {
    isChangeReserveMode: Boolean,
    stNo: String,
    date: String,
  },
  
  setup() {
    const store = useStore();

    // 스터디룸 관련 저장소 정보 조회 - 2023.08.29
    const strstatus = computed(() => store.getters.getSTRStatusInfo());

    // 스터디룸 목록 - 2023.08.29
    const studyRoomList = computed(() => store.getters.studyRoomList); 

    return { store, strstatus, studyRoomList };
  },

  data() {
    return {
      dispModeBackup: { // 예약 변경중 취소를 위한 이전 화면 위치 - 2023.05.07
        mode: undefined,
        idx: undefined,
      }
    }
  },

  created() {
    Com.log("theader.mounted");
  },

  mounted() {
    Com.log("theader.mounted", "stNo: " + this.stNo + ", date: " + this.date );

    // 사용자 정보 조회에서 이동해온 경우 - 2023.05.10
    if (this.stNo) {
      this.moveToStNo(this.stNo, this.date);
    }
    else {
      this.getSTRList();
    }
/* 2023.10.09 - getSTRList 에서 변경모드가 아닌 경우에는 매번 저장하도록 변경    
    // 예약 변경 모드인 경우 현재 화면 조회 모드 및 위치 백업 - 2023.05.07
    this.dispModeBackup = {
      mode: this.displayMode,
      idx: this.displayMode === this.DISP_MODE.ROOM_MODE ? this.dayIdx : this.stNoIdx
    };
*/    
  },

  computed: {
    // 화면의 한페이지에 표시할 날짜 / 스터디룸 개수 - 2021.10.04
    PAGE_SIZE: {
      get: function() {return this.strstatus.PAGE_SIZE;}
    },

    DISP_MODE: {
      get: function() {return this.strstatus.DISP_MODE;}
    },

    displayMode: {
      get() {return this.strstatus.displayMode;}
    },

    dayIdx: {
      get: function() {return this.strstatus.dayIdx;}
    },

    selectedDay: {
      get: function() {return this.strstatus.dateList[this.dayIdx];}
    },

    stNoIdx: {
      get: function() {return this.strstatus.stNoIdx;}
    },

    selectedStudyRoom: {
      get: function() {return this.studyRoomList[this.stNoIdx];}
    },

    // 화면표시 페이지 인덱스 - 2021.10.04
    dispPageIdx: {
      get: function() {return this.strstatus.dispPageIdx;}
    },

    isExistNext: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return this.dayIdx < this.strstatus.dateList.length-1;
        }
        else {
          return this.stNoIdx < this.studyRoomList.length-1;
        }
      }
    },

    isExistPrev: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return this.dayIdx > 0;
        }
        else {
          return this.stNoIdx > 0;
        }
      }
    },

    /* 다음 페이지 존재 여부 - 2021.10.04 */
    isExistNextPage: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return (this.dispPageIdx.roomPageIdx+1) * this.PAGE_SIZE.ROOM_MODE < this.studyRoomList.length;
        }
        else {
          return (this.dispPageIdx.datePageIdx+1) * this.PAGE_SIZE.DATE_MODE < this.strstatus.dateList.length;
        }
      }
    },

    /* 이전 페이지 존재 여부 - 2021.10.04 */
    isExistPrevPage: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return this.dispPageIdx.roomPageIdx > 0;
        }
        else {
          return this.dispPageIdx.datePageIdx > 0;
        }
      }
    },

    headVariant: {
      get: function() {
        return this.displayMode == this.DISP_MODE.ROOM_MODE ? "dark" : "light";
      }
    },

    title: {
      get: function() {
        // 전체 조회인 경우
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          // 선택된 날짜를 표시
          return this.selectedDay?Com.stringFormatter(this.selectedDay.date, "DAY5") +
            " (" + this.selectedDay.week + ")" : "";
        }
        // 특정 스터디룸 조회인 경우
        else {
          // 스터디룸 번호를 표시
          return this.selectedStudyRoom.roomNam +
            " (" + this.selectedStudyRoom.stCnt + "인용)";
        }
      }
    },

    secondHeaders: {
      get: function() {
        const retList = [];

        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          // 룸리스트를 페이지 단위로 구분하여 표시 - 2021.10.04
          const roomLength = this.studyRoomList.length;

          // 화면표시 페이지 개수보다 적은 경우 전체 조회
          if (roomLength <= this.PAGE_SIZE.ROOM_MODE) {
            //return this.studyRoomList;
            this.studyRoomList.forEach((item) => retList.push(item));
          }
          // 화면표시 페이지 개수보다 큰 경우 현재 페이지 조회
          else {
            const startIdx = this.dispPageIdx.roomPageIdx * this.PAGE_SIZE.ROOM_MODE;

            //const curRoomList = [];

            for (let idx = 0; idx < this.PAGE_SIZE.ROOM_MODE && startIdx + idx < roomLength; ++idx) {
              //curRoomList.push(this.studyRoomList[startIdx + idx]);
              retList.push(this.studyRoomList[startIdx + idx]);
            }

            //return curRoomList;
          }

          // 조회 일자 추가 - 2023.05.03
          // cell 에서 예약 정보에 활용 
          retList.forEach((item) => {
            item.id = item.stNo;
            item.date = this.selectedDay.date;
          });
          //return this.studyRoomList;
        }
        else {
          // 예약 가능일수를 페이지 단위로 구분하여 표시 - 2021.10.04
          const dateLength = this.strstatus.dateList.length;
          const startIdx = this.dispPageIdx.datePageIdx * this.PAGE_SIZE.DATE_MODE;

          //let curDateList = [];

          for (let idx = 0; idx < this.PAGE_SIZE.DATE_MODE && startIdx + idx < dateLength; ++idx) {
            //curDateList.push(this.strstatus.dateList[startIdx + idx]);
            retList.push(this.strstatus.dateList[startIdx + idx]);
          }

          //return curDateList;

          // 조회 스터디룸 추가 - 2023.05.03
          // cell 에서 예약 정보에 활용 
          retList.forEach((item) => {
            item.id = item.date;
            item.stNo = this.selectedStudyRoom.stNo;
            item.roomNam = this.selectedStudyRoom.roomNam;
            item.usNmpr = this.selectedStudyRoom.usNmpr;  // 요금기준 인원
            item.stCnt = this.selectedStudyRoom.stCnt;    // 실제 좌석수
          });
        }

        return retList;
      }
    },

    prevBtnName: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          if (this.dayIdx > 0) {
            return this.strstatus.dateList[this.dayIdx-1].title;
          }
          // 잘못 호출된 경우
          else {
            return "이전날";
          }
        }
        else {
          if (this.stNoIdx > 0) {
            return this.studyRoomList[this.stNoIdx-1].roomNam;
          }
          // 잘못 호출된 경우
          else {
            return '이전룸';
          }
        }
      }
    },

    nextBtnName: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          if (this.dayIdx+1 < this.strstatus.dateList.length) {
            return this.strstatus.dateList[this.dayIdx+1].title;
          }
          // 잘못 호출된 경우
          else {
            return "다음날";
          }
        }
        else {
          //Com.log('nextBtnName:' + this.stNoIdx + ", length:" + this.studyRoomList.length);
          //Com.log(this.studyRoomList);

          if (this.stNoIdx+1 < this.studyRoomList.length) {
            return this.studyRoomList[this.stNoIdx+1].roomNam;
          }
          // 잘못 호출된 경우
          else {
            return '다음룸';
          }
        }
      }
    },

    /* 이전페이지 버튼 명칭 - 2021.10.04  */
    prevPageName: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return "이전룸";
        }
        else {
          return '이전주';
        }
      }
    },

    /* 다음페이지 버튼 명칭 - 2021.10.04  */
    nextPageName: {
      get: function() {
        if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
          return "다음룸";
        }
        else {
          return "다음주";
        }
      }
    },

    variantClass() {
      return this.displayMode === this.DISP_MODE.ROOM_MODE ? "room" : "date";
    },

  },

  methods: {

    /**
     * 예약 변경 모드인 경우 현재 화면 조회 모드 및 위치 백업 - 2023.10.09
     */
    backupCurPage() {
      this.dispModeBackup = {
        mode: this.displayMode,
        idx: this.displayMode === this.DISP_MODE.ROOM_MODE ? this.dayIdx : this.stNoIdx
      };
    },

    /** 화면표시 형태 변경 - 2023.05.02
     * 
     * @param {header index} idx 
     */
    changeDispMode(idx) {
      Com.log("changeDispMode:" + idx);

      if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
        this.store.commit("setStNoIdx", idx);
      }
      else {
        this.store.commit("setDayIdx", idx);
      }

      // 예약 조회
      this.getSTRList();
    },

    /** 오늘날짜로 이동(룸모드인 경우 사용) - 2023.05.20 */
    moveToday() {
      this.store.commit("setDayIdx", 0);

      // 예약 조회
      this.getSTRList();
    },

    /** 해당 스터디룸 조회모드로 변경 및 이동 - 2023.05.10
     * 
     * @param {스터디룸 번호} stNo 
     */
    moveToStNo(stNo, date) {
      Com.log("theader.moveToStNo", "stNo: " + stNo + ", date: " + date );
      this.store.commit("setStNo", stNo);

      // 예약일자 리스트 idx, date(yyyymmdd), title(mm월 dd일), week(월)
      const dateList = this.strstatus.dateList;

      // 페이지 이동
      for (let idx = 0; idx < dateList.length; ++idx) {
        
        if (date === dateList[idx].date) {
          const pageIdx = Math.floor(idx / this.PAGE_SIZE.DATE_MODE);
          Com.log("theader.moveToStNo", "date: " + date + ", idx: " + idx + ", pageIdx: " +  pageIdx);
          this.store.commit("setDispPageIdx", {datePageIdx: pageIdx, roomPageIdx: this.dispPageIdx.roomPageIdx});
          break;
        }
      }

      // 예약 조회
      this.getSTRList();
    },

    move(add) {
      if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
        // 날짜이동
        const toIdx = this.dayIdx + add;

        Com.log("move.dayIdx:" + toIdx);

        if (toIdx >= 0 && toIdx < this.strstatus.dateList.length) {
          this.store.commit("setDayIdx", toIdx);
        }
      }
      else {
        // 스터디룸 이동
        const toIdx = this.stNoIdx + add;

        Com.log("move.stNoIdx:" + toIdx);
        
        if (toIdx >= 0 && toIdx < this.studyRoomList.length) {
          Com.log("move:" + toIdx);
          this.store.commit("setStNoIdx", toIdx);
        }
      }

      // 예약 재조회 - 2023.05.02
      this.getSTRList();
    },

    /* 페이지 이동 - 2021.10.04 */
    movePage(add) {
      if (this.displayMode == this.DISP_MODE.ROOM_MODE) {
        // 해당 일자의 스터디룸 페이지 이동
        const toIdx = this.dispPageIdx.roomPageIdx + add;

        Com.log("movePage.roomPageIdx:" + toIdx);

        if (toIdx >= 0 && toIdx * this.PAGE_SIZE.ROOM_MODE < this.studyRoomList.length) {
          this.store.commit("setDispPageIdx", {datePageIdx: this.dispPageIdx.datePageIdx, roomPageIdx: toIdx});
        }
      }
      else {
        // 해당 스터디룸의 날짜 페이지 이동
        const toIdx = this.dispPageIdx.datePageIdx + add;

        Com.log("movePage.datePageIdx:" + toIdx);
        
        if (toIdx >= 0 && toIdx * this.PAGE_SIZE.DATE_MODE < this.strstatus.dateList.length) {
          this.store.commit("setDispPageIdx", {datePageIdx: toIdx, roomPageIdx: this.dispPageIdx.roomPageIdx});
        }
      }

      // 예약 재조회 - 2023.05.02
      this.getSTRList();
    },

    /** 스터디룸 예약리스트 조회 - 2023.05.03
     * 
     */
    getSTRList() {
      Com.log("getSTRList event")

      // 변경모드가 아닌 경우 현재 조회 중인 페이지 및 모드 저장 - 2023.10.09
      if (!this.isChangeReserveMode) {
        this.backupCurPage();
      }

      // parent 호출 - 2023.05.03
      this.$emit("fetchSTRBookList", 
        {
          secondHeaders: this.secondHeaders,
          selectedDay: this.selectedDay,
          selectedStudyRoom: this.selectedStudyRoom
        }
      );
    },

    /** 예약 변경 모드 취소 - 2023.05.07
     * 
     */
    cancelReqChangeSelect() {
      if (this.dispModeBackup.mode === this.DISP_MODE.ROOM_MODE) {
        // 백업받은 일자 재지정
        this.store.commit('setDayIdx', this.dispModeBackup.idx);
      }
      else {
        // 백업받은 스터디룸 번호 재지정
        this.store.commit('setStNoIdx', this.dispModeBackup.idx);
      }

      // 예약 변경 모드 취소
      this.$emit('cancelReqChangeSelect');
    },

    /** parent에서 백업 받은 내용과 기존으로 돌아가는 기능을 활용하기 위해 호출함 - 2023.05.10
     * 
     */
    cancelMode() {
      if (this.dispModeBackup.mode === this.DISP_MODE.ROOM_MODE) {
        // 백업받은 일자 재지정
        this.store.commit('setDayIdx', this.dispModeBackup.idx);
      }
      else {
        // 백업받은 스터디룸 번호 재지정
        this.store.commit('setStNoIdx', this.dispModeBackup.idx);
      }
    },
  }
}
</script>

<style scoped>
.fixed-header {
  position: sticky;
  top: 0;
}
.header_text {
  margin: 0px;
  padding: 0px;
  font: 12px "verdana";
}

table > thead > tr > th{
  /*border: 1px solid white;*/
  border: none;
}
.mouseHand {
  cursor: pointer;
}
.headerTitle {
  background-color: #d3f5fa;
  color:rgb(51, 70, 0);
}
.head {
  background-color: #0B253E;
  color: #ffffff;
}
.today {
  background-color: #ffffff;
  color: #0B253E;
  font: 12px "verdana";
}
.movebutton {
  background-color: #1380f5;
  color: #ffffff;
  font-size: 13px;
}

</style>